import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Pages from '../../../components/Page/Pages';
import { TextField } from 'app/components';

import { requestPassword } from '../core/_requests';

const initialValues = {
  email: '',
}

const status = [200, 201, 202]

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Por favor, informe seu login de usuário')
    .required('E-mail é obrigatório'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  const Nav = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      requestPassword(values.email)
        .then((data) => {
          window.analytics.track('Password Reseted', {
            email: values.email
          })
          if (status.includes(data.status)) handleShow()
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
          setSubmitting(false)
          setStatus('The login detail is incorrect')
        })
    },
  })
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <Pages type='Logo'>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        style={{ marginBottom: '30%' }}
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='mb-10'>
          <h1 className='text-black mb-3 w-75 fa-2x'>Esqueceu sua senha?</h1>
          <p className='text-gray-600 fs-5 font-roboto'>Informe o seu e-mail para que possamos enviar o link de redefinição.</p>
        </div>

        <div className='grid-forgot-password'>
          <div>
            <TextField
              placeholder="Ex: email@email.com.br"
              name="email"
              autoComplete="off"
              touched={formik.touched.email}
              error={formik.errors.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              id="email"
              {...formik.getFieldProps('email')}
            />
          </div>
          <div>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-info fw-bolder me-2 py-2'
              disabled={loading && true}
              style={{ marginLeft: '10px' }}
            >
              {loading ? 'Link enviado!' : 'Enviar link'}
            </button>
          </div>
        </div>

      </form>
      <Modal show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton className='border-0'></Modal.Header>
        <Modal.Body>
          <h2 className='pb-10 text-center'>E-mail reenviado com sucesso</h2>
          <p className='pb-10 text-center'>Por favor, cheque sua caixa de entrada e as outras pastas do seu e-mail também (Spam, lixo eletrônico, promoções etc).</p>
          <div className="text-center mb-10">
            <span className='btn btn-info' onClick={() => Nav('/auth/')}>Ir para a BrandLovrs</span>
          </div>
        </Modal.Body>
      </Modal>
    </Pages >
  )
}
