import { useState } from "react";
import { Modal, ModalHeader, ModalTitle, ModalBody, } from "react-bootstrap";

import { AmbassadorDashboard } from "app/components/Metabase/Ambassador";


import { ReactComponent as DashboardActive } from "../../../../assets/svg/general/dashboard-active.svg";
import { ReactComponent as DashboardInactive } from "../../../../assets/svg/general/dashboard-active.svg";

import styles from './styles.module.scss';

import BrandId from "app/modules/hooks/BrandId/BrandId";

interface ambassadorDashboardData {
  name: string;
  approval: boolean;
  ambassador: number;
}

export const AmbassadorResultDashboard = ({ name, approval, ambassador }: ambassadorDashboardData) => {
  const [addTagModal, setAddTagModal] = useState(false);
  const handleCloseModal = () => setAddTagModal(false);
  const brandId = BrandId()

  return (
    <>
      <div className={`d-flex justify-content-center align-items-center mt-5 mb-5 min-h-45px cursor-pointer`}>
        <button disabled={!approval} className={`d-flex btn btn-outline-light ${styles.buttonText}`} onClick={() => setAddTagModal(!addTagModal)}>
          {approval ? <DashboardActive /> : <DashboardInactive />}
          <span className="ps-2">Analisar</span>
        </button>
      </div>

      <Modal
        centered
        scrollable
        size="xl"
        show={addTagModal}
        onHide={handleCloseModal}
      >
        <ModalHeader closeButton>
          <ModalTitle className="fs-1">{name}</ModalTitle>
        </ModalHeader>

        <ModalBody>
          <AmbassadorDashboard brandId={brandId} ambassadorId={ambassador} />
        </ModalBody>
      </Modal>
    </>
  )
}