/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-cond-assign */
import React from 'react';

export const useMasks = () => {
  function cep(e: React.FormEvent<HTMLInputElement>) {
    // @ts-ignore
    e.target.maxLength = 9;
    // @ts-ignore
    let value = e.target.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{5})(\d)/, '$1-$2');
    // @ts-ignore
    e.target.value = value;

    return e;
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    // @ts-ignore
    let value = e.target.value;

    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1.$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ',');
    // @ts-ignore
    e.target.value = value;

    return e;
  }

  function cpf(e: React.FormEvent<HTMLInputElement>) {
    // @ts-ignore
    let value = e.target.value;
    // @ts-ignore
    e.target.maxLength = 14;

    if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d{2})$/, '$1-$2');
      // @ts-ignore
      e.target.value = value;
    }

    return e;
  }

  function cnpj(e: React.FormEvent<HTMLInputElement>) {
    // @ts-ignore
    e.target.maxLength = 18;
    // @ts-ignore
    let value = e.target.value;

    if (!value.match(/^(\d{2}).(\d{3}).(\d{3}).(\d{4})-(\d{2})$/)) {
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{2})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d)/, '$1/$2');
      value = value.replace(/(\d{4})(\d{2})$/, '$1-$2');
      // @ts-ignore
      e.target.value = value;
    }

    return e;
  }

  function phone(e: React.FormEvent<HTMLInputElement>) {
    // @ts-ignore
    let value = e.target.value;
    // @ts-ignore
    e.target.maxLength = 15;

    if (!value.match(/^(\d{2}).(\d{5}).(\d{4})$/)) {
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{2})(\d)/, '($1) $2');
      value = value.replace(/(\d{5})(\d)/, '$1-$2');
      // @ts-ignore
      e.target.value = value;
    }

    return e;
  }

  function data(e: React.FormEvent<HTMLInputElement> | any) {
    e.target.maxLength = 9
    if (e.target.maxLength !== -1) {
      e.target.maxLength = 10
      // @ts-ignore
      let value = e.target.value;
      // @ts-ignore
      e.target.maxLength
      if (!value.match(/^\d{4}\/\d{2}\/\d{2}$/)) {
        value = value.replace(/\D/g, "")
        value = value.replace(/(\d{2})(\d)/, "$1/$2")
        value = value.replace(/(\d{2})(\d)/, "$1/$2")
        // @ts-ignore
        e.target.value = value;
      }

    }

    return e;
  }

  return {
    cpf,
    cnpj,
    cep,
    currency,
    phone,
    data
  };
};
