/* eslint-disable import/no-anonymous-default-export */

import * as Yup from "yup";
export default [
    Yup.object({
        numberOfEmployees: Yup.string().required("Este campo é obrigatório."),
    }),
    Yup.object({
        averageMonthlyRevenue: Yup.string().required("Este campo é obrigatório.")
    }),
    Yup.object({
        brandGoals: Yup.array().min(1, "Este campo é obrigatório.")
    })
  ]
  