import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import MultiSelect from "multiselect-react-dropdown";

import Token from "app/modules/hooks/Token/Token";
import CharacterCounter from "app/modules/hooks/CharacterCounter/CharacterCounter";

import { CreateEngagementChallengeWizzardController } from "../../../components";
import { TextField } from "app/components/TextField";
import { RichText } from "app/components/RichText";
import { MaskInput, Radio, SubTitle, Trigger } from "app/components";
import { erroAlert, successAlert } from "app/components/ModalSwal/ModalSwal";
import { UploadImage } from "app/components/Inputs/UploadImage";

import { WizzardFrameData } from "../../types";

const header = {
  title: "Descrição",
  subtitle: "Preenchas as informações relacionadas ao desafio",
};

type SelectedTagsData = {
  id: number;
  name: string;
};

const DescriptionFormSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "Tamanho mínimo de 3 caracteres")
    .max(56, "Tamanho máximo 56 caracteres")
    .required("Informe o título do desafio"),
  description: Yup.object().required("Informe a descrição do desafio"),
  expiredDate: Yup.string().required("Informe a validade do desafio"),
  image_cover: Yup.mixed(),
  //.test('fileSize', "O tamanho da imagem excede o limite de: 1 MB", value => value.size <= 160 * 1024)
  //.test('fileType', "Formato de arquivo inválido.", value => ['image/jpg', 'image/jpeg', 'image/png'].includes(value.type))
});

const Description = ({
  setHeaderData,
  formData,
  setFormData,
  actualStep,
  setNextStep,
}: WizzardFrameData) => {
  const [counterTitle, setCounterTitle] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [banner, setBanner] = useState({
    filename: "",
    image: "",
  });

  const [toggleAvailability, setToggleAvailability] = useState<number>(0);
  const [brandTags, setBrandTags] = useState<SelectedTagsData[]>([]);
  const [selectedTags, setSelectedTags] = useState<Number[]>([]);

  const navigate = useNavigate();
  const token = Token();

  useEffect(() => {
    setHeaderData(header);
  }, [setHeaderData]);

  const startDate = new Date().toISOString().slice(0, 10);

  const handleSubmitDescription = async (data) => {
    try {
      setLoading(true);

      await DescriptionFormSchema.validate(data, { abortEarly: false });

      const bannerData =
        banner.image !== ""
          ? { filename: banner.filename, data: banner.image }
          : null;

      const res = await axios.post("/colab/create-engagement-challenge", {
        ...formData,
        ...data,
        banner: bannerData,
        ambassadorsTags: selectedTags,
        startDate,
        token,
      });

      if (res.status === 201) {
        successAlert("Ebaa", "O desafio foi cadastrado com sucesso!");
        navigate("/dashboard/challenge");
      }
    } catch (error) {
      erroAlert(
        "Opss",
        "Aconteceu um erro inesperado. Por favor, tente novamente."
      );
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      expiredDate: "",
      image_cover: "",
    },
    validationSchema: DescriptionFormSchema,
    onSubmit: handleSubmitDescription,
  });

  async function handleGetBrandTagsData() {
    const payload: any = localStorage.getItem("kt-auth-react-v");

    let parsedPayload;
    if (payload) parsedPayload = JSON.parse(payload);

    const response = await axios.post("colab/list-brand-ambassador-tags", {
      accessToken: parsedPayload.api_token,
      brandId: parsedPayload.brandId,
    });

    setBrandTags(response.data);
  }

  function handleAddTagToChallenge(selectedItem) {
    const addTagToList = selectedItem.map((d) => d.id).concat();

    setSelectedTags(addTagToList);
  }

  function handleRemoveTagFromChallenge(selectedList, removedItem) {
    const filterTagRemove = selectedTags.filter(function (item) {
      return item !== removedItem.id;
    });

    setSelectedTags(filterTagRemove);
  }

  useEffect(() => {
    handleGetBrandTagsData();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <section>
        <SubTitle
          title="Título do desafio"
          description="Será exibido na página do desafio para qualquer embaixador dentro da BrandLovrs"
          hasPopover="Chamada principal que aparecerá no Desafio da sua marca. Insira palavras-chave que chamem atenção."
        />

        <div className="mt-3">
          <TextField
            placeholder="Ex: Divulgue o saldão de Natal da BrandLovrs"
            name="title"
            touched={formik.touched.title}
            error={formik.errors.title}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            onKeyDown={(e) => CharacterCounter(e, setCounterTitle)}
            maxLength={56}
            {...formik.getFieldProps("title")}
          />

          <p className="text-end text-gray-400 mt-3 mb-10">
            {counterTitle <= 56 ? counterTitle : 56}/56 CARACTERES
          </p>
        </div>

        <SubTitle
          title="Descrição"
          description="Será exibido na página do desafio para qualquer embaixador dentro da BrandLovrs"
          hasPopover="Oriente de forma clara sobre as regras e o que os participantes devem fazer ou falar para cumprir o desafio."
        />

        <div className="mt-3">
          <RichText
            placeholder="Ex: Crie um reels bem criativo apresentando a loja e convidando seus seguidores do Instagram a conhecer e aproveitar a promoção de Natal da BrandLovrs."
            name="description"
            id="description"
            {...formik.getFieldProps("description")}
            setFieldValue={(val) => formik.setFieldValue("description", val)}
            touched={formik.touched.description}
            error={formik.errors.description}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>

        <UploadImage
          name="image_cover"
          label_title="Banner do Desafio"
          label_subtitle="Defina uma imagem exclusiva para ilustrar este desafio."
          badge_title="Opcional"
          banner_title="Carregar arquivo..."
          banner_subtitle="Seu banner precisa ser no formato JPG ou PNG, ter até 1 MB e dimensão máxima de 1820 x 800."
          {...formik.getFieldProps("image_cover")}
          touched={formik.touched.image_cover}
          error={formik.errors.image_cover}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          setBaseValue={setBanner}
        />

        <div className="divisor px-10 my-10" />

        <h2 className="mb-3">Público Alvo</h2>
        <p className="text-gray-600 font-roboto mb-8">
          Aqui você escolhe se seu desafio será exibido para todos ou só para
          aqueles que você marcou com as suas tags.
        </p>

        <div className="grid-radio-type mb-10">
          <Radio
            label={"Exibir para todos"}
            id={"challenge_availability_off"}
            onClick={() => {
              setToggleAvailability(0);
              setSelectedTags([]);
            }}
            {...formik.getFieldProps("challengeAvailability")}
            name="challengeAvailability"
            checked={toggleAvailability === 0}
          />

          <Radio
            label={"Segmentar por tags"}
            id={"challenge_availability_on"}
            onClick={() => setToggleAvailability(1)}
            {...formik.getFieldProps("challengeAvailability")}
            name="challengeAvailability"
            checked={toggleAvailability === 1}
          />
        </div>

        {toggleAvailability === 1 && (
          <div className="row mb-5">
            <div className="col-md-6">
              <label className="mb-3  form-label">Tags escolhidas</label>

              <div className="input-group MultiSelect mb-3">
                <MultiSelect
                  {...formik.getFieldProps("selectedTags")}
                  options={brandTags}
                  displayValue="name"
                  isObject
                  placeholder="Selecione"
                  hidePlaceholder
                  emptyRecordMsg="Nenhuma opção disponível"
                  showArrow
                  onSelect={handleAddTagToChallenge}
                  onRemove={handleRemoveTagFromChallenge}
                  style={{
                    chips: {
                      backgroundColor: "#C9E8FF",
                      color: "#009EF6",
                    },
                    searchBox: {
                      border: "1px solid #e4e6ef",
                      backgroundColor: "#F3F6F9",
                      borderRadius: "0.475rem",
                      boxShadow: "inset 0 1px 2px rgb(0 0 0 / 8%)",
                      transition:
                        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                      position: "relative",
                      padding: "1rem 3rem 1rem 1rem",
                    },
                    inputField: {
                      MozPaddingStart: "calc(1rem - 3px)",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      lineHeight: "1.5",
                      color: "#5e6278",
                      appearance: "none",
                    },
                  }}
                />

                <span className="text-black-50 d-block py-2">
                  O desafio só vai aparecer para embaixadores categorizados com
                  alguma das tags selecionadas.
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="divisor px-10 my-10" />

        <h2>
          Validade do desafio
          <Trigger description="Caso todas as recompensas já tenham sido liberadas, o desafio será encerrado automaticamente." />
        </h2>
        <div className="mw-175px mt-7 mb-7">
          <MaskInput
            type="date"
            placeholder="Ex: Divulgue o saldão de Natal da BrandLovrs"
            name="expiredDate"
            touched={formik.touched.expiredDate}
            error={formik.errors.expiredDate}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            {...formik.getFieldProps("expiredDate")}
          />
        </div>
      </section>

      <div className="divisor px-10 my-10" />

      <CreateEngagementChallengeWizzardController
        actualStep={actualStep}
        setNextStep={setNextStep}
        loading={loading}
      />
    </form>
  );
};

export { Description };

