import React, { forwardRef, InputHTMLAttributes, ReactNode, useImperativeHandle, useRef, useState } from 'react';
import { FormikTouched } from "formik";

import { ModalButton } from 'app/components/Buttons';
import Base64 from 'app/modules/hooks/Base64/Base64';

interface UploadImageData extends InputHTMLAttributes<HTMLInputElement> {
  label_title: string;
  label_subtitle: string;
  badge_title: string;
  banner_title: string;
  banner_subtitle: string;
  touched: boolean | FormikTouched<any> | FormikTouched<any>[];
  value: any;
  error: string | ReactNode;
  disabled?: boolean;
  setBaseValue: Function;
  viewText?: boolean
}

export type TextFieldRef = {
  handleFocus: () => void;
};

const UploadImage = forwardRef<TextFieldRef, UploadImageData>(({
  label_title,
  label_subtitle,
  badge_title,
  banner_title,
  banner_subtitle,
  touched,
  value,
  error,
  disabled = false,
  setBaseValue,
  viewText = true,
  ...rest
}, ref) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [imageUrl, setImageUrl] = useState<any>();

  async function handleChangeImage(e) {
    const base64 = await Base64(e.target.files[0])

    setImageUrl(URL.createObjectURL(e.target.files[0]));
    setBaseValue({ filename: e.target.files[0].name, image: base64 });
  }

  function handleEditBannerImage() {
    fileRef.current.click();
  }

  useImperativeHandle(ref, () => ({
    handleFocus() {
      if (fileRef.current) fileRef.current.focus();
    },
  }));



  return (
    <div>
      <div className='d-flex mt-5 justify-content-between'>
        {viewText &&
          <div className=''>
            <h2>
              {label_title}
              <span className="badge ms-2 bg-ligth-blue text-blue">{badge_title}</span>
            </h2>

            <p className="font-14 font-roboto mb-0 text-gray-600">{label_subtitle}</p>
          </div>
        }


        {imageUrl && (
          <div className='d-flex align-items-center'>
            <ModalButton title='Editar' buttonType='outlined' type='button' onClick={handleEditBannerImage} />
            <ModalButton title='Excluir' buttonStyle='reproved' buttonType='primary' type='button'
              onClick={() => { setImageUrl(''); }} />
          </div>
        )}
      </div>

      <div className='m-auto mt-3'>
        <input
          accept="image/*"
          ref={fileRef}
          id="avatar"
          type="file"
          onInput={handleChangeImage}
          hidden
          disabled={disabled}
          {...rest}
        />

        {!imageUrl && (
          <label htmlFor='avatar' className='border-dashed alert alert-dismissible bg-ligth-blue border-dashed-ligth-blue d-flex align-items-center flex-column flex-sm-row p-5 mb-10 border-1 cursor-pointer' >
            <span
              className={`svg-icon svg-icon-primary svg-icon-2hx pe-6`}
              dangerouslySetInnerHTML={
                {
                  __html: `
                      <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M24.2448 28.5938V9.84375C24.2448 8.80822 23.399 7.96875 22.3556 7.96875C21.3123 7.96875 20.4665 8.80822 20.4665 9.84375V28.5938C20.4665 29.6293 21.3123 30.4688 22.3556 30.4688C23.399 30.4688 24.2448 29.6293 24.2448 28.5938Z" fill="#009EF7"/>
                        <path d="M22.9361 11.4727L25.6999 14.7644C26.4401 15.6461 27.7834 15.7066 28.6011 14.895C29.3373 14.1643 29.372 12.9907 28.6802 12.2183L23.7675 6.73284C23.016 5.89374 21.6951 5.89374 20.9437 6.73284L16.0309 12.2183C15.3392 12.9907 15.3739 14.1643 16.1101 14.895C16.9278 15.7066 18.271 15.6461 19.0113 14.7644L21.7751 11.4727C22.0771 11.1128 22.634 11.1128 22.9361 11.4727Z" fill="#009EF7"/>
                        <path opacity="0.3" d="M35.5795 15.4688H33.6904C32.647 15.4688 31.8013 16.3082 31.8013 17.3437C31.8013 18.3793 32.647 19.2187 33.6904 19.2187C34.7338 19.2187 35.5795 20.0582 35.5795 21.0937V34.2187C35.5795 35.2543 34.7338 36.0937 33.6904 36.0937H11.0208C9.97746 36.0937 9.13166 35.2543 9.13166 34.2187V21.0937C9.13166 20.0582 9.97744 19.2187 11.0208 19.2187C12.0641 19.2187 12.9099 18.3793 12.9099 17.3437C12.9099 16.3082 12.0641 15.4688 11.0208 15.4688H9.13166C7.04498 15.4688 5.35339 17.1477 5.35339 19.2187V36.0937C5.35339 38.1649 7.04498 39.8437 9.13166 39.8437H35.5795C37.6663 39.8437 39.3578 38.1649 39.3578 36.0937V19.2187C39.3578 17.1477 37.6663 15.4688 35.5795 15.4688Z" fill="#009EF7"/>
                      </svg>
                    `
                }
              }
            />

            <div>
              <h2>{banner_title}</h2>
              <p className={`text-gray-500 mb-0`}>{banner_subtitle}</p>
            </div>
          </label>
        )
        }
      </div>

      {
        imageUrl && (
          <div className='card'>
            <img src={imageUrl} className="card-img" alt="imagebanneraltimage" />
          </div>
        )
      }

      <div className="d-flex justify-content-between">
        {error && (
          <small className="text-danger fw-bold d-block">
            {error}
          </small>
        )}
      </div>
    </div>
  );
});

export { UploadImage };