
import * as Yup from "yup";

const data = new Date()

const formSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "Minimo de 3 caracteres")
    .max(50, "Maximo 50 caracteres")
    .required("Infome o titulo do desafio")
    .nullable(),
  description: Yup.string()
    .min(3, "Minimo de 3 caracteres")
    .max(450, "Maximo 50 caracteres"),
  expiration: Yup.date()
    .min(new Date(data.toISOString()), 'A data deve ser apartir da data atual ')
    .required('Informe a validade do desafio'),
  discount: Yup.string()
    .required('Informe o valor do desconto.'),
  commission: Yup.string()
    .required('Informe o valor da recompensa.')
});

export { formSchema };

const fromImport = Yup.object().shape({
  import: Yup.string()
    .required("Algo deu errado, tente novamente.")
});

export { fromImport };
