import {toAbsoluteUrl} from '_metronic/helpers'

const logos = {
  shopify: {
    active: '/media/logo/shopify-blue.png',
    inactive: '/media/logo/shopify-gray.png',
    regular: '/media/logo/shopify-gray.png'
  },
  vtex: {
    active: '/media/logo/vtex-blue.png',
    inactive: '/media/logo/vtex-gray.png',
    regular: '/media/logo/vtex-gray.png'
  },
  vnda: {
    active: '/media/logo/vnda-ativo.svg',
    inactive: '/media/logo/vnda-gray.svg',
    regular: '/media/logo/vnda-orange.svg'
  },
  yampi: {
    active: '/media/logo/yampi-ativo.svg',
    inactive: '/media/logo/yampi-gray.svg',
    regular: '/media/logo/yampi-color.svg'
  },
  'nuvem-shop': {
    active: '/media/logo/nuvem-shop-ativo.svg',
    inactive: '/media/logo/nuvem-shop-gray.svg',
    regular: '/media/logo/nuvem-shop-color.svg'
  },
  'loja-integrada': {
    active: '/media/logo/loja-integrada-ativo.svg',
    inactive: '/media/logo/loja-integrada-gray.svg',
    regular: '/media/logo/loja-integrada-color.svg'
  },
  tray: {
    active: '/media/logo/tray-ativo.svg',
    inactive: '/media/logo/tray-gray.svg',
    regular: '/media/logo/tray-color.svg'
  },
  magento: {
    active: '/media/logo/magento-ativo.png',
    inactive: '/media/logo/magento-gray.png',
    regular: '/media/logo/magento-color.png'
  },
  magazord: {
    active: '/media/logo/magazord-ativo.png',
    inactive: '/media/logo/magazord-gray.png',
    regular: '/media/logo/magazord-color.png'
  },
  dooca: {
    active: '/media/logo/dooca-ativo.png',
    inactive: '/media/logo/dooca-gray.png',
    regular: '/media/logo/dooca-color.png'
  },
  woocommerce: {
    active: '/media/logo/woocommerce-ativo.png',
    inactive: '/media/logo/woocommerce-gray.png',
    regular: '/media/logo/woocommerce-color.png'
  },
  wix: {
    active: '/media/logo/wix-ativo.png',
    inactive: '/media/logo/wix-gray.png',
    regular: '/media/logo/wix-color.png'
  },
  'e-com-plus': {
    active: '/media/logo/ecom-ativo.png',
    inactive: '/media/logo/ecom-gray.png',
    regular: '/media/logo/ecom-color.png'
  }
}

export const InactiveLogo = (img) => {
  const resource = logos[img]
  return resource ?
    <img style={{ maxWidth: '63px' }} src={toAbsoluteUrl(logos[img].inactive)} alt={'logo'} />
    : ''
}

export const ActiveLogo = (img) => {
  const resource = logos[img]
  return resource ?
    <img style={{ maxWidth: '63px' }} src={toAbsoluteUrl(logos[img].active)} alt={'logo'} />
    : ''
}

export const PatternLogo = (img) => {
  const resource = logos[img]
  return resource ?
    <img style={{ maxWidth: '63px' }} src={toAbsoluteUrl(logos[img].regular)} alt={'logo'} />
    : ''
}
