import React, {useCallback, useEffect, useRef, useState} from "react";
import {ICheckBoxProps} from "./types";

export const CheckBox: React.FC<ICheckBoxProps> = ({checked = false, onChange = (event) => {}, ...rest}) => {
  
  const [checkedState, setCheckedState] = useState(false)
  const input = useRef(null)
  
  useEffect(() => setCheckedState(checked), [checked])
  
  const handleChange = useCallback((event) => {
    const checked = event.target.checked
    setCheckedState(checked)
    onChange(checked)
  }, [setCheckedState, onChange])
  
  return (
    <div className="check-container" onClick={() => input.current.click()}>
      <input ref={input} type="checkbox" checked={checkedState} onChange={handleChange}/>
      <span className="check-box"/>
    </div>
  )
}  