import { useEffect, useRef, useState } from "react";
import axios from "axios";

import { useAuth } from "app/modules/auth";
import ValideteError from "app/modules/hooks/ValideteError/ValideteError";

import './styles.scss';
import { Spinner } from "react-bootstrap";

type TagListData = {
  tags: TagData[];
  ambassadorId: number;
  isLoadingButton: boolean;
  setIsLoadingButton?: Function;
  handleGetAmbassadorTagList?: Function;
  setAddTagModal?: Function;
  setRefresh?: Function;
  listChartRequest?: Function;
}

interface TagData {
  id: number;
  name: string;
}

export const TagField = ({ listChartRequest, setRefresh, tags, ambassadorId, handleGetAmbassadorTagList, isLoadingButton, setIsLoadingButton, setAddTagModal }: TagListData) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { logout } = useAuth();

  const [input, setInput] = useState('');
  const [ambassadorTags, setAmbassadorTags] = useState<TagData[]>([]);
  const [brandTags, setBrandTags] = useState<TagData[]>([]);

  const onChange = (e) => {
    const { value } = e.target;

    setInput(value);
  }

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if ((key === ',' || e.keyCode === 13) && trimmedInput.length) {
      e.preventDefault();

      handleCreateBrandTag(trimmedInput);
      setInput('');
    }
  }

  const handleFocusOnInput = () => {
    inputRef.current.focus();
  }

  async function handleListBrandTags() {
    const payload: any = localStorage.getItem('kt-auth-react-v');

    let parsedPayload;
    if (payload) parsedPayload = JSON.parse(payload);

    const response = await axios.post('colab/list-brand-ambassador-tags', {
      "accessToken": parsedPayload.api_token,
      "brandId": parsedPayload.brandId
    });

    setBrandTags(response.data);
  }

  async function handleCreateBrandTag(tagName: string) {
    try {
      const payload: any = localStorage.getItem('kt-auth-react-v');

      let parsedPayload;
      if (payload) parsedPayload = JSON.parse(payload);

      await axios.post('colab/create-brand-ambassador-tag', {
        "accessToken": parsedPayload.api_token,
        "brandId": parsedPayload.brandId,
        "creatorId": ambassadorId,
        "tagName": tagName
      });
      setRefresh(draft => draft + 1);

      handleListBrandTags();
      handleGetAmbassadorTagList();
      setInput('');
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      }
    }
  }

  async function handleAddTagToAmbassador(tagId: number) {
    setIsLoadingButton(true);
    try {
      const payload: any = localStorage.getItem('kt-auth-react-v');

      let parsedPayload;
      if (payload) parsedPayload = JSON.parse(payload);

      await axios.post('colab/add-tag-to-ambassador', {
        "accessToken": parsedPayload.api_token,
        "creatorId": ambassadorId,
        "tagId": tagId
      });

      handleGetAmbassadorTagList();
      setInput('');
      setAddTagModal(false)
      setRefresh((draft) => draft + 1)
    } catch (e) {
      setIsLoadingButton(false);
      if (ValideteError(e)) {
        logout();
      }
    }
  }

  async function handleRemoveTagFromAmbassador(tagId: number) {
    setIsLoadingButton(true);
    try {
      const payload: any = localStorage.getItem('kt-auth-react-v');

      let parsedPayload;
      if (payload) parsedPayload = JSON.parse(payload);
      handleGetAmbassadorTagList();

      await axios.post('colab/remove-tag-from-ambassador', {
        "accessToken": parsedPayload.api_token,
        "creatorId": ambassadorId,
        "tagId": tagId
      });

      setRefresh((draft) => draft + 1);
      setInput('');
      handleListBrandTags();

      setAddTagModal(false)
    } catch (e) {
      setIsLoadingButton(false);
      if (ValideteError(e)) {
        logout();
      }
    }
  }

  useEffect(() => {
    handleListBrandTags();
  }, []);

  useEffect(() => {
    setAmbassadorTags(tags);
  }, [tags]);

  return (
    <>
      <div className="tag-container mt-5" onClick={handleFocusOnInput}>
        <input
          ref={inputRef}
          value={input}
          placeholder='Ex. Influencer'

          className={`form-control form-control-lg form-control-solid`}

          onKeyDown={onKeyDown}
          onChange={onChange}
        />

        {ambassadorTags?.map((tag, index) => (
          <>
            {isLoadingButton ? <div className="tag"><button><Spinner animation="grow" variant="primary" style={{ width: '10px', height: '10px' }} /></button></div> : <div key={`${tag?.name}-${index}`} className="tag">{tag?.name} <button onClick={() => handleRemoveTagFromAmbassador(tag?.id)}>x</button></div>}
          </>
        ))}
      </div>

      {isLoadingButton ? <div className="loadingTag"><Spinner animation="grow" variant="primary" style={{ width: '10px', height: '10px' }} /></div> : (
        <div className="tag-container-out mt-5 mb-5">
          {brandTags?.filter(({ id: brandTagId }) => !ambassadorTags?.some(({ id: ambassadorTagId }) => ambassadorTagId === brandTagId))?.map((tag, index) => (
            <div key={`${tag?.name}-${index}`} className="tag" onClick={() => handleAddTagToAmbassador(tag?.id)}>{tag?.name} <button>↑</button></div>
          ))}
        </div>
      )}
    </>
  );
};
