import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { default as _ReactPlayer } from 'react-player/wistia';
import { ReactPlayerProps } from "react-player/types/lib";


const Connectors = () => {
    const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;
    return (
        <section className='container-metronic px-5 px-md-0 px-xxl-0 position-relative bg-white h-100'>
            <h2 className='px-5 py-md-10 px-md-15 bg-gray-100'>Conectores</h2>
            <article className='px-5 px-md-15'>
                <div className="row">
                    <div className="col-md-6  h-100 d-flex flex-column justify-content-center">
                        <h1 className='text-info pt-20 pb-5 settings-h1'>Conecte o seu e-commerce</h1>
                        <p>Boas vindas à sua nova plataforma de gestão de comunidades de embaixadores! </p>
                        <p>Pra conseguir construir sua comunidade e criar seus desafios, você precisa primeiro conectar seu e-commerce à BrandLovrs.</p>
                        <p>Assim, poderemos gerar os cupons automaticamente e acompanhar as vendas que sua comunidade fizer durante os desafios.</p>
                        <p><strong>Não se preocupe:</strong> O processo é seguro e só teremos acesso aos dados relacionados às vendas feitas com os cupons dos desafios.</p>
                        <div className='my-10'>
                            <Link to="/dashboard/integration">
                                <Button variant='primary'>
                                    <span className="svg-icon svg-icon-white svg-icon-1hx">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.2166 8.50002L10.5166 7.80007C10.1166 7.40007 10.1166 6.80005 10.5166 6.40005L13.4166 3.50002C15.5166 1.40002 18.9166 1.50005 20.8166 3.90005C22.5166 5.90005 22.2166 8.90007 20.3166 10.8001L17.5166 13.6C17.1166 14 16.5166 14 16.1166 13.6L15.4166 12.9C15.0166 12.5 15.0166 11.9 15.4166 11.5L18.3166 8.6C19.2166 7.7 19.1166 6.30002 18.0166 5.50002C17.2166 4.90002 16.0166 5.10007 15.3166 5.80007L12.4166 8.69997C12.2166 8.89997 11.6166 8.90002 11.2166 8.50002ZM11.2166 15.6L8.51659 18.3001C7.81659 19.0001 6.71658 19.2 5.81658 18.6C4.81658 17.9 4.71659 16.4 5.51659 15.5L8.31658 12.7C8.71658 12.3 8.71658 11.7001 8.31658 11.3001L7.6166 10.6C7.2166 10.2 6.6166 10.2 6.2166 10.6L3.6166 13.2C1.7166 15.1 1.4166 18.1 3.1166 20.1C5.0166 22.4 8.51659 22.5 10.5166 20.5L13.3166 17.7C13.7166 17.3 13.7166 16.7001 13.3166 16.3001L12.6166 15.6C12.3166 15.2 11.6166 15.2 11.2166 15.6Z" fill="currentColor" />
                                            <path opacity="0.3" d="M5.0166 9L2.81659 8.40002C2.31659 8.30002 2.0166 7.79995 2.1166 7.19995L2.31659 5.90002C2.41659 5.20002 3.21659 4.89995 3.81659 5.19995L6.0166 6.40002C6.4166 6.60002 6.6166 7.09998 6.5166 7.59998L6.31659 8.30005C6.11659 8.80005 5.5166 9.1 5.0166 9ZM8.41659 5.69995H8.6166C9.1166 5.69995 9.5166 5.30005 9.5166 4.80005L9.6166 3.09998C9.6166 2.49998 9.2166 2 8.5166 2H7.81659C7.21659 2 6.71659 2.59995 6.91659 3.19995L7.31659 4.90002C7.41659 5.40002 7.91659 5.69995 8.41659 5.69995ZM14.6166 18.2L15.1166 21.3C15.2166 21.8 15.7166 22.2 16.2166 22L17.6166 21.6C18.1166 21.4 18.4166 20.8 18.1166 20.3L16.7166 17.5C16.5166 17.1 16.1166 16.9 15.7166 17L15.2166 17.1C14.8166 17.3 14.5166 17.7 14.6166 18.2ZM18.4166 16.3L19.8166 17.2C20.2166 17.5 20.8166 17.3 21.0166 16.8L21.3166 15.9C21.5166 15.4 21.1166 14.8 20.5166 14.8H18.8166C18.0166 14.8 17.7166 15.9 18.4166 16.3Z" fill="currentColor" />
                                        </svg>
                                    </span>
                                    Conectar e-commerce
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="pt-20 pb-5 video">
                            <ReactPlayer url='https://adventures-inc.wistia.com/medias/32rtbhemwn' controls={true} width='100%' height='350px' />
                        </div>
                    </div>
                </div>
            </article>
        </section>
    )
}

export default Connectors