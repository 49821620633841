import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MoneyInput from "@rschpdr/react-money-input";
import { Modal } from "react-bootstrap";
import InputMask from "react-input-mask";
import ValideteError from "../../modules/hooks/ValideteError/ValideteError";
import { useAuth } from "../../modules/auth";

const WithdrawFunds = () => {
  const [data, setData] = useState({} as any);
  const [showPixKey, setShowPixKey] = useState(false);
  const [withdrawValue, setWithdrawValue] = useState<any>();
  const [typePixKey, setTypePixKey] = useState<any>();
  const [pixKeyValue, setPixKeyValue] = useState("");
  const [pixRegistered, setPixRegistered] = useState(false);
  const [password, setPassword] = useState("");
  const [pixSended, setPixSended] = useState(false);
  const [minimumValueError, setMinimumValueError] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const token = JSON.parse(localStorage.getItem("kt-auth-react-v"));
  const { logout } = useAuth()

  useEffect(() => {
    balanceApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const balanceApi = async () => {
    try {
      setLoading(true);
      const res = await axios.post("/colab/wallet/balance", {
        token: token.api_token,
      });
      setData(res.data.data);
      setLoading(false);
    } catch (e) {
      if (ValideteError(e)) {
        logout()
      }
    }
  };

  const handleChangeWithDraw = (e): any => {
    setWithdrawValue(e.target.value);
  };

  const handleRegisterPix = () => {
    setPixRegistered(true);
  };

  const handleWithdrawFunds = async () => {
    setLoading(true);
    try {
      await axios.post("/colab/wallet/remove-money", {
        token: token.api_token,
        value: withdrawValue,
        password,
        pixTypeKey: typePixKey,
        pixKey: pixKeyValue,
      });

      setLoading(false);
      setPixSended(true);
    } catch {
      setLoading(false);
      setErrorPassword(true);
    }
  };

  const handleChangeTypePixKey = (e): any => {
    setPixKeyValue(null);
    setTypePixKey(e.target.value);
  };

  const handleChangeStep = async () => {
    parseInt(withdrawValue) < 100
      ? setMinimumValueError(true)
      : setShowPixKey(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return <>

    <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between">
      <Link to="/dashboard/financial">
        <button className="btn btn-primary text-white w-100 mt-5 mb-5">
          Voltar
        </button>
      </Link>
    </div>
    {showPixKey ? (
      <>
        {pixRegistered ? (
          <div className="row">
            <article className="col-md-4 py-4 py-md-0">
              <div className="border rounded-2 py-7 px-7 h-100">
                <>
                  <h2>Retirar dinheiro</h2>
                  <p className="mb-0 mt-5">
                    Valor da transferência
                  </p>

                  <div className="d-flex align-items-center">
                    <p
                      style={{
                        fontSize:
                          "24px",
                        fontWeight:
                          "bold",
                        color: "#00A3FF",
                        margin: "0",
                      }}
                      className="mt-2"
                    >
                      {withdrawValue?.toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency:
                            "BRL",
                        }
                      )}
                    </p>

                    <span
                      className="svg-icon svg-icon-primary mt-2 cursor-pointer"
                      style={{
                        marginLeft:
                          "6px",
                      }}
                      onClick={() =>
                        setShowPixKey(
                          false
                        )
                      }
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                          fill="currentColor"
                        />
                        <path
                          d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                          fill="currentColor"
                        />
                        <path
                          d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>
                      Tipo de
                      transferência
                    </p>
                    <p>PIX</p>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Chave PIX</p>
                    <p>{pixKeyValue}</p>
                  </div>

                  <button
                    className="btn btn-primary text-white w-100 mt-5"
                    onClick={() =>
                      setShow(true)
                    }
                    disabled={
                      !pixKeyValue ||
                      pixKeyValue?.length ===
                      0
                    }
                  >
                    Continuar
                  </button>
                </>
              </div>
            </article>
          </div>
        ) : (
          <div className="row">
            <article className="col-md-4 py-4 py-md-0">
              <div className="border rounded-2 py-7 px-7 h-100">
                <>
                  <h2>Retirar dinheiro</h2>
                  <p className="mb-0 mt-5">
                    Valor da transferência
                  </p>

                  <div className="d-flex align-items-center">
                    <p
                      style={{
                        fontSize:
                          "24px",
                        fontWeight:
                          "bold",
                        color: "#00A3FF",
                        margin: "0",
                      }}
                      className="mt-2"
                    >
                      {withdrawValue?.toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency:
                            "BRL",
                        }
                      )}
                    </p>

                    <span
                      className="svg-icon svg-icon-primary mt-2 cursor-pointer"
                      style={{
                        marginLeft:
                          "6px",
                      }}
                      onClick={() =>
                        setShowPixKey(
                          false
                        )
                      }
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                          fill="currentColor"
                        />
                        <path
                          d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                          fill="currentColor"
                        />
                        <path
                          d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>

                  <p className="mb-0">
                    Para quem você quer
                    transferir?
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                    }}
                  >
                    Escolha o tipo de chave
                    e digite os dados de
                    quem irá receber
                  </p>

                  <div>
                    <label className="radio radio-lg w-100 mt-2">
                      <div className="d-flex align-items-center">
                        <input
                          onChange={
                            handleChangeTypePixKey
                          }
                          type="radio"
                          name="acceptRule"
                          value="cpf"
                          checked={
                            typePixKey ===
                            "cpf"
                          }
                        />
                        <p
                          className="mb-0"
                          style={{
                            marginLeft:
                              "5px",
                          }}
                        >
                          CNPJ
                        </p>
                      </div>
                    </label>

                    {typePixKey ===
                      "cpf" && (
                        <InputMask
                          mask="99.999.999/9999-99"
                          value={
                            pixKeyValue
                          }
                          onChange={(e) =>
                            setPixKeyValue(
                              e.target
                                .value
                            )
                          }
                        >
                          {() => (
                            <input
                              placeholder="00.000.000/0000-00"
                              type="text"
                              className="form-control form-control-lg form-control-solid"
                            />
                          )}
                        </InputMask>
                      )}

                    <label className="radio radio-lg w-100 mt-2">
                      <div className="d-flex align-items-center">
                        <input
                          onChange={
                            handleChangeTypePixKey
                          }
                          type="radio"
                          name="acceptRule"
                          value="phone"
                          checked={
                            typePixKey ===
                            "phone"
                          }
                        />
                        <p
                          className="mb-0"
                          style={{
                            marginLeft:
                              "5px",
                          }}
                        >
                          Celular
                        </p>
                      </div>
                    </label>

                    {typePixKey ===
                      "phone" && (
                        <InputMask
                          mask="(99) 99999-9999"
                          value={
                            pixKeyValue
                          }
                          onChange={(e) =>
                            setPixKeyValue(
                              e.target
                                .value
                            )
                          }
                        >
                          {() => (
                            <input
                              placeholder="(00) 00000-0000"
                              type="text"
                              className="form-control form-control-lg form-control-solid"
                            />
                          )}
                        </InputMask>
                      )}

                    <label className="radio radio-lg w-100 mt-2">
                      <div className="d-flex align-items-center">
                        <input
                          onChange={
                            handleChangeTypePixKey
                          }
                          type="radio"
                          name="acceptRule"
                          value="mail"
                          checked={
                            typePixKey ===
                            "mail"
                          }
                        />
                        <p
                          className="mb-0"
                          style={{
                            marginLeft:
                              "5px",
                          }}
                        >
                          E-mail
                        </p>
                      </div>
                    </label>

                    {typePixKey ===
                      "mail" && (
                        <input
                          placeholder="E-mail"
                          value={
                            pixKeyValue
                          }
                          onChange={(e) =>
                            setPixKeyValue(
                              e.target
                                .value
                            )
                          }
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                        />
                      )}

                    <label className="radio radio-lg w-100 mt-2">
                      <div className="d-flex align-items-center">
                        <input
                          onChange={
                            handleChangeTypePixKey
                          }
                          type="radio"
                          name="acceptRule"
                          value="random_key"
                          checked={
                            typePixKey ===
                            "random_key"
                          }
                        />
                        <p
                          className="mb-0"
                          style={{
                            marginLeft:
                              "5px",
                          }}
                        >
                          Chave
                          aleatória
                        </p>
                      </div>
                    </label>

                    {typePixKey ===
                      "random_key" && (
                        <input
                          placeholder="Chave aleatória"
                          value={
                            pixKeyValue
                          }
                          onChange={(e) =>
                            setPixKeyValue(
                              e.target
                                .value
                            )
                          }
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                        />
                      )}
                  </div>

                  <button
                    className="btn btn-primary text-white w-100 mt-5"
                    onClick={
                      handleRegisterPix
                    }
                    disabled={
                      !pixKeyValue ||
                      pixKeyValue?.length ===
                      0
                    }
                  >
                    Continuar
                  </button>
                </>
              </div>
            </article>
          </div>
        )}
      </>
    ) : (
      <div className="row">
        <article className="col-md-4 py-4 py-md-0">
          <div className="border rounded-2 py-7 px-7 h-100">
            {loading ? (
              <div className="d-flex justify-content-center h-100 flex-column align-items-center">
                <div
                  className="spinner-grow text-primary"
                  role="status"
                />
              </div>
            ) : (
              <>
                <h2>Retirar dinheiro</h2>
                <p className="mb-0 mt-5">
                  Qual o valor da
                  transferência?
                </p>
                <p
                  style={{
                    fontSize: "10px",
                    color: "#464E5F",
                  }}
                  className="mt-2"
                >
                  Saldo disponível{" "}
                  <b>
                    {data?.total?.toLocaleString(
                      "pt-br",
                      {
                        style: "currency",
                        currency: "BRL",
                      }
                    )}
                  </b>
                </p>

                <MoneyInput
                  onChange={
                    handleChangeWithDraw
                  }
                  value={withdrawValue}
                  variant="outlined"
                  className={`form-control form-control-lg form-control-solid mt-5 ${minimumValueError &&
                    "is-invalid"
                    }`}
                  currencyConfig={{
                    locale: "pt-BR",
                    currencyCode: "BRL",
                    currencyDisplay:
                      "symbol",
                  }}
                />
                {minimumValueError && (
                  <span
                    style={{
                      fontSize: "10px",
                      color: "#FF4B55",
                    }}
                  >
                    O valor mínimo de
                    transferência é de
                    R$100,00
                  </span>
                )}

                <button
                  className="btn btn-primary text-white w-100 mt-5"
                  onClick={handleChangeStep}
                  disabled={
                    !withdrawValue ||
                    withdrawValue?.length ===
                    0
                  }
                >
                  Continuar
                </button>
              </>
            )}
          </div>
        </article>
      </div>
    )}


    <Modal show={show} onHide={handleClose}>
      {pixSended ? (
        <div className="d-flex flex-column p-10 justify-content-center align-items-center text-center">
          <div className="d-flex-column justify-content-center align-items-center text-center">
            <span
              onClick={handleClose}
              className="svg-icon svg-icon-primary svg-icon-2hx me-3 cursor-pointer"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.3"
                  x="2"
                  y="2"
                  width="20"
                  height="20"
                  rx="10"
                  fill="currentColor"
                />
                <path
                  d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                  fill="currentColor"
                />
              </svg>
            </span>

            <h2 className="mt-5">
              Pronto, enviamos sua transferência
            </h2>
          </div>

          <input
            defaultValue={`${withdrawValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} para `}
            type="text"
            className="form-control form-control-lg form-control-solid mt-3"
          />

          <p className="mt-3" style={{ fontSize: "12px" }}>
            Seu comprovante será enviado por e-mail
          </p>

          <button
            onClick={handleClose}
            className="btn btn-primary text-white mt-5"
          >
            Voltar
          </button>
        </div>
      ) : (
        <div className="d-flex flex-column p-10">
          <div className="d-flex justify-content-between w-100">
            <h2>Confirma sua identidade</h2>
            <span
              onClick={handleClose}
              className="svg-icon svg-icon-primary svg-icon-2hx me-3 cursor-pointer"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.3"
                  x="2"
                  y="2"
                  width="20"
                  height="20"
                  rx="10"
                  fill="currentColor"
                />
                <rect
                  x="7"
                  y="15.3137"
                  width="12"
                  height="2"
                  rx="1"
                  transform="rotate(-45 7 15.3137)"
                  fill="currentColor"
                />
                <rect
                  x="8.41422"
                  y="7"
                  width="12"
                  height="2"
                  rx="1"
                  transform="rotate(45 8.41422 7)"
                  fill="currentColor"
                />
              </svg>
            </span>
          </div>

          <p>Para realizar a transferência, informe sua senha</p>

          <input
            placeholder="**********"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            className={`form-control form-control-lg form-control-solid mt-5 ${errorPassword && "is-invalid"
              }`}
          />

          {errorPassword && (
            <span
              style={{
                fontSize: "10px",
                color: "#FF4B55",
              }}
            >
              A senha digitada precisa corresponder à senha
              atual.
            </span>
          )}

          {loading ? (
            <div className="d-flex justify-content-center h-100 flex-column align-items-center mt-5">
              <div
                className="spinner-grow text-primary"
                role="status"
              />
            </div>
          ) : (
            <button
              onClick={handleWithdrawFunds}
              className="btn btn-primary text-white w-100 mt-5"
              disabled={!password || password?.length === 0}
            >
              Continuar
            </button>
          )}
        </div>
      )}
    </Modal>
  </>
}

export default WithdrawFunds;

