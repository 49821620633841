interface ButtonData extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  buttonType: 'primary' | 'outlined' | 'success';
  buttonStyle?: string;
}

export function ModalButton({ title, buttonType, buttonStyle, ...rest }: ButtonData) {
  return (
    <button
      type='button'
      className={`modal-btn ms-2 ${buttonType} ${buttonType === 'outlined' ? '' : buttonStyle}`}

      {...rest}
    >
      {title}
    </button>
  );
}
