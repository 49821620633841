import React, { useEffect, useState } from "react";
import metabase from "app/modules/services/metabase";

interface SalesDashboardData {
  brandId: number;
}

export function SalesDashboard(brandId: SalesDashboardData) {
  const [iframe, setIframe] = useState<any>({
    header: "",
    urlEmbed: ""
  })

  const hash_token = `b79e6b95b5450b2e2ce5743c379f233c5a0d5fde`;

  const GetSalesDashboard = async () => {
    try {
      const response = await metabase.post('/dashboard/get-dashboard', {
        hash: hash_token,
        brand_id: [brandId.brandId],
      });

      setIframe(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (brandId.brandId) {
      GetSalesDashboard();
    }
    // eslint-disable-next-line
  }, [brandId])


  return (
    <>
      {/* <h1 className="mb-10">Painel Geral - Resumo das atividades</h1> */}
      <iframe
        title="dashboard"
        src={iframe.urlEmbed}
        frameBorder="0"
        width="800"
        height="650"
        style={{ width: "100%" }}
        className="mb-10 mt-10"
      />
      {/* <ChartRequests /> */}
    </>
  );
};
