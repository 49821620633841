/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FormEvent, useCallback, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import Token from "../../modules/hooks/Token/Token";
import ValideteError from "../../modules/hooks/ValideteError/ValideteError";
import { useAuth } from "../../modules/auth";
import { useFormik } from "formik";
import { erroAlert, successAlert } from "app/components/ModalSwal/ModalSwal";
import { Radio, TextField, SubTitle, Trigger } from "app/components";
import { EditRichText } from "app/components/RichText/EditRichText";
import { editFormSchema } from "./FormChallenge.schema.ts";
import { IntegrationImport, IntegrationNewChallenge, SelectedTagsData } from "./types";
import clsx from "clsx";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import MultiSelect from 'multiselect-react-dropdown';

import { fromImport } from "app/pages/Challenge/Sales/pages/create/Sales.schema";

import {
  DateFormat,
  DateTimeFormat,
} from "app/modules/hooks/DateFormat/DateFormat";
import Decimal from "decimal.js";
import { UploadImage } from "../Inputs/UploadImage";

interface ActiveConnectorData {
  id: number;
  title: string;
  connected: boolean;
}

type RichTextContract = {
  blocks: Array<{
    key: string,
    text: string,
    type: any,
    depth: number,
    inlineStyleRanges: any[],
    entityRanges: any[],
    data: object
  }>;
  entityMap: object;
};

const richTextMock = {
  blocks: [
    {
      key: "701rr",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
};

const EditFormChallenge: React.FC = (): JSX.Element => {
  const EDITDATECHALLENGE = ["shopify", "vnda", "magento", "dooca", "vtex"];

  const auth = Token();
  const { logout } = useAuth();
  const router = useParams();

  const navigate = useNavigate();

  const params = router.id ? true : false;

  const [counterTitle, setCounterTitle] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [res, setRes] = useState(0);
  const [erro, setErro] = useState(false);
  const [disable, setDisable] = useState(false);

  const [ciclo, setCiclo] = useState<string>("30");
  const [open, setOpen] = useState(false);

  const [toggleAvailability, setToggleAvailability] = useState<number>(0);
  const [selectedTags, setSelectedTags] = useState([] as any);
  const [brandTags, setBrandTags] = useState<SelectedTagsData[]>([]);
  const [rewardType, setRewardType] = useState<number>(3);
  const [cupomDiscountType, setCupomDiscountType] = useState<number>(3);
  const [editData, setEditData] = useState<any>({});
  const [challengeData, setChallengeData] = useState<any>({});
  const [connector, setConnector] = useState<ActiveConnectorData[]>([]);
  const [descriptionData, setDescriptionData] =
    useState<RichTextContract>(richTextMock);

  const [banner, setBanner] = useState({
    filename: '',
    image: ''
  });


  const serviceFee = useCallback((value) => Number(new Decimal(value).mul(new Decimal(0.2).toFixed(2))), []);

  // const percentAmountMask = useCallback((value: string) =>
  //   value.replace(/(^0)|\D/g, '').slice(0, 2), []);

  const toggleTab = (index) => setRewardType(index);
  const toggleCupom = (index) => setCupomDiscountType(index);

  const initialValues: IntegrationNewChallenge = {
    title: '',
    description: richTextMock,
    validatedAt: '',
    commissionMoney: 0,
    commissionPercentage: 0,
    dueDate: '',
    quantity: 0,
    discountType: '',
    discount: '',
    typecommission: 'money',
    prefix: '',
    minimumSubtotal: '',
    discountMoney: "",
    discountPercentage: "",
    image_cover: {
      type: 'image/png',
      size: 1048576,
    },
    selectedTags: [],
    rewardDescription: null,
  };

  const initialValuesImport: IntegrationImport = {
    import: "",
  };

  async function handleGetBrandTagsData() {
    const payload: any = localStorage.getItem("kt-auth-react-v");

    let parsedPayload;
    if (payload) parsedPayload = JSON.parse(payload);

    const response = await axios.post("colab/list-brand-ambassador-tags", {
      accessToken: parsedPayload.api_token,
      brandId: parsedPayload.brandId,
    });

    setBrandTags(response.data);
  }
  const bannerData = banner.image !== '' ? { filename: banner.filename, data: banner.image } : {};

  useEffect(() => {
    handleGetBrandTagsData();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: editFormSchema,
    onSubmit: async (values: IntegrationNewChallenge) => {
      formik.setTouched({})
      formik.setErrors({})

      const dateOPA = values.dueDate.split('/').reverse().join('-')
      try {
        setLoading(true);
        const addTagToList = selectedTags.map((d) => d.id).concat();

        const res = await axios
          .post("colab/update-challenge", {
            token: auth,
            challengeId: router.id,
            title: values.title,
            description: values.description,
            ambassadorsTags: addTagToList,
            validatedAt: dateOPA,
            banner: { filename: bannerData.filename, data: bannerData.data },
            rewardDescription: values.rewardDescription
          })
          .then((d) => d.status)
          .catch((d) => d.response.status);

        setRes(res.status);

        if (res === 200 || res === 204) {
          window.analytics.track("Challenge Updated", {
            nameOfChallenge: formik.values.title,
            type: "Sales",
            commissionValue:
              formik.values.typecommission === "value"
                ? formik.values.discountMoney
                : formik.values.discountPercentage,
            discountValue: formik.values.discount,
          });

          successAlert(
            "",
            "Seu desafio foi atualizado com sucesso.",
            "Voltar para Desafios"
          );

          navigate("/dashboard/challenge");
        } else {
          erroAlert(
            "",
            "Algo deu errado. Poderia verificar e tentar de novo?",
            "Verificar desafio"
          );
        }
      } catch (e) {
        if (ValideteError(e)) {
          logout();
        } else if (e.response.status === 500) {
          erroAlert(
            "",
            "Algo deu errado. Poderia verificar e tentar de novo?",
            "Verificar desafio"
          );
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const getChallenge = async () => {
    try {
      const response = await axios.post("colab/get-challenge", {
        token: auth,
        id: Number(router.id),
      });

      formik.setFieldValue("title", response.data.title);
      formik.setFieldValue("discountType", response.data.discountValue.type);

      formik.setFieldValue(
        "dueDate",
        DateTimeFormat(response.data.expiration).substring(0, 10)
      );

      formik.setFieldValue("commissionMoney", response.data.rewardValue.value);
      formik.setFieldValue(
        "commissionPercentage",
        response.data.rewardValue.value
      );
      formik.setFieldValue("discountMoney", response.data.discountValue.value);
      formik.setFieldValue(
        "discountPercentage",
        response.data.discountValue.value
      );

      formik.setFieldValue(
        "minimumSubtotal",
        response.data.minimumSubtotal.value
      );

      formik.setFieldValue("selectedTags", response.data.ambassadorsTags);

      formik.setFieldValue("rewardDescription", response.data.rewardDescription)

      if (response.data?.ambassadorsTags?.length > 0) {
        setToggleAvailability(1);
        setSelectedTags(response.data.ambassadorsTags);
      }

      let description: RichTextContract;
      try {
        description = JSON.parse(response.data.description);
      } catch (e) {
        description = {
          ...richTextMock,
          blocks: [
            { ...richTextMock.blocks[0], text: response.data.description },
          ],
        };
      }
      setDescriptionData(description);

      setRewardType(response.data.rewardValue.type);

      setCiclo(response.data.paymentDays.toString());
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      }
    }
  };

  const getConnectors = async () => {
    try {
      const response = await axios.post("/colab/list-connectors-available", {
        token: auth,
      });
      const activeConnector = response.data.data.filter(
        (connector) => connector.connected === true
      );
      const connecter = response.data.data.filter(
        (e) => e.connected === true && EDITDATECHALLENGE.includes(e.title)
      );

      setConnector(activeConnector);
      setEditData(connecter.length === 1 && true);
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      }
    }
  };
  useEffect(() => {
    getChallenge();
    getConnectors();
    // eslint-disable-next-line
  }, []);

  const formImport = async () => {
    try {
      setLoadingImport(true);
      const res = await axios.post("colab/import-challenge", {
        token: auth,
        externalId: formik_Import.values.import,
      });

      const data = DateFormat(res.data.dueDate);
      formik.setValues({
        ...formik.values,
        ...res.data,
        dueDate: data,
      });
      toggleCupom(res.data.discountType === "value" ? 1 : 2);
      setDisable(true);
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      } else if (e.response.status === 400) {
        setErro(true);
      }
    } finally {
      setLoadingImport(false);
    }
  };

  const formik_Import = useFormik({
    initialValues: initialValuesImport,
    validationSchema: fromImport,
    onSubmit: formImport,
  });

  function CounterTitle(e: FormEvent<HTMLInputElement>) {
    const s = e.target as HTMLTextAreaElement;
    if (s.value.length === 0) {
      return setCounterTitle(s.value.length);
    }
    return setCounterTitle(s.value.length + 1);
  }

  useEffect(() => {
    toggleTab(challengeData.typeOfCommission === "money" ? 1 : 2);
  }, [rewardType]);

  useEffect(() => {
    formik.setFieldValue("description", descriptionData);
  }, [descriptionData]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <SubTitle
          title="Título do desafio"
          description="Será exibido na página do desafio para qualquer embaixador dentro da BrandLovrs"
          hasPopover="Chamada principal que aparecerá no Desafio da sua marca. Insira palavras-chave que chamem atenção."
        />

        <div className="mt-3">
          <TextField
            autoComplete="off"
            name="title"
            trigger={true}
            placeholder="Ex: Promo de Natal da BandLovrs"
            onKeyDown={(e) => CounterTitle(e)}
            touched={formik.touched.title}
            error={formik.errors.title}
            onBlur={formik.handleBlur}
            maxLength={56}
            onChange={formik.handleChange}
            {...formik.getFieldProps("title")}
          />

          <p className="text-end text-gray-400 mt-3 mb-10">
            {counterTitle <= 56 ? counterTitle : 56}/56 CARACTERES
          </p>
        </div>

        <SubTitle
          title="Descrição"
          description="Será exibido na página do desafio para qualquer embaixador dentro da BrandLovrs"
          hasPopover="Oriente de forma clara sobre as regras e o que os participantes devem fazer ou falar para cumprir o desafio."
        />

        <div className="mt-3">
          <EditRichText
            placeholder="Ex: Crie um reels bem criativo apresentando a loja e convidando seus seguidores do Instagram a conhecer e aproveitar a promoção de Natal da BrandLovrs."
            name="description"
            id="description"
            {...formik.getFieldProps("description")}
            setFieldValue={(val) => formik.setFieldValue("description", val)}
            rawData={descriptionData}
            touched={formik.touched.description}
            error={formik.errors.description}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>
        <UploadImage
          name='image_cover'

          label_title='Banner do Desafio'
          label_subtitle='Defina uma imagem exclusiva para ilustrar este desafio.'

          badge_title='Opcional'

          banner_title='Carregar arquivo...'
          banner_subtitle='Seu banner precisa ser no formato JPG ou PNG, ter até 1 MB e dimensão máxima de 1820 x 800.'

          {...formik.getFieldProps("image_cover")}

          touched={formik.touched.image_cover}
          error={formik.errors.image_cover}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.setFieldValue('image_cover', e.target.files[0])
          }}
          setBaseValue={setBanner}

          disabled={false}
        />

        {/* <hr className='border-gray-600 mt-5 mb-10' />
        <h2 className="mb-3">Recompensa</h2>
        <p className="text-gray-600 font-roboto mb-8" >Escolha qual será a recompensa dos participantes que cumprirem o desafio.</p>

         Comissionamento 

        <div className="grid-radio mb-10">
          <Radio
            label={"Valor fixo por venda"}
            id={"l3"}
            disabled={params && true}
            {...formik.getFieldProps('typecommission')}
            name="typecommission"
            value="money"
            checked={challengeData.typeOfCommission === 'money'}
          />

          <Radio
            label={"% do total da venda"}
            id={"l2"}
            disabled={params && true}
            {...formik.getFieldProps('typecommission')}
            name="typecommission"
            value="percent"
            checked={challengeData.typeOfCommission === 'percent'}
          />
        </div>

        {rewardType === 1 && <div className="row mb-5">
          <div className="col-sm-3">
            <article>
              <label className="mb-3 form-label">Valor a ser pago
                <Trigger description="Valor que a marca pagará como recompensa para cada desafio cumprido." />
              </label>

              <div className="input-group mb-3">
                <MoneyInput
                  currencyConfig={{
                    locale: "pt-BR",
                    currencyCode: "BRL",
                    currencyDisplay:
                      "symbol",
                  }}

                  autoComplete="off"
                  name="commissionMoney"
                  placeholder="Ex:10"

                  max={9999999999}
                  min={1}

                  disabled={(params && true) || disable}
                  className={clsx('form-control form-control-lg form-control-solid',
                    { 'is-invalid': formik.touched.commissionMoney && formik.errors.commissionMoney },
                    { 'is-valid': formik.touched.commissionMoney && !formik.errors.commissionMoney },
                    { 'disabled': (params && true) || disable })}

                  {...formik.getFieldProps("commissionMoney")}
                />
              </div>
            </article>
          </div>
        </div>}

        {rewardType === 2 && <div className="row mb-5">
          <div className="col-sm-3">
            <article>
              <label className="mb-3 form-label">Valor a ser pago
                <Trigger description="Valor que a marca pagará como recompensa para cada desafio cumprido." />
              </label>

              <div className="input-group mb-3">
                <input
                  type='text'
                  autoComplete='off'
                  disabled={params && true}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  {...formik.getFieldProps("commissionPercentage")}
                />

                <span className="input-group-text" id="basic-addon1">%</span>
              </div>
            </article>
          </div>
        </div>}

        {rewardType === 3 && <></>}

         Prazo para pagamento 

        <h3>
          Prazo para pagamento

          <Trigger
            description={"Tempo necessário para confirmar as vendas e liberar o pagamento para seus embaixadores."}
          />
        </h3>

        <p className="text-gray-600 font-roboto mb-8" >Prazo para confirmar que o desafio foi cumprido e liberar a recompensa.</p>

        <div className='d-flex mt-3'>
          <div className='d-flex'>
            <input type="radio" id="30" name="payment_days" value="30" className='d-none' disabled={params && true} checked={ciclo === '30'} />
            <label className='btn-ciclo' htmlFor="30">30 dias</label>
          </div>

          <div className='d-flex'>
            <input type="radio" id="60" name="payment_days" value="60" className='d-none' disabled={params && true} checked={ciclo === '60'} />
            <label className='btn-ciclo' htmlFor="60">60 dias</label>
          </div>

          <div className='d-flex'>
            <input type="radio" id="90" name="payment_days" value="90" className='d-none' disabled={params && true} checked={ciclo === '90'} />
            <label className='btn-ciclo' htmlFor="90">90 dias</label>
          </div>

          <div className='d-flex'>
            <input type="radio" id="120" name="payment_days" value="120" className='d-none' disabled={params && true} checked={ciclo === '120'} />
            <label className='btn-ciclo' htmlFor="120">120 dias</label>
          </div>

          <div className='d-flex'>
            <input type="radio" name="payment_days" value={ciclo} className='d-none' disabled={params && true} />
            <label className={`btn-ciclo cursor-pointer ${open ? 'd-none' : 'd-block'}`} htmlFor="120" onClick={() => setOpen(true)}>Personalizado</label>

            <div className={`d-flex align-items-end ${open ? 'd-block' : 'd-none'}`}>
              <input maxLength={3} type="number" min={1} className={`form-ciclo`} disabled={params && true} onChange={(e) => setCiclo(e.target.value)} />
              <span className={`text-blue`}>dias</span>
            </div>
          </div>
        </div> */}

        {challengeData?.rewardValue?.type === "money" &&
          challengeData?.rewardValue?.value === 0 && (
            <>
              <hr className="border-gray-600 my-10" />
              <div className="row mb-5">
                <div className="col-sm-3">
                  <article>
                    <label className="mb-3 form-label">
                      Descreva a recompensa
                      <Trigger description="Descreva a recompensa que deseja oferecer" />
                      <span className="badge badge-blue-primary">Opcional</span>
                    </label>
                    <div className="input-group mb-3">
                      <TextField
                        placeholder="Descreva a recompensa"
                        maxLength={12}
                        touched={formik.touched.rewardDescription}
                        error={formik.errors.rewardDescription}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        {...formik.getFieldProps("rewardDescription")}
                      />
                    </div>
                    {formik.touched.rewardDescription &&
                      formik.errors.rewardDescription && (
                        <span className="text-danger fw-bold d-block py-2">
                          {formik.errors.rewardDescription}
                        </span>
                      )}
                  </article>
                </div>
              </div>
            </>
          )}

        <hr className="border-gray-600 my-10" />

        {/* <h2 className="mb-3">Cupom de desconto</h2>
        <p className="text-gray-600 font-roboto mb-8" >Configure o cupom de desconto que será divulgado para os compradores.</p>

        <div className="grid-radio-type mb-10">
          <Radio
            label={"Desconto fixo em R$"}
            id={"l4"}
            disabled={(params && true) || disable}
            {...formik.getFieldProps('discountType')}
            value='value'
            name="discountType"
            checked={challengeData === 1}
          />

          <Radio
            label={"% de desconto"}
            id={"l5"}
            disabled={(params && true) || disable}
            {...formik.getFieldProps('discountType')}
            value='percent'
            name="discountType"
            checked={cupomDiscountType === 2}
          />
        </div>

        {
          cupomDiscountType === 1 && (
            <div className="row mb-5">
              <div className="col-sm-3">
                <article>
                  <label className="mb-3 form-label">Desconto por cupom</label>

                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">R$</span>

                    <MoneyInput
                      placeholder="Ex:10"
                      autoComplete="off"
                      name="discountMoney"
                      max={9999999999}
                      min={1}
                      disabled={(params && true) || disable}

                      className={
                        clsx('form-control form-control-lg form-control-solid',
                          { 'is-invalid': formik.touched.discountMoney && formik.errors.discountMoney },
                          { 'is-valid': formik.touched.discountMoney && !formik.errors.discountMoney },
                          { 'disabled': (params && true) || disable })
                      }
                      currencyConfig={{
                        locale: "pt-BR",
                        currencyCode: "BRL",
                        currencyDisplay: "symbol",
                      }}

                      {...formik.getFieldProps("discountMoney")}
                    />
                  </div>

                  {
                    formik.touched.discountMoney && formik.errors.discountMoney && (
                      <span className='text-danger fw-bold d-block py-2'>{formik.errors.discountMoney}</span>
                    )
                  }
                </article>
              </div>
            </div>
          )
        }

        {
          cupomDiscountType === 2 && (
            <div className="row mb-5">
              <div className="col-sm-3">
                <article>
                  <label className="mb-3 form-label">Desconto por cupom</label>

                  <div className="input-group mb-3">
                    <input
                      placeholder="Ex:10"
                      type='number'
                      max={99}
                      min={1}
                      autoComplete='off'
                      name="discountPercentage"
                      disabled={(params && true) || disable}

                      className={
                        clsx('form-control form-control-lg form-control-solid',
                          { 'is-invalid': formik.touched.discountPercentage && formik.errors.discountPercentage },
                          { 'is-valid': formik.touched.discountPercentage && !formik.errors.discountPercentage },
                          { 'disabled': (params && true) || disable })
                      }
                      onChange={({ target }) => {
                        let value = percentAmountMask(target.value);
                        formik.setFieldValue("discountPercentage", value.length ? value : 0);
                      }}

                      {...formik.getFieldProps("discountPercentage")}
                    />

                    <span className="input-group-text" id="basic-addon1">%</span>
                  </div>

                  {
                    formik.touched.discountPercentage && formik.errors.discountPercentage && (
                      <span className='text-danger fw-bold d-block py-2'>{formik.errors.discountPercentage}</span>
                    )
                  }
                </article>
              </div>
            </div>
          )
        } */}

        <div className="row mt-10 mb-10">
          {/* <div className="col-md-6">
            <article>
              <label className="mb-3 form-label">
                Pedido mínimo

                <Trigger description="Valor mínimo do pedido para permitir o uso do cupom." />
                <span className="badge badge-blue-primary">Opcional</span>
              </label>


              <MoneyInput
                placeholder="Ex:10"
                autoComplete="off"
                name="minimumSubtotal"
                max={9999999999}
                min={1}
                disabled={(params && true) || disable}

                className={
                  clsx('form-control form-control-lg form-control-solid',
                    { 'is-invalid': formik.touched.minimumSubtotal && formik.errors.minimumSubtotal },
                    { 'is-valid': formik.touched.minimumSubtotal && !formik.errors.minimumSubtotal },
                    { 'disabled': (params && true) || disable })
                }
                currencyConfig={{
                  locale: "pt-BR",
                  currencyCode: "BRL",
                  currencyDisplay: "symbol",
                }}

                {...formik.getFieldProps("minimumSubtotal")}
              />
            </article>
          </div> */}

          <div className="col-md-6">
            <label className="mb-3 form-label">Data de Validade</label>

            <InputMask
              mask="99/99/9999"
              placeholder="DD/MM/AAAA"
              disabled={!editData}
              autoComplete="off"
              name=""
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid": formik.touched.dueDate && formik.errors.dueDate,
                },
                { "is-valid": formik.touched.dueDate && !formik.errors.dueDate }
              )}
              {...formik.getFieldProps("dueDate")}
            />
            {formik.touched.dueDate && formik.errors.dueDate && (
              <span className="text-danger fw-bold d-block py-2">
                {formik.errors.dueDate}
              </span>
            )}
          </div>
        </div>

        <hr className="border-gray-600 my-10" />

        <div className="grid-radio-type mb-10">
          <Radio
            label={"Exibir para todos"}
            id={"challenge_availability_off"}
            onClick={() => {
              setToggleAvailability(0);
              setSelectedTags([]);
            }}
            {...formik.getFieldProps("challengeAvailability")}
            name="challengeAvailability"
            checked={toggleAvailability === 0}
          />

          <Radio
            label={"Segmentar por tags"}
            id={"challenge_availability_on"}
            onClick={() => setToggleAvailability(1)}
            {...formik.getFieldProps("challengeAvailability")}
            name="challengeAvailability"
            checked={toggleAvailability === 1}
          />
        </div>

        {toggleAvailability === 1 && (
          <div className="row mb-5">
            <div className="col-md-6">
              <label className="mb-3  form-label">Tags escolhidas</label>

              <div className="input-group MultiSelect mb-3">
                <MultiSelect
                  {...formik.getFieldProps("selectedTags")}
                  options={brandTags}
                  displayValue="name"
                  selectedValues={selectedTags}
                  isObject
                  placeholder="Selecione"
                  hidePlaceholder
                  emptyRecordMsg="Nenhuma opção disponível"
                  showArrow
                  onSelect={setSelectedTags}
                  onRemove={setSelectedTags}
                  style={{
                    chips: {
                      backgroundColor: "#C9E8FF",
                      color: "#009EF6",
                    },
                    searchBox: {
                      border: "1px solid #e4e6ef",
                      backgroundColor: "#F3F6F9",
                      borderRadius: "0.475rem",
                      boxShadow: "inset 0 1px 2px rgb(0 0 0 / 8%)",
                      transition:
                        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                      position: "relative",
                      padding: "1rem 3rem 1rem 1rem",
                    },
                    inputField: {
                      MozPaddingStart: "calc(1rem - 3px)",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      lineHeight: "1.5",
                      color: "#5e6278",
                      appearance: "none",
                    },
                  }}
                />

                <span className="text-black-50 d-block py-2">
                  O desafio só vai aparecer para embaixadores categorizados com
                  alguma das tags selecionadas.
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="d-flex justify-content-between">
          <Link to="/dashboard/challenge">
            <span className="btn btn-secondary">Voltar</span>
          </Link>

          {res === 201 ? (
            <button className="btn btn-success" type="button">
              <span>Alterações realizadas com sucesso</span>
            </button>
          ) : (
            <button className="btn btn-primary text-white" type="submit">
              <span className={`${loading && "pe-5"}`}>
                {" "}
                {loading ? "Salvando Alterações" : "Salvar Alterações"}
              </span>

              {loading && (
                <Spinner animation="grow" variant="light" size="sm" />
              )}
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export { EditFormChallenge };
