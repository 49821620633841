import React from "react";

interface ProgressData {
  goalStatus: string;
  paymentStatus: string;
}

export function SpanProgress({ goalStatus, paymentStatus }: ProgressData) {
  return (
    <span>
      {
        goalStatus === 'pending' ? 'Aguardando aprovacao' :
        goalStatus === 'reproved' ? 'Conteúdo reprovado' :
        (goalStatus === 'approved' && paymentStatus === 'pending') ? 'Aguardando recompensa' :
        (goalStatus === 'approved' && paymentStatus === 'paid') ? 'Recompensa liberada' : ''
      }
    </span>
  );
}
