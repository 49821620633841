import { Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";


interface ControllerButtonData extends React.HTMLAttributes<HTMLButtonElement> {
  actualStep: number;
  setNextStep?: Function;
  disableNextStep?: boolean;
  handleSubmit?: Function
  loading?: boolean
}

const CreateEngagementChallengeWizzardController = ({ actualStep, loading, setNextStep, disableNextStep, handleSubmit }: ControllerButtonData) => {
  return (
    <div className={`d-flex justify-content-between align-items-center`}>
      {actualStep >= 1 ?
        <Button
          type='button'
          variant="light"
          onClick={() => setNextStep(actualStep - 1)}
        >Voltar</Button> :
        <Link to={"/dashboard/challenge"}>
          <span className="btn btn-light">Voltar</span>
        </Link>
      }
      <Button
        type='submit'
        variant={actualStep > 0 ? 'primary' : 'secondary'}
        disabled={disableNextStep}
      >
        {actualStep > 2 ? 'Criar Desafio' : 'Avançar'}
        {loading && <Spinner animation="grow" className={loading && 'ms-3'} variant="light" size="sm" />}
      </Button>
    </div>
  );
}

export { CreateEngagementChallengeWizzardController };