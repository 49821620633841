import React from "react";
import {IButtonProps} from "../types";
import {OverlayTrigger, Popover} from 'react-bootstrap';

export const ActionButton: React.FC<IButtonProps> = ({className, type, onClick, disabled, children}) => {
  const renderTooltip = (props) => (
    <Popover id="button-tooltip" {...props}>
      <div className='p-4 d-block fw-normal'>
        <div>
          {
            type === 'reward'
              ?
                'Liberar Recompensa'
              :
                type === 'approved'
                  ?
                    'Aprovar'
                  :
                    'Reprovar'
          }
        </div>
      </div>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger='hover'
      placement='top'
      overlay={renderTooltip}
    >
      <span className="d-inline-block">
        <button
          type={"button"}
          className={`custom-btn-${type} text-light mx-1`}
          disabled={disabled}
          onClick={(event) => onClick && onClick(event)}
        >
          {children}
        </button>
      </span>
    </OverlayTrigger>
  )
}