/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from 'react-router-dom';
import { ForgotPassword } from './components/ForgotPassword';
import { ResetPassword } from './components/ResetPassword';
import { Login } from './components/Login';
import { Confirmation } from './components/Confirmation';
import RegisterWizzard from './components/Register';


const AuthPage = () => (
    <Routes>
        <Route>
            <Route path='login' element={<Login />} />
            <Route path='registration' element={<RegisterWizzard />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='confirmation' element={<Confirmation />} />
            <Route path='recovery-password' element={<ResetPassword />} />
            <Route index element={<Login />} />
        </Route>
    </Routes>
)

export { AuthPage }
