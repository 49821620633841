/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { Modal, Spinner, Form } from 'react-bootstrap';
import draftToHtml from 'draftjs-to-html';

import { TbAlertCircle } from 'react-icons/tb';
import { BsFillPersonFill } from 'react-icons/bs';
import { RiLoaderLine } from 'react-icons/ri';


import { SalesChallengeDashboard } from 'app/components/Metabase';
import EngagementChallengeDashboard from 'app/components/Metabase/Challenges/Engagement/index';
import { Table, Review } from "app/components/Table";
import { Check, XMark } from "app/components/Icons";
import { ActionButton } from "app/components/Buttons";
import { ActionModal } from "./components/Modals";
import { SpanProgress } from './components/Progress';
import Pagination from 'app/components/Pagination/Pagination';

import Token from 'app/modules/hooks/Token/Token';
import Money from 'app/modules/hooks/Money/Money';
import { DateFormat, DateTimeFormat } from 'app/modules/hooks/DateFormat/DateFormat';
import ValideteError from 'app/modules/hooks/ValideteError/ValideteError';
import { useAuth } from 'app/modules/auth';

import { ChallengeTableAmbassadorData } from './types';
import MetricTitle from 'app/modules/hooks/MetricTitle/MetricTitle';

interface list {
  id: number
  ambassadors: string,
  nominalCoupon: string,
  salesMade: string,
  canceledSales: string,
  commissionPayable: number,
  commissionPaud: number,
  admissionDate: string
}

const ViewChallenge = () => {
  const auth = Token();
  const { id } = useParams();
  const history = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const descriptionRef = useRef<HTMLDivElement>(null);

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [ambassadorsList, setAmbassadorsList] = useState<ChallengeTableAmbassadorData[]>([]);
  const [filterData, setFilterData] = useState('all');
  const [paymentData, setPaymentData] = useState('all');

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [show, setShow] = useState(false);
  const [process, setProcess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [validate, setValidate] = useState<any>(null);
  const [order, setOrder] = useState('desc');
  const [loadingTable, setLoadingTable] = useState(false);
  const [table, setTable] = useState<list[]>([]);
  const [loading, setLoading] = useState(false);

  const [blocos, setBlocos] = useState<any>({
    id: 0,
    title: '',
    description: '',
    expiration: '',
    banner: '',
    maxParticipantsNumber: 0,
    maxRewardsPerParticipant: 0,
    interactionGoals: [],
    challengeType: '',
    rewardValue: {
      type: "",
      value: 1  
    },
    minRewardValue: '',
    maxRewardValue: '',
    discount: 0,
    minimumSubtotal: {
      type: "",
      value: 1
    },
    paymentDays: 0,
    usedCoupons: 0,
    totalReseved: 0,
    totalRefundable: 0,
    typeOfCommission: "",
    discountValue: {
      type: "",
      value: 1
    },
  });

  const getBlocks = async () => {
    try {
      
      setLoading(true);
      const res = await axios.post('/colab/get-challenge', {
        "token": auth,
        "id": id,
        "type": location.state
      });

      setLoading(false);
      setBlocos(res.data);
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      }
    }
  }

  const handleGetTableData = async () => {
    
    try {
      setLoading(true);

      const res = await axios.post('/colab/challenges/list-subscriptions', {
        "token": auth,
        "challengeId": id
      });

      setAmbassadorsList(res.data.ambassadors);
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {

    Validate()
    // eslint-disable-next-line
    getBlocks()
    handleGetTableData()
    // eslint-disable-next-line
  }, [total, limit]);

  const handleRemoveChallenge = () => {
    
    if (blocos.challengeType === 'engagement') RemoveEngagementChallenge(blocos.id)
    else RemoveChallenge(blocos.id)
  }

  const RemoveChallenge = async (id: number) => {
    try {
      setProcess(false)
      setSuccess(false)
      const res = await axios.post('/colab/delete-challenge', {
        "token": auth,
        "id": id,
      })
      setProcess(true)
      if (res.status === 204 || res.status === 200) {
        setSuccess(true)
        window.analytics.track('Challenge Disabled', {
          challengeId: blocos.id,
          nameOfChallenge: blocos.title,
          type: location.state,
          commissionValue: blocos.commissionPerCoupon,
          discountValue: blocos.couponDiscount
        })
        setTimeout(() => {
          history('/dashboard/challenge')
        }, 4000);
      }
    } catch (e) {
      if (ValideteError(e)) {
        logout()
      }
    }
  }

  const RemoveEngagementChallenge = async (id: number) => {
    try {
      
      setProcess(false)
      setSuccess(false)
      const res = await axios.post('/colab/delete-engagement-challenge', {
        accessToken: auth,
        challengeId: id
      })
      setProcess(true)
      if (res.status === 204 || res.status === 200) {
        setSuccess(true)
        setTimeout(() => {
          history('/dashboard/challenge')
        }, 4000);
      }
    } catch (e) {
      if (ValideteError(e)) {
        logout()
      }
    }
  }

  const handleShow = () => {

    setShow(true)
  };
  const handleClose = () => {
    
    setShow(false)
    setProcess(false)
    setSuccess(false)
  }

  const Validate = () => {

    const str = DateFormat(blocos.expiration)
    const data = new Date(str.split('/').reverse().join('/'))
    const novaData = new Date()
    if (data > novaData) {
      setValidate(false)
    } else {
      setValidate(true)
    }
  }

  const Influencer = async () => {
    setLoadingTable(true);

    const res = await axios.post('/colab/challenges/list-influencers', {
      "challengeId": id,
      "page": currentPage,
      "pageSize": limit,
      "order": order
    });

    setLoadingTable(false);
    setTable(res.data.ambassadors);
    setTotal(res.data.stats.totalAmbassadors);
  }
  const handleApprovalAction = async (data: any) => {
    try {

      await axios.post(`/colab/subscriptions/${data.type === 'approved' ? 'approve' : 'reprove'}`, {
        token: auth,
        subscriptionsIds: data.id
      });

      handleGetTableData();
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {


    Influencer()
    // eslint-disable-next-line
  }, [limit, order, currentPage]);

  useEffect(() => {

    const description = blocos?.description;

    if (description.toString().includes('{')) {
      descriptionRef.current.innerHTML = `${draftToHtml(JSON.parse(blocos.description))}`;
    } else {
      descriptionRef.current.innerHTML = `${description}`;
    }
  }, [blocos]);



  return <>
    {
      loading ?
        <div className='d-flex justify-content-center p-20'>
          <Spinner animation="grow" variant="info" />
        </div>
        :
        <>
          <div className='d-flex flex-column align-items-start justify-content-start flex-lg-row justify-content-md-between'>
            <div className='position-relative pb-5'>
              <h1>{blocos.title}</h1>
              <p className='text-gray-400 fw-bold fs-5 d-inline mt-5 ms-2'>ID: {blocos.id}</p>
            </div>

            <div>
              <Link to={`/dashboard/challenge/${location.state}/edit/${id}`}>
                <span className="btn btn-primary me-2 me-sm-2">
                  <span className="svg-icon svg-icon-light svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor" />
                    <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor" />
                  </svg></span>
                  <span className='ps-5'>Editar Desafio</span>
                </span>
              </Link>

              <button className='btn btn-outlined-danger border border-danger' onClick={handleShow}>
                <span className="svg-icon svg-icon-danger svg-icon-2hx">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                    <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                    <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
                  </svg>
                </span>
                <span className='ps-5 text-danger'>Excluir Desafio</span>
              </button>
            </div>
          </div>

          <section className='row mt-12'>
            <div className='col-md-6'>
              <article className='bg-light bg-opacity-50 text-gray-800 rounded-3 p-10 mx-md-5 h-md-100'>
                <h4>Descrição do Desafio</h4>
                <div className='pt-4' ref={descriptionRef}></div>
              </article>
            </div>

            <div className='col-md-6'>
              <article className='bg-light bg-opacity-50 text-gray-800 rounded-3 p-10 mx-md-5 h-md-100 max'>
                <h4 className='text-gray-800 mb-5'>Informações Adicionais</h4>
                {location?.state === 'engagement' ?
                  <ul>
                    <li className='mb-2'>
                      <strong>Tipo de Desafio:</strong> {blocos?.challengeType === 'engagement' ? 'Engajamneto' : 'Vendas'}
                    </li>
                    <li className='mb-2'>
                      <strong>Validade:</strong> {DateFormat(blocos?.expiration)}
                    </li>
                    <li className='mb-2'>
                      <strong>Valor da recompensa:</strong> {blocos?.rewardValue?.value === '' || blocos?.rewardValue?.type === 'custom' ? 0 : Money(blocos?.rewardValue?.value)}
                    </li>
                    {blocos?.rewardValue?.type === 'custom' && (
                      <li className='mb-2'>
                        <strong>Descrição da recompensa:</strong> {blocos?.rewardValue?.value}
                      </li>
                    )}
                    <li className='mb-2'>
                      <strong>Máximo de recompensas por participante:</strong> {blocos?.maxRewardsPerParticipant}
                    </li>
                    <li className='mb-2'>
                      <strong>Máximo de participantes:</strong> {blocos?.maxParticipantsNumber}
                    </li>
                    <li className='mb-2'>
                      <strong>Metas de Interação:</strong>
                      {blocos?.interactionGoals === null && '0'}
                      {blocos?.interactionGoals?.map((data, i) => (
                        <ul className='mt-3' key={i}>
                          <li className='mb-2' style={{ listStyleType: 'disc' }}>{`${data?.value} ${MetricTitle(data?.name)}`}</li>
                        </ul>
                      ))}
                    </li>
                  </ul> : <ul>
                    <li className='mb-2'>
                      <strong>Tipo de Desafio: </strong> {blocos?.challengeType === 'engagement' ? 'Engajamneto' : 'Vendas'}
                    </li>
                    <li className='mb-2'>
                      <strong>Validade:</strong> {DateFormat(blocos?.expiration)}
                    </li>
                    <li className='mb-2'>
                      <strong>Valor da Recompensa: </strong>
                      {blocos?.rewardValue?.type === 'money' ? Money(blocos?.rewardValue?.value) : <>{blocos?.rewardValue?.value}%</>}
                    </li>
                    <li className='mb-2'>
                      <strong>Desconto: </strong>
                      {blocos.discountValue.type === "money" ? Money(blocos?.discountValue.value) : <>{blocos?.discountValue?.value}%</>}

                    </li>
                    <li className='mb-2'>
                      <strong>Pedido Mínimo: </strong> {blocos?.minimumSubtotal?.value === '' ? 0 : Money(blocos?.minimumSubtotal?.value)}
                    </li>
                    <li className='mb-2'>
                      <strong>Prazo de liberação das Recompensas: </strong> {blocos?.paymentDays}
                    </li>
                  </ul>}
              </article >
            </div>
          </section>

          {
            location?.state === 'engagement' ?
              <>
                <EngagementChallengeDashboard challengeId={blocos?.id} />

                <section className='mt-10'>
                  <div className="d-flex justify-content-between pb-4">
                    <div className="title">
                      <h1>Conteúdos recebidos</h1>
                      <span className="fw-light">Revise os conteúdos e aprove ou reprove os que bateram a meta</span>
                    </div>

                      <>
                        <div className="d-flex align-items-center">
                          <span className='pr-4'>Filtro por Aprovação: </span>

                          <Form.Select aria-label="Filtro por status de aprovação de conteúdo" onChange={(item) => setFilterData(item.target.value)}>
                            <option value="all">Todos</option>
                            <option value="approved">Aprovados</option>
                            <option value="reproved">Rejeitados</option>
                            <option value="pending">Pendentes</option>
                          </Form.Select>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className='pr-4'>Filtro por Recompensa: </span>

                          <Form.Select aria-label="Filtro por status de liberação de pagamento" onChange={(item) => setPaymentData(item.target.value)}>
                            <option value="all">Todos</option>
                            <option value="paid">Recompensa Liberada</option>
                            <option value="pending">Recompensa Pendente</option>
                          </Form.Select>
                        </div>
                      </>

                    {
                      Object.keys(selectedItems).length !== 0
                        ?
                        <div className="d-flex align-items-center">
                          <span className='mr-4'>Selecionados: </span>

                          <ActionButton
                            type="approved"
                            onClick={() => {
                              setModalType('approved');
                              setShowModal(true);
                            }}
                          >
                            <Check width={14} />
                          </ActionButton>

                          <ActionButton
                            type="reproved"
                            onClick={() => {
                              setModalType('reproved');
                              setShowModal(true);
                            }}
                          >
                            <XMark width={14} />
                          </ActionButton>
                        </div>
                        :
                        ''
                    }
                  </div>

                  <div className="scroll-view">
                    <Table
                      headers={[
                        { value: 'embaixador', isSortable: false },
                        // { value: 'instagram', isSortable: true },
                        { value: 'Data de envio', isSortable: false },
                        // { value: 'desempenho' },
                        // { value: 'recompensa', isSortable: true },
                        { value: 'Link do conteúdo' },
                        { value: 'aprovar?' },
                        { value: 'Recompensar?', isSortable: false },
                        { value: 'Status Atual', isSortable: false }
                      ]}
                      rows={
                        Object.keys(ambassadorsList).length === 0 ? [] :
                          ambassadorsList
                            .filter((ambassador, index) => {
                              return (
                                (
                                  filterData === 'all'
                                    ?
                                    index >= 0
                                    :
                                    ambassador.status === filterData
                                )
                                &&
                                (
                                  paymentData === 'all'
                                    ?
                                    index >= 0
                                    :
                                    ambassador.paymentStatus === paymentData
                                )
                              );
                            })
                            .map((ambassador) => ({
                              key: ambassador.id,
                              values: [
                                ambassador.ambassadorName,
                                // ambassador.instagramUserName,
                                DateFormat(ambassador.dataFromSend),
                                // <Progress
                                //   percent={100}
                                //   status='Desafio Concluído'
                                //   multiplier='x2'
                                //   goals={ambassador.progress.goals}
                                // />,
                                // <Timer time="em 2 horas" />,
                                <Review link={ambassador.contentLink} />,
                                <div className={"d-flex"}>
                                  <ActionButton
                                    type="approved"
                                    className={'text-light mx-1'}
                                    onClick={() => handleApprovalAction({ type: 'approved', id: ambassador.id })}
                                    disabled={ambassador.status === 'approved' || Object.keys(selectedItems).length > 0}
                                  >
                                    <Check width={14} />
                                  </ActionButton>

                                  <ActionButton
                                    type="reproved"
                                    className={'text-light mx-1'}
                                    onClick={() => handleApprovalAction({ type: 'reproved', id: ambassador.id })}
                                    disabled={ambassador.paymentStatus === 'paid' || ambassador.status === 'reproved' || Object.keys(selectedItems).length > 0}
                                  >
                                    <XMark width={14} />
                                  </ActionButton>
                                </div>,
                                (
                                  ambassador.status === 'reproved' ? '' :
                                    ambassador.paymentStatus === 'paid' ? (
                                      <span>{DateTimeFormat(ambassador.paymentMoment)}</span>
                                    ) : (
                                      <div className={"d-flex"}>
                                        <ActionButton
                                          type="reward"
                                          className={'text-light mx-1'}
                                          onClick={() => {
                                            setSelectedData([ambassador.id]);

                                            setModalType('reward');
                                            setShowModal(true);
                                          }}
                                          disabled={ambassador.paymentStatus === 'paid' || ambassador.status !== 'approved' || Object.keys(selectedItems).length > 0}
                                        >
                                          <Check width={14} />
                                        </ActionButton>
                                      </div>
                                    )
                                ),
                                <SpanProgress
                                  goalStatus={ambassador.status}
                                  paymentStatus={ambassador.paymentStatus}
                                />
                              ]
                            }
                            ))
                      }

                      onSelectedRowsChange={rowKeys => setSelectedItems(rowKeys)}

                      hasBatchActions={true}
                    />

                    <ActionModal
                      modalType={modalType}
                      modalControl={showModal}
                      setModalControl={setShowModal}

                      modalActionItems={selectedItems}
                      setModalActionItems={setSelectedItems}

                      actionData={selectedData}
                      setActionData={setSelectedData}

                      onConfirmationSuccess={handleGetTableData}
                    />
                  </div>
                </section>
              </>
              :
              <>
                <SalesChallengeDashboard challengeId={blocos.id} />
              </>
          }

          <Modal show={show} onHide={handleClose}>
            {loading ? <div className='d-flex justify-content-center h-100 flex-column align-items-center h-100px '>
              <div className="spinner-grow text-primary" role="status" />
            </div> : <>
              {success ?
                // <Modal.Body className='d-flex justify-content-center flex-column align-items-center'>
                //     <MdOutlineHourglassTop className='text-gray-600 fa-5x' />
                //     <h2 className='mt-5'>Excluindo desafio</h2>
                //     <p className='text-gray-500'>Aguarde enquanto processamos sua solicitação</p>
                // </Modal.Body>
                // :
                <Modal.Body className='d-flex justify-content-center flex-column align-items-center'>
                  <span className="svg-icon svg-icon-primary svg-icon-2hx">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                      <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
                    </svg>
                  </span>
                  <h2 className='mt-5'>O desafio foi excluído</h2>
                  <button type='button' className='btn btn-primary text-white mt-5' onClick={handleClose}>O desafio foi excluído</button>
                </Modal.Body>
                : <>
                  <Modal.Body>
                    <div className='alert alert-danger border-0'>
                      <h2 className='mb-0 text-danger text-center'>
                        <TbAlertCircle className='fs-2  me-3' />
                        Atenção
                      </h2>
                    </div>
                    <p className='my-5 text-center'>Você está prestes a excluir este desafio</p>
                    <p>Todas as vendas feitas com cupom durante o período ativo da campanha ainda terão o desconto aplicado.</p>
                    <p>Valor parcial referente ao pagamento de embaixadores participantes no período de pagamento definido previamente por você. </p>
                    <p>Caso alguma compra seja estornada durante este período, o valor de comissão não será pago ao embaixador e será devolvido a sua carteira.</p>
                    <p>Os cupons não utilizados serão cancelados e o desafio será removido do aplicativo imediatamente. </p>
                    <p>Todos os seus embaixadores serão avisados sobre o término antecipado.</p>
                    <div className='py-10 text-center fw-bold'>
                      <p className='mb-0'>Você tem certeza de que quer excluir o desafio?</p>
                      <p className='text-danger'> Essa ação não poderá ser desfeita</p>
                    </div>
                    <div className='row'>
                      <div className='col-5'>
                        <button className='btn w-100 border border-2 border-gray-600 text-gray-600' onClick={handleClose}>Voltar</button>
                      </div>
                      <div className='col-7'>
                        <button className='btn btn-danger w-100' onClick={handleRemoveChallenge}>
                          <span className={`${process && 'pe-5'}`}> {process ? 'Excluindo' : 'Excluir desafio'}</span>
                          {process && <Spinner animation="grow" variant="light" size='sm' />}
                        </button>
                      </div>
                    </div>
                  </Modal.Body>
                </>}
            </>}
          </Modal>
        </>
    }
  </>
   
}

  export default ViewChallenge;