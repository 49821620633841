import { useFormik } from "formik";

import { MaskInput, SubTitle, TextField } from "app/components";
import { RegisterWizzardController } from "../../components/Controller";

import { WizzardFrameData } from "../types";
import { RegisterType } from "./types";
import { useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import schema from "./schema";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import { ModalButton } from "app/components/Buttons";
import { canvasPreview, useDebounceEffect } from "utils/formatImg64";

const RegisterFormSchema = schema;

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const Register = ({
  formData,
  setFormData,
  actualPage,
  setNextPage,
  actualField,
  setNextField,
}: WizzardFrameData) => {
  const [brandLogo, setBrandLogo] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [errorRequest, setErrorRequest] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [crop, setCrop] = useState<Crop>();
  const [imgSrc, setImgSrc] = useState("");
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const currentValidationSchema = RegisterFormSchema[actualField];

  const initialValues: RegisterType = {
    name: "",
    email: "",
    whatsapp: "",
    companyName: "",
    siteAddress: "",
    password: "",
    passwordConfirmation: "",
    acceptTerms: false,
  };

  const createBrand = async (data: {
    name?: string;
    email?: string;
    whatsapp: any;
    companyName?: string;
    siteAddress?: string;
    password?: string;
    passwordConfirmation?: string;
    acceptTerms?: boolean;
    logoUrl?: string;
    logoThumbUrl?: string;
  }) => {
    try {
      setLoading(true);
      const res = await axios.post("/create-brand", {
        ...data,
        logoFileName: data?.logoUrl,
        logoFileNameThumb: data?.logoThumbUrl,
        service: "colab",
        whatsapp: (data.whatsapp = `55${data.whatsapp
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "")
          .replace("-", "")}`),
      });
      localStorage.setItem("accessToken", res.data.accessToken);
      updateStatus(res.data.accessToken);
    } catch (e) {
      setErrorMessage(
        "Ocorreu um erro inesperado. Por favor tente novamente mais tarde"
      );
      setErrorRequest(true);
      setLoading(false);
    }
  };

  const validateEmail = async (data: any) => {
    setLoading(true);
    try {
      const res = await axios.post("/auth/verify-email", {
        email: data,
        context: "brand",
      });
      setLoading(false);
      if (res.data.existsEmail)
        return formik.setErrors({ email: "Email já registrado." });
      formik.setTouched({});
      return setNextField(actualField + 1);
    } catch (e) {
      setLoading(false);
      formik.setErrors({
        email: "Ocorreu um erro inesperado, tente novamente",
      });
    }
  };

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function handleEditBannerImage() {
    fileRef.current.click();
  }

  const updateStatus = async (token) => {
    try {
      await axios.post("/brand/save-register-status", {
        accessToken: token,
        code: 1002,
      });
      return setNextPage(actualPage + 1);
    } catch (e) {
      console.log(e);
    }
  };

  const treatImage = async (form: any) => {
    setLoading(true)
    try {
      const response = await axios.post("/ms/treatment-images/logo", {
        imageObject: {
          data: {
            brandname: formik.values.companyName,
            width: Math.floor(completedCrop.width),
            height: Math.floor(completedCrop.height),
            widthThumb: 74,
            heightThumb: 74,
          },
          imgB64: brandLogo.image,
        },
      });
      setLoading(false)
      formik.setTouched({});
      form.logoUrl = response.data.data.logoUrl;
      form.logoThumbUrl = response.data.data.logoUrlThumb;
      createBrand(form);
    } catch (e) {
      console.log(e);
      setErrorMessage(
        "Ocorreu um erro inesperado. Por favor tente novamente mais tarde"
      );
      setErrorRequest(true);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: currentValidationSchema,
    onSubmit: async (registry: RegisterType) => {
      if (actualField === 1) return validateEmail(registry.email);
      if (actualField === 5) setBrandLogo({ filename: 'nomeImage', image: previewCanvasRef.current.toDataURL("image/png") })
      if (actualField < 6) {
        formik.setTouched({});
        return setNextField(actualField + 1);
      }
      setFormData({
        ...formData,
        ...registry,
      });
      treatImage(registry);
    },
  });

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0
        );
      }
    },
    100,
    [completedCrop]
  );

  return (
    <form
      onSubmit={formik.handleSubmit}
      noValidate
      className="d-flex flex-column justify-content-lg-center"
    >
      {actualField === 0 && (
        <>
          <SubTitle
            title="Qual o seu nome?"
            description="Antes de cadastrar a marca, queremos conhecer melhor quem vai utilizar a plataforma."
          />

          <div className="mt-3 mb-10">
            <TextField
              placeholder="Nome do responsável"
              name="name"
              autoFocus
              touched={formik.touched.name}
              error={formik.errors.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              {...formik.getFieldProps("name")}
            />
          </div>
        </>
      )}

      {actualField === 1 && (
        <>
          <SubTitle
            title="Qual será o e-mail de cadastro?"
            description="Esse será o seu login no nosso painel, por isso, Informe um e-mail que possa ser usado por todo o time que for gerenciar a BrandLovrs :)"
          />

          <div className="mt-3 mb-10">
            <TextField
              placeholder="Email"
              name="email"
              autoFocus
              touched={formik.touched.email}
              error={formik.errors.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              {...formik.getFieldProps("email")}
            />
          </div>
        </>
      )}

      {actualField === 2 && (
        <>
          <SubTitle
            title="Qual o número do WhatsApp?"
            description="Esse será o principal canal pra gente se comunicar com você, mas fique atento ao seu e-mail também :)"
          />

          <div className="mt-3 mb-10">
            <MaskInput
              placeholder="Whatsapp"
              name="whatsapp"
              mask="phone"
              autoFocus
              touched={formik.touched.whatsapp}
              error={formik.errors.whatsapp}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              {...formik.getFieldProps("whatsapp")}
            />
          </div>
        </>
      )}

      {actualField === 3 && (
        <>
          <SubTitle
            title="Qual o nome da marca?"
            description="Este dado será exibido para os seus Lovrs."
          />

          <div className="mt-3 mb-10">
            <TextField
              placeholder="Nome da marca"
              name="companyName"
              autoFocus
              touched={formik.touched.companyName}
              error={formik.errors.companyName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              {...formik.getFieldProps("companyName")}
            />
          </div>
        </>
      )}

      {actualField === 4 && (
        <>
          <SubTitle
            title="E o site?"
            description="Informe o link (URL) do seu e-commerce ou site institucional. Este dado será exibido para os seus Lovrs.

            Ex: https://www.brandlovrs.com"
          />

          <div className="mt-3 mb-10">
            <TextField
              placeholder="Site"
              name="siteAddress"
              autoFocus
              touched={formik.touched.siteAddress}
              error={formik.errors.siteAddress}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              {...formik.getFieldProps("siteAddress")}
            />
          </div>
        </>
      )}

      {actualField === 5 && (
        <>
          <div className="mt-3 mb-10">
            <div>
              <div className="d-flex mt-5 justify-content-between">
                {!!imgSrc && (
                  <div className="d-flex align-items-center">
                    <ModalButton
                      title="Editar"
                      buttonType="outlined"
                      type="button"
                      onClick={handleEditBannerImage}
                    />
                    <ModalButton
                      title="Excluir"
                      buttonStyle="reproved"
                      buttonType="primary"
                      type="button"
                      onClick={() => {
                        setImgSrc("");
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="m-auto mt-3">
                <input
                  accept="image/*"
                  id="avatar"
                  type="file"
                  ref={fileRef}
                  onChange={onSelectFile}
                  hidden
                />

                {!!imgSrc && (
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={1}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={imgSrc}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                )}

                {!!completedCrop && (
                  <>
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          border: "1px solid black",
                          objectFit: "contain",
                          width: 0,
                          height: 0,
                        }}
                      />
                    </div>
                  </>
                )}

                {!imgSrc && (
                  <label
                    htmlFor="avatar"
                    className="border-dashed alert alert-dismissible bg-ligth-blue border-dashed-ligth-blue d-flex align-items-center flex-column flex-sm-row p-5 mb-10 border-1 cursor-pointer"
                  >
                    <span
                      className={`svg-icon svg-icon-primary svg-icon-2hx pe-6`}
                      dangerouslySetInnerHTML={{
                        __html: `
                      <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M24.2448 28.5938V9.84375C24.2448 8.80822 23.399 7.96875 22.3556 7.96875C21.3123 7.96875 20.4665 8.80822 20.4665 9.84375V28.5938C20.4665 29.6293 21.3123 30.4688 22.3556 30.4688C23.399 30.4688 24.2448 29.6293 24.2448 28.5938Z" fill="#009EF7"/>
                        <path d="M22.9361 11.4727L25.6999 14.7644C26.4401 15.6461 27.7834 15.7066 28.6011 14.895C29.3373 14.1643 29.372 12.9907 28.6802 12.2183L23.7675 6.73284C23.016 5.89374 21.6951 5.89374 20.9437 6.73284L16.0309 12.2183C15.3392 12.9907 15.3739 14.1643 16.1101 14.895C16.9278 15.7066 18.271 15.6461 19.0113 14.7644L21.7751 11.4727C22.0771 11.1128 22.634 11.1128 22.9361 11.4727Z" fill="#009EF7"/>
                        <path opacity="0.3" d="M35.5795 15.4688H33.6904C32.647 15.4688 31.8013 16.3082 31.8013 17.3437C31.8013 18.3793 32.647 19.2187 33.6904 19.2187C34.7338 19.2187 35.5795 20.0582 35.5795 21.0937V34.2187C35.5795 35.2543 34.7338 36.0937 33.6904 36.0937H11.0208C9.97746 36.0937 9.13166 35.2543 9.13166 34.2187V21.0937C9.13166 20.0582 9.97744 19.2187 11.0208 19.2187C12.0641 19.2187 12.9099 18.3793 12.9099 17.3437C12.9099 16.3082 12.0641 15.4688 11.0208 15.4688H9.13166C7.04498 15.4688 5.35339 17.1477 5.35339 19.2187V36.0937C5.35339 38.1649 7.04498 39.8437 9.13166 39.8437H35.5795C37.6663 39.8437 39.3578 38.1649 39.3578 36.0937V19.2187C39.3578 17.1477 37.6663 15.4688 35.5795 15.4688Z" fill="#009EF7"/>
                      </svg>
                    `,
                      }}
                    />

                    <div>
                      <h2>Pode nos mandar o logotipo?</h2>
                      <p className={`text-gray-500 mb-0`}>
                        Mande um logo que tenha o nome da marca pra aparecer no
                        carrossel que vamos mostrar na nossa plataforma. Prefira
                        o logo em um fundo não-branco pra chamar mais atenção.
                      </p>
                    </div>
                  </label>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {actualField === 6 && (
        <>
          <SubTitle
            title="Qual será a senha de cadastro?"
            description="Essa será a sua senha no nosso painel :)"
          />

          <div className="mt-3 mb-5">
            <TextField
              placeholder="Senha"
              name="password"
              autoFocus
              type="password"
              touched={formik.touched.password}
              error={formik.errors.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              {...formik.getFieldProps("password")}
            />
          </div>

          <div className="mt-3 mb-10">
            <TextField
              placeholder="Repita sua Senha"
              name="passwordConfirmation"
              type="password"
              touched={formik.touched.passwordConfirmation}
              error={formik.errors.passwordConfirmation}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              {...formik.getFieldProps("passwordConfirmation")}
            />
          </div>

          <div className="fv-row">
            <div className="form-check form-check-custom form-check-solid form-check-info mb-19">
              <div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="kt_login_toc_agree"
                  {...formik.getFieldProps("acceptTerms")}
                />
                <label
                  className="form-check-label fw-bold text-gray-700 fs-7"
                  htmlFor="kt_login_toc_agree"
                >
                  Li e concordo com os
                  <Link
                    to="/auth/terms"
                    className="ms-1 link-info text-decoration-underline"
                  >
                    Termos e Condições de uso
                  </Link>
                  .
                </label>
                {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                  <small className="text-danger fw-bold d-block mx-9">
                    {formik.errors.acceptTerms}
                  </small>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {errorRequest && (
        <span className="text-danger fw-bold d-block mb-5">{errorMessage}</span>
      )}

      <RegisterWizzardController
        actualField={actualField}
        setNextField={setNextField}
        loading={loading}
      />
    </form>
  );
};

export { Register };
