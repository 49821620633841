import React, { useEffect, useState } from "react";
import metabase from "app/modules/services/metabase";

interface ChallengeDashboardData {
  challengeId: number;
}

export function SalesChallengeDashboard(challengeId: ChallengeDashboardData) {
  const [iframe, setIframe] = useState<any>({
    header: "",
    urlEmbed: ""
  });

  const hash_token = `27d493bbe37e63e87c2e982b59c04af79a2b5419`;

  const GetSalesChallengeDashboard = async () => {
    try {
      const response = await metabase.post('/dashboard/get-dashboard', {
        hash: hash_token,
        challenge_id: [challengeId.challengeId],
      });

      setIframe(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (challengeId.challengeId) {
      GetSalesChallengeDashboard();
    }
    // eslint-disable-next-line
  }, [challengeId]);

  return (
    <>
      {/* <h1 className="mb-10">Painel Geral - Resumo das atividades</h1> */}
        <iframe
          title="dashboard"
          src={iframe.urlEmbed}
          frameBorder="0"
          width="800"
          height="1300"
          style={{ width: "100%" }}
          className="mb-10 mt-10"
        />
      {/* <ChartRequests /> */}
    </>
  );
};
