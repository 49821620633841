import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import {
  Spinner,
  Tooltip,
  OverlayTrigger,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
} from "react-bootstrap";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";

import { useAuth } from "../../modules/auth";
import Base64 from "../../modules/hooks/Base64/Base64";
import Token from "../../modules/hooks/Token/Token";
import ValideteError from "../../modules/hooks/ValideteError/ValideteError";

import Styles from "./Profile.module.scss";

import { ReactComponent as InstagramIcon } from "../../assets/svg/socials/instagram.svg";
import { ReactComponent as FacebookIcon } from "../../assets/svg/socials/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/svg/socials/twitter.svg";
import { ReactComponent as YouTubeIcon } from "../../assets/svg/socials/youtube.svg";
import { ReactComponent as TikTokIcon } from "../../assets/svg/socials/tiktok.svg";
import { ReactComponent as TooltipIcon } from "../../assets/svg/general/tooltip.svg";

import { useBannerContext } from "../../modules/providers";

import "react-image-crop/dist/ReactCrop.css";

import {
  TextArea,
  Select,
  Warning,
  TextField,
  RowSocial,
} from "../../components";

import { formSchema } from "./Form.schema";
import { ICategory, IntegrationProfileType } from "./types";
import { erroAlert, successAlert } from "app/components/ModalSwal/ModalSwal";
import { canvasPreview, useDebounceEffect } from "utils/formatImg64";

import jwt_decode from "jwt-decode";

const initialValues: IntegrationProfileType = {
  categoryId: "",
  url: "https://exemplo.com",
  aboutMe: "",
  name: "",
  acceptRule: "",
  formBanner: {
    filename: "",
    data: "",
  },
  banner: {
    filename: "",
    data: "",
  },
  logo: {
    filename: "",
    data: "",
  },
  socials: [
    {
      fk_id_colab_type_label: 1,
      value: "",
    },
    {
      fk_id_colab_type_label: 2,
      value: "",
    },
    {
      fk_id_colab_type_label: 3,
      value: "",
    },
    {
      fk_id_colab_type_label: 4,
      value: "",
    },
    {
      fk_id_colab_type_label: 5,
      value: "",
    },
    {
      fk_id_colab_type_label: 6,
      value: "",
    },
    {
      fk_id_colab_type_label: 7,
      value: "",
    },
    {
      fk_id_colab_type_label: 8,
      value: "",
    },
    {
      fk_id_colab_type_label: 9,
      value: "",
    },
    {
      fk_id_colab_type_label: 10,
      value: "",
    },
  ],
};

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const FILE_SIZE = 1660 * 660;

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export const Profile = () => {
  const { handleBannerChange } = useBannerContext();
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [bannerFile, setBannerFile] = useState<any>({});
  const [formBannerFile, setFormBannerFile] = useState<any>({});
  const [logoFile, setLogoFile] = useState<any>({});
  const [warning, setWarning] = useState<boolean>(false);
  // const [base, setBase] = useState<unknown>();
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({} as { email: string });
  const [fileMessage, setfileMessage] = useState(!false);
  const [show, setShow] = useState(false);
  const [crop, setCrop] = useState<Crop>();
  const [imgSrc, setImgSrc] = useState("");
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const user = Token();
  const { logout } = useAuth();

  function handleBannerFile(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    setBannerFile(e.target.files[0]);
  }

  function handleFormBannerFile(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    setFormBannerFile(e.target.files[0]);
  }

  const handleCloseModal = () => setShow(false);

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  }

  const treatLogo = async () => {
    setLoading(true);
    const base64 = previewCanvasRef.current.toDataURL("image/png");
    console.log(base64)
    try {
      const response = await axios.post("/save-logo", {
        accessToken: user,
        logo: {
          data: base64,
        },
      });
      setLogoFile({
        filename: response.data.fileName,
        data: base64,
      });
      setLoading(false);
      setShow(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const postImageasync = async () => {
    try {
      const base64 = await Base64(bannerFile);
      if (!base64) return;
      // setBase(base64);
      const res = await axios.post("/colab/upload-banner-brand", {
        brandToken: user,
        filename: bannerFile.name,
        data: base64,
      });
      if (!bannerFile || bannerFile === undefined) {
        return;
      }
      setLoading(false);
      showAlert(res.status)
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      }
      setLoading(false);
      showAlert(e.response.status)
    }
  };

  const postFormBannerSync = async () => {
    const decodedToken: any = jwt_decode(user);
    const base64 = await Base64(formBannerFile);
    try {
      const res = await axios.post("/profile/brand/edit-form", {
        brandId: decodedToken.permissions[0]?.brandId,
        banner: base64,
      });
      if (!formBannerFile || formBannerFile === undefined) {
        return;
      }
      setLoading(false);
      showAlert(res.status)
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      }
      setLoading(false);
      showAlert(e.response.status)
    }
  };

  const getBrandInfo = async () => {
    try {
      const response = await axios
        .post("colab-list-info", { brandToken: user })
        .then((response) => response.data);
      if (!response.banner || !response.aboutMe) {
        setWarning(true);
      }
      // if (typeof response.banner === "string") delete response.banner;
      formik.setValues({ ...initialValues, ...response });
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      }
    }
  };

  const getUser = async () => {
    const payload: string = localStorage.getItem("accessPayload");
    let parsedPayload;
    if (payload) parsedPayload = JSON.parse(payload);
    const user = await axios
      .post(`/profile/show`, { id: parsedPayload.sub })
      .then((response) => response.data)
      .catch(() => false);
    // TODO - utilizar estado global
    if (!user || !user.email) return;
    setCurrentUser({ email: user.email });
  };

  const showAlert = (status: number) => {
    if (status === 200) {
      return successAlert("Ebaa", "Os dados foram atualizados com sucesso!");
    } else {
      return erroAlert("Opss", "Ocorreu um erro ao atulizar os dados!");
    }
  };

  const getInterests = async () => {
    try {
      const response = await axios
        .post("colab/interests", { brandToken: user })
        .then((response) => response.data);
      setCategories(response);
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      }
    }
  };

  const normalizeValues = (img) => {
    let normalized: any = formik.values;

    normalized = {
      ...normalized,
      categoryId: normalized.categoryId
        ? parseInt(normalized.categoryId)
        : null,
      acceptRule: normalized.acceptRule
        ? parseInt(normalized.acceptRule)
        : null,
      brandToken: user,
    };

    if (bannerFile && bannerFile.name && img) {
      return {
        ...normalized,
        logo: {
          data: logoFile.data,
          filename: logoFile.name,
        },
        banner: {
          filename: bannerFile.name,
          data: img,
        },
      };
    }

    delete normalized.banner;
    return normalized;
  };

  const formSubmit = async () => {
    try {
      const bannerBase64 = await Base64(bannerFile);
      const formBannerBase64 = await Base64(formBannerFile);
      // eslint-disable-next-line no-mixed-operators
      if (
        (SUPPORTED_FORMATS.includes(bannerFile.type) &&
          bannerFile.size <= FILE_SIZE) ||
        formik.values.banner !== ""
      ) {
        setLoading(true);
        const normalizedValues = normalizeValues(
          !bannerBase64 ? undefined : bannerBase64
        );
        const res = await axios
          .post("colab-update-info", normalizedValues)
          .then((data) => {
            window.analytics.identify(currentUser.email, {
              website: normalizedValues.url,
              Instagram_URL: normalizedValues.socials[2].value,
              Instagram_Followers: normalizedValues.socials[3].value,
              Facebook_URL: normalizedValues.socials[0].value,
              Facebook_Followers: normalizedValues.socials[1].value,
              Tiktok_URL: normalizedValues.socials[4].value,
              Tiktok_Followers: normalizedValues.socials[5].value,
              Twitter_URL: normalizedValues.socials[6].value,
              Twitter_Followers: normalizedValues.socials[7].value,
              Youtube_URL: normalizedValues.socials[8].value,
              Youtube_Followers: normalizedValues.socials[9].value,
            });
            window.analytics.track("Profile Updated");
            return data.status;
          })
          .catch((d) => d.response.status);

          
          if (typeof bannerBase64 === "string") handleBannerChange(bannerBase64);
          if (bannerBase64 || formBannerBase64) {
            bannerBase64 && postImageasync();
            formBannerBase64 && postFormBannerSync();
          } else {
            showAlert(res);
            setLoading(false);
          }
      } else if (formik.values.banner === null) {
        setfileMessage(false);
        return false;
      } else {
        setfileMessage(false);
        return false;
      }
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    onSubmit: formSubmit,
  });

  useEffect(() => {
    getBrandInfo();
    // eslint-disable-next-line
  }, []);

  // file, base
  useEffect(() => {
    getInterests();
    // TODO - criar estado global do usuário
    getUser();
    // eslint-disable-next-line
  }, []);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0
        );
      }
    },
    100,
    [completedCrop]
  );


  return (
    <>
      <section>
        {loading && (
          <div className="container-metronic">
            <section
              className="position-fixed top-0 start-0 w-100 h-100 z-index-3  d-flex justify-content-center align-items-center"
              style={{ background: "rgba(0,0,0,0.5)" }}
            >
              <Spinner animation="grow" variant="light" />
            </section>
          </div>
        )}

        <div className={Styles.formWrapper}>
          {warning && (
            <Warning
              title="Complete seu Perfil de Marca"
              description="Configure as informações que estarão no perfil exibido aos Embaixadores."
            />
          )}

          <form onSubmit={formik.handleSubmit} noValidate>
            <h1>Perfil da Marca</h1>

            <div className="my-5 my-md-10">
              <h3>Informações básicas</h3>

              <p className="text-gray-500">
                Conte um pouco mais sobre sua Marca
              </p>
            </div>

            {/* FIELDS */}
            <div className="row mb-8">
              <div className="col-sm-4">
                <TextField
                  label="Nome da Marca"
                  placeholder="Key Design"
                  name="name"
                  autoComplete="off"
                  touched={formik.touched.name}
                  error={formik.errors.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  {...formik.getFieldProps("name")}
                />
              </div>

              <div className="col-sm-4">
                <TextField
                  label="Link da Marca"
                  placeholder="Digite a url"
                  name="url"
                  autoComplete="off"
                  touched={formik.touched.url}
                  error={formik.errors.url}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  {...formik.getFieldProps("url")}
                />
              </div>

              <div className="col-sm-4">
                <Select
                  label="Categoria da Marca"
                  name="categoryId"
                  touched={formik.touched.categoryId}
                  error={formik.errors.categoryId}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  labelOfDefault="Selecione a categoria da Marca"
                  {...formik.getFieldProps("categoryId")}
                >
                  {categories?.map((category) => (
                    <option key={category.id} value={category?.id}>
                      {category?.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="row mb-8">
              <div className="col-sm-6">
                <label className="form-label">
                  Banner Informativo
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 500 }}
                    overlay={
                      <Tooltip
                        id="tooltip-banner"
                        className={Styles.tooltipBanner}
                      >
                        É a imagem que vai aparecer pros creators no seu
                        perfil de marca na BrandLovrs.
                      </Tooltip>
                    }
                  >
                    <TooltipIcon className={Styles.tip} stroke="#000" />
                  </OverlayTrigger>
                </label>

                <div className="d-flex">
                  <input
                    className="d-none"
                    type="file"
                    accept="image/*"
                    id="formFile"
                    {...formik.getFieldProps("banner.filename")}
                    name="banner.filename"
                    onChange={(e) => handleBannerFile(e)}
                  />

                  <input
                    className="form-control form-control-lg form-control-solid disabled"
                    type="text"
                    disabled
                    id="formFile"
                    defaultValue={bannerFile.name}
                  />

                  <label
                    htmlFor="formFile"
                    style={{ marginLeft: "0.5rem", background: "#00A3FF" }}
                    className={`${Styles.button} cursor-pointer`}
                  >
                    <img
                      src={toAbsoluteUrl("/media/svg/upload.svg")}
                      alt="Upload"
                    />
                  </label>
                </div>

                <small
                  className={`mt-2 ${fileMessage ? "text-gray-500" : "text-danger"
                    }`}
                >
                  Seu banner precisa ser no formato JPG ou PNG, ter até 1 MB e
                  dimensão máxima de 1820 x 800.
                </small>
              </div>

              <div className="col-sm-6">
                <label className="form-label">
                  Logotipo da Marca
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 500 }}
                    overlay={
                      <Tooltip
                        id="tooltip-logo"
                        className={Styles.tooltipBanner}
                      >
                        É necessário que a imagem seja o logotipo da marca, com
                        fundo na cor da identidade visual.
                      </Tooltip>
                    }
                  >
                    <TooltipIcon className={Styles.tip} stroke="#000" />
                  </OverlayTrigger>
                </label>

                <div className="d-flex">
                  <input
                    className="form-control form-control-lg form-control-solid disabled"
                    type="text"
                    disabled
                    defaultValue={logoFile.name}
                  />

                  <label
                    style={{ marginLeft: "0.5rem", background: "#00A3FF" }}
                    className={`${Styles.button} cursor-pointer`}
                    onClick={() => setShow(true)}
                  >
                    <img
                      src={toAbsoluteUrl("/media/svg/upload.svg")}
                      alt="Upload"
                    />
                  </label>
                </div>

                <small className="mt-2 text-gray-500">
                  Formato PNG ou JPG e com tamanho mínimo de 390 x 390 pixels e
                  peso máximo de 500KB.
                </small>
              </div>

              <div className="col-sm-6">
                <label className="form-label">
                  Banner do Formulário
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 500 }}
                    overlay={
                      <Tooltip
                        id="tooltip-banner"
                        className={Styles.tooltipBanner}
                      >
                        É a imagem que vai aparecer pros creators no formulário da marca.
                      </Tooltip>
                    }
                  >
                    <TooltipIcon className={Styles.tip} stroke="#000" />
                  </OverlayTrigger>
                </label>

                <div className="d-flex">
                  <input
                    className="d-none"
                    type="file"
                    accept="image/*"
                    id="formBannerFile"
                    {...formik.getFieldProps("formBanner.filename")}
                    name="formBanner.filename"
                    onChange={(e) => handleFormBannerFile(e)}
                  />

                  <input
                    className="form-control form-control-lg form-control-solid disabled"
                    type="text"
                    disabled
                    id="formBannerFile"
                    defaultValue={formBannerFile.name}
                  />

                  <label
                    htmlFor="formBannerFile"
                    style={{ marginLeft: "0.5rem", background: "#00A3FF" }}
                    className={`${Styles.button} cursor-pointer`}
                  >
                    <img
                      src={toAbsoluteUrl("/media/svg/upload.svg")}
                      alt="Upload"
                    />
                  </label>
                </div>

                <small
                  className={`mt-2 ${fileMessage ? "text-gray-500" : "text-danger"
                    }`}
                >
                  Seu banner precisa ser no formato JPG ou PNG, ter até 1 MB e
                  dimensão máxima de 1820 x 800.
                </small>
              </div>
            </div>

            <div className="mb-8">
              <TextArea
                label="Sobre a Marca"
                maxLength={420}
                placeholder="Conte um pouco sobre a marca para quem é da sua comunidade, e também para pessoas interessadas em se tornarem suas embaixadoras."
                autoComplete="off"
                name="aboutMe"
                touched={formik.touched.aboutMe}
                error={formik.errors.aboutMe}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                {...formik.getFieldProps("aboutMe")}
              />
            </div>

            {/* SOCIALS */}
            <div className="mb-8">
              <div className="my-7 mb-10 mt-md-10 mb-md-20">
                <h3>Redes Sociais</h3>

                <p className="text-gray-500">
                  Informe a URL e a quantidade aproximada de seguidores em cada
                  uma das suas redes sociais.
                </p>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className={Styles.titleHeaderSocialMedia}>REDE SOCIAL</p>
                </div>

                <div className="col-8">
                  <p className={Styles.titleHeaderSocialMedia}>URL DO PERFIL</p>
                </div>

                <div className="col-2">
                  <p className={Styles.titleHeaderSocialMedia}>
                    Nº DE SEGUIDORES
                  </p>
                </div>
              </div>

              <div className="row mb-8">
                <RowSocial
                  socialName="Instagram"
                  socialIcon={<InstagramIcon />}
                  onChange={formik.handleChange}
                  placeholderFieldLink="ex: www.instagram.com/suamarca"
                  nameFieldLink={formik.getFieldProps("socials[2].value").name}
                  valueFieldLink={
                    formik.getFieldProps("socials[2].value").value
                  }
                  placeholderFieldNumber="Digite aqui"
                  nameFieldNumber={
                    formik.getFieldProps("socials[3].value").name
                  }
                  valueFieldNumber={
                    formik.getFieldProps("socials[3].value").value
                  }
                />
              </div>

              <div className="row mb-8">
                <RowSocial
                  socialName="Facebook"
                  socialIcon={<FacebookIcon />}
                  onChange={formik.handleChange}
                  placeholderFieldLink="ex: www.facebook.com/suamarca"
                  nameFieldLink="socials[0].value"
                  valueFieldLink={
                    formik.getFieldProps("socials[0].value").value
                  }
                  placeholderFieldNumber="Digite aqui"
                  nameFieldNumber="socials[1].value"
                  valueFieldNumber={
                    formik.getFieldProps("socials[1].value").value
                  }
                />
              </div>

              <div className="row mb-8">
                <RowSocial
                  socialName="Twitter"
                  socialIcon={<TwitterIcon />}
                  onChange={formik.handleChange}
                  placeholderFieldLink="ex: www.twitter.com/suamarca"
                  nameFieldLink="socials[6].value"
                  valueFieldLink={
                    formik.getFieldProps("socials[6].value").value
                  }
                  placeholderFieldNumber="Digite aqui"
                  nameFieldNumber="socials[7].value"
                  valueFieldNumber={
                    formik.getFieldProps("socials[7].value").value
                  }
                />
              </div>

              <div className="row mb-8">
                <RowSocial
                  socialName="YouTube"
                  socialIcon={<YouTubeIcon />}
                  onChange={formik.handleChange}
                  placeholderFieldLink="ex: www.youtube.com/cn/suamarca"
                  nameFieldLink="socials[8].value"
                  valueFieldLink={
                    formik.getFieldProps("socials[8].value").value
                  }
                  placeholderFieldNumber="Digite aqui"
                  nameFieldNumber="socials[9].value"
                  valueFieldNumber={
                    formik.getFieldProps("socials[9].value").value
                  }
                />
              </div>

              <div className="row mb-8">
                <RowSocial
                  socialName="TikTok"
                  socialIcon={<TikTokIcon />}
                  onChange={formik.handleChange}
                  placeholderFieldLink="ex: www.tiktok.com/@suamarca"
                  nameFieldLink="socials[4].value"
                  valueFieldLink={
                    formik.getFieldProps("socials[4].value").value
                  }
                  placeholderFieldNumber="Digite aqui"
                  nameFieldNumber="socials[5].value"
                  valueFieldNumber={
                    formik.getFieldProps("socials[5].value").value
                  }
                />
              </div>
            </div>

            {/* APPROVE */}
            <div className="mb-8">
              <div className="mt-10 mt-md-13 mb-md-7">
                <h3>
                  Critério de Aprovação de Embaixadores
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 500 }}
                    overlay={
                      <Tooltip
                        id="tooltip-banner"
                        className={Styles.tooltipBanner}
                      >
                        Apresente suas redes sociais para seus embaixadores
                        conhecerem sua presença digital. Coloque o número atual
                        aproximado de seguidores.
                      </Tooltip>
                    }
                  >
                    <TooltipIcon className={Styles.tip} stroke="#000" />
                  </OverlayTrigger>
                </h3>

                <p className="text-gray-500 mb-0">
                  Aprovação automática de todos os novos embaixadores que se
                  inscreverem.
                </p>
              </div>

              <div className="form-group">
                <div className="radio-inline">
                  <label
                    className="radio radio-lg"
                    style={{
                      width: "100%",
                      padding: "2rem 0",
                      borderBottom: "1px solid #EFF2F5",
                    }}
                  >
                    <div className={Styles.statusWrapper}>
                      <div>
                        <h4 className="mb-0">Automático</h4>

                        <p className="text-gray-500">
                          Aceita automaticamente qualquer embaixador que se
                          inscrever.
                        </p>
                      </div>

                      <input
                        type="radio"
                        className="form-check-input"
                        {...formik.getFieldProps("acceptRule")}
                        name="acceptRule"
                        value="1"
                        checked={parseInt(formik.values.acceptRule) === 1}
                      />
                    </div>
                  </label>

                  <label
                    className="radio radio-lg"
                    style={{
                      width: "100%",
                      padding: "2rem 0",
                      borderBottom: "1px solid #EFF2F5",
                    }}
                  >
                    <div className={Styles.statusWrapper}>
                      <div>
                        <h4 className="mb-0">Manual</h4>

                        <p className="text-gray-500">
                          A marca revisa e aprova manualmente novos embaixadores
                          inscritos
                        </p>
                      </div>

                      <input
                        type="radio"
                        className="form-check-input"
                        {...formik.getFieldProps("acceptRule")}
                        name="acceptRule"
                        value="2"
                        checked={parseInt(formik.values.acceptRule) === 2}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!formik.isValid}
              >
                Salvar
              </button>
            </div>
          </form>
        </div>
      </section>

      <Modal
        centered
        scrollable
        size="xl"
        show={show}
        onHide={handleCloseModal}
      >
        <ModalHeader closeButton>
          <ModalTitle className="fs-1">Adicionar Logotipo</ModalTitle>
        </ModalHeader>

        <ModalBody>
          <input type="file" accept="image/*" onChange={onSelectFile} />
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={1}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}

          {!!completedCrop && (
            <>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: 0,
                    height: 0,
                  }}
                />
              </div>
              <div>
                <button className="btn btn-primary" onClick={treatLogo}>
                  Salvar
                  {loading && (
                    <Spinner
                      animation="grow"
                      className={loading && "ms-3"}
                      variant="light"
                      size="sm"
                    />
                  )}
                </button>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
