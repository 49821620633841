import * as Yup from "yup";

// const SUPPORTED_FORMATS = [
//   "image/jpg",
//   "image/jpeg",
//   "image/png"
// ];
// const FILE_SIZE = 1660 * 660;

const formSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimo de 3 caracteres")
    .max(50, "Maximo 50 caracteres")
    .required("O nome da marca não pode ser vazio.")
    .nullable(),
  url: Yup.string()
    .min(3, "Minimo de 3 caracteres")
    .max(50, "Maximo 120 caracteres")
    .required("Preencha com uma url válida do site da sua marca.")
    .nullable(),
  categoryId: Yup.string().required("Selecione sua categoria.").nullable(),
  aboutMe: Yup.string()
    .min(3, "Minimo de 3 caracteres")
    .max(420, "Maximo 420 caracteres")
    .required("Por favor, descreva brevemente sobre sua marca.")
    .nullable(),
  acceptRule: Yup.string().required().nullable(),
  // banner: Yup.mixed()
  //   .required("Insira uma imagem de até 1660 x 660px e com até 1MB.")
  //   .test(
  //     "Insira uma imagem de até 1660 x 660px e com até 1MB.",
  //     value => value && value.size <= FILE_SIZE)
  //   .test(
  //     "Insira uma imagem em .JPG ou .PNG",
  //     value => value && SUPPORTED_FORMATS.includes(value.type)),
});

export { formSchema };

