/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import MultiSelect from "multiselect-react-dropdown";

import Token from "app/modules/hooks/Token/Token";
import CharacterCounter from "app/modules/hooks/CharacterCounter/CharacterCounter";

import { TextField } from "app/components/TextField";
//import { RichText } from "app/components/RichText";
import { MaskInput, Radio, SubTitle, Trigger } from "app/components";
import { erroAlert, successAlert } from "app/components/ModalSwal/ModalSwal";
import { UploadImage } from "app/components/Inputs/UploadImage";
// import { CreateEngagementChallengeWizzardController } from "../../components";
import { WizzardFrameData } from "../types";
import { DateTimeFormat } from "app/modules/hooks/DateFormat/DateFormat";
import ValideteError from "app/modules/hooks/ValideteError/ValideteError";
import { EditRichText } from "app/components/RichText/EditRichText";
import { useAuth } from "app/modules/auth";
import { Spinner } from "react-bootstrap";
// eslint-disable-next-line react-hooks/rules-of-hooks

const header = {
  title: "Editar Desafio",
};

type SelectedTagsData = {
  id: number;
  name: string;
};

type RichTextContract = {
  blocks: Array<{
    key: string;
    text: string;
    type: any;
    depth: number;
    inlineStyleRanges: any[];
    entityRanges: any[];
    data: object;
  }>;
  entityMap: object;
};

const richTextMock = {
  blocks: [
    {
      key: "701rr",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
};

const FILE_SIZE = 1048576;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const DescriptionFormSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "Tamanho mínimo de 3 caracteres")
    .max(56, "Tamanho máximo 56 caracteres")
    .required("Informe o título do desafio"),
  description: Yup.object().required("Informe a descrição do desafio"),
  expiredDate: Yup.string().required("Informe a validade do desafio"),
  image_cover: Yup.mixed()
    .notRequired()
    .test("Imagem deve ter no máximo 1MB", (value) => {
      if (!value) {
        return true;
      }
      return value.size <= FILE_SIZE;
    })
    .test("Formato não suportado", (value) => {
      if (!value) {
        return true;
      }
      return SUPPORTED_FORMATS.includes(value.type);
    }),
});

const Description = ({
  setHeaderData,
  formData,
  setFormData,
  actualStep,
  setNextStep,
}: WizzardFrameData) => {
  const [counterTitle, setCounterTitle] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [banner, setBanner] = useState({
    filename: "",
    image: "",
  });

  const [toggleAvailability, setToggleAvailability] = useState<number>(0);
  const [brandTags, setBrandTags] = useState<SelectedTagsData[]>([]);
  const [selectedTags, setSelectedTags] = useState([] as any);
  const [resEng, setResEng] = useState<any>({});
  const [descriptionData, setDescriptionData] =
    useState<RichTextContract>(richTextMock);

  const navigate = useNavigate();
  const token = Token();
  const { id } = useParams();
  const { logout } = useAuth();
  const bannerData =
    banner.image !== ""
      ? { filename: banner.filename, data: banner.image }
      : {};

  useEffect(() => {
    setHeaderData(header);
  }, [setHeaderData]);

  const startDate = new Date().toISOString().slice(0, 10);

  const handleSubmitDescription = async (data) => {
   
    await DescriptionFormSchema.validate(data, { abortEarly: false });

    try {
      setLoading(true);
      await DescriptionFormSchema.validate(data, { abortEarly: false });
   
 
      
      const addTagToList = selectedTags.map((d) => d.id).concat();
      const updateChallengeData = banner.image === ''?{
        token,
        challengeId: Number(id),
        title: data.title,
        description: data.description,
        challengeType: "engagement",
        ambassadorsTags: addTagToList,
        validatedAt: data.expiredDate,
      }:
      {
        token,
        challengeId: Number(id),
        title: data.title,
        description: data.description,
        challengeType: "engagement",
        ambassadorsTags: addTagToList,
        validatedAt: data.expiredDate,
        banner: { filename: bannerData?.filename, data: bannerData?.data }
      }
      const res = await axios.post("/colab/update-challenge", {
        ...updateChallengeData
      });


      setResEng(res);
      if (res.status === 204 || res.status === 201) {
        successAlert("Ebaa", "O desafio foi editado com sucesso!");
        navigate("/dashboard/challenge");
      }
    } catch (error) {
      erroAlert(
        "Opss",
        "Aconteceu um erro inesperado. Por favor, tente novamente."
      );
    } finally {
      setLoading(false);
    }
  };

  const getChallenge = async () => {
    try {
      const response = await axios.post("colab/get-challenge", {
        token: token,
        id: id,
        type: "engagement",
      });

      setResEng(response.data);

      formik.setFieldValue("title", response.data.title);

      let description: RichTextContract;
      try {
        description = JSON.parse(response.data.description);
      } catch (e) {
        description = {
          ...richTextMock,
          blocks: [
            { ...richTextMock.blocks[0], text: response.data.description },
          ],
        };
      }
      if (response.data?.ambassadorTags?.length > 0) {
        setToggleAvailability(1);
        setSelectedTags(response.data.ambassadorTags);
      }

      setDescriptionData(description);
      formik.setFieldValue("title", response.data.title);
      formik.setFieldValue(
        "expiredDate",
        response.data.expiration.substring(0, 10)
      );
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      }
    }
  };

  useEffect(() => {
    getChallenge();
  }, []);

  useEffect(() => {
    formik.setFieldValue("description", descriptionData);
  }, [descriptionData]);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      expiredDate: "",
      image_cover: "",
    },
    validationSchema: DescriptionFormSchema,
    onSubmit: handleSubmitDescription,
  });

  async function handleGetBrandTagsData() {
    const payload: any = localStorage.getItem("kt-auth-react-v");

    let parsedPayload;
    if (payload) parsedPayload = JSON.parse(payload);

    const response = await axios.post("colab/list-brand-ambassador-tags", {
      accessToken: parsedPayload.api_token,
      brandId: parsedPayload.brandId,
    });

    setBrandTags(response.data);
  }

  useEffect(() => {
    handleGetBrandTagsData();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <section>
        <SubTitle
          title="Título do desafio"
          description="Será exibido na página do desafio para qualquer embaixador dentro da BrandLovrs"
          hasPopover="Chamada principal que aparecerá no Desafio da sua marca. Insira palavras-chave que chamem atenção."
        />

        <div className="mt-3">
          <TextField
            placeholder="Ex: Divulgue o saldão de Natal da BrandLovrs"
            name="title"
            touched={formik.touched.title}
            error={formik.errors.title}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            onKeyDown={(e) => CharacterCounter(e, setCounterTitle)}
            maxLength={56}
            {...formik.getFieldProps("title")}
          />

          <p className="text-end text-gray-400 mt-3 mb-10">
            {counterTitle <= 56 ? counterTitle : 56}/56 CARACTERES
          </p>
        </div>

        <SubTitle
          title="Descrição"
          description="Será exibido na página do desafio para qualquer embaixador dentro da BrandLovrs"
          hasPopover="Oriente de forma clara sobre as regras e o que os participantes devem fazer ou falar para cumprir o desafio."
        />

        <div className="mt-3">
          <EditRichText
            rawData={descriptionData}
            placeholder="Ex: Crie um reels bem criativo apresentando a loja e convidando seus seguidores do Instagram a conhecer e aproveitar a promoção de Natal da BrandLovrs."
            name="description"
            id="description"
            {...formik.getFieldProps("description")}
            setFieldValue={(val) => formik.setFieldValue("description", val)}
            touched={formik.touched.description}
            error={formik.errors.description}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>

        <UploadImage
          name="image_cover"
          label_title="Banner do Desafio"
          label_subtitle="Defina uma imagem exclusiva para ilustrar este desafio."
          badge_title="Opcional"
          banner_title="Carregar arquivo..."
          banner_subtitle="Seu banner precisa ser no formato JPG ou PNG, ter até 1 MB e dimensão máxima de 1820 x 800."
          {...formik.getFieldProps("image_cover")}
          touched={formik.touched.image_cover}
          error={formik.errors.image_cover}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.setFieldValue("image_cover", e.target.files[0]);
          }}
          setBaseValue={setBanner}
          disabled={false}
        />

        <div className="divisor px-10 my-10" />

        <h2 className="mb-3">Público Alvo</h2>
        <p className="text-gray-600 font-roboto mb-8">
          Aqui você escolhe se seu desafio será exibido para todos ou só para
          aqueles que você marcou com as suas tags.
        </p>

        <div className="grid-radio-type mb-10">
          <Radio
            label={"Exibir para todos"}
            id={"challenge_availability_off"}
            onClick={() => {
              setToggleAvailability(0);
              setSelectedTags([]);
            }}
            {...formik.getFieldProps("challengeAvailability")}
            name="challengeAvailability"
            checked={toggleAvailability === 0}
          />

          <Radio
            label={"Segmentar por tags"}
            id={"challenge_availability_on"}
            onClick={() => setToggleAvailability(1)}
            {...formik.getFieldProps("challengeAvailability")}
            name="challengeAvailability"
            checked={toggleAvailability === 1}
          />
        </div>

        {toggleAvailability === 1 && (
          <div className="row mb-5">
            <div className="col-md-6">
              <label className="mb-3  form-label">Tags escolhidas</label>

              <div className="input-group MultiSelect mb-3">
                <MultiSelect
                  {...formik.getFieldProps("selectedTags")}
                  options={brandTags}
                  displayValue="name"
                  isObject
                  selectedValues={selectedTags}
                  placeholder="Selecione"
                  hidePlaceholder
                  emptyRecordMsg="Nenhuma opção disponível"
                  showArrow
                  onSelect={setSelectedTags}
                  onRemove={setSelectedTags}
                  style={{
                    chips: {
                      backgroundColor: "#C9E8FF",
                      color: "#009EF6",
                    },
                    searchBox: {
                      border: "1px solid #e4e6ef",
                      backgroundColor: "#F3F6F9",
                      borderRadius: "0.475rem",
                      boxShadow: "inset 0 1px 2px rgb(0 0 0 / 8%)",
                      transition:
                        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                      position: "relative",
                      padding: "1rem 3rem 1rem 1rem",
                    },
                    inputField: {
                      MozPaddingStart: "calc(1rem - 3px)",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      lineHeight: "1.5",
                      color: "#5e6278",
                      appearance: "none",
                    },
                  }}
                />

                <span className="text-black-50 d-block py-2">
                  O desafio só vai aparecer para embaixadores categorizados com
                  alguma das tags selecionadas.
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="divisor px-10 my-10" />

        <h2>
          Validade do desafio
          <Trigger description="Caso todas as recompensas já tenham sido liberadas, o desafio será encerrado automaticamente." />
        </h2>
        <div className="mw-175px mt-7 mb-7">
          <MaskInput
            type="date"
            placeholder="Ex: Divulgue o saldão de Natal da BrandLovrs"
            name="expiredDate"
            touched={formik.touched.expiredDate}
            error={formik.errors.expiredDate}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            {...formik.getFieldProps("expiredDate")}
          />
        </div>
      </section>

      <div className="divisor px-10 my-10" />
      <div className="d-flex justify-content-between">
        <Link to="/dashboard/challenge">
          <span className="btn btn-secondary">Voltar</span>
        </Link>
        {resEng.status === 204 ? (
          <button className="btn btn-success" type="button">
            <span>Alterações realizadas com sucesso</span>
          </button>
        ) : (
          <button
            className="btn btn-primary text-white"
            type="submit"
            disabled={!(formik.isValid && formik.dirty)}
          >
            <span className={`${loading && "pe-5"}`}>
              {loading ? "Salvando Alterações" : "Salvar Alterações"}
            </span>
            {loading && <Spinner animation="grow" variant="light" size="sm" />}
          </button>
        )}
      </div>
    </form>
  );
};

export { Description };
