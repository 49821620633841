import { useState } from "react";
import { Alert, OverlayTrigger, Popover, Modal, ModalHeader, ModalTitle, ModalBody } from "react-bootstrap";

import { TagField } from "app/components/Inputs/TagInput";

type TagListData = {
  tags: TagData[];
  ambassadorId: number;
  canCreateTag: boolean;
  handleGetAmbassadorTagList: Function;
  isLoadingButton: any;
  setIsLoadingButton: Function;
  setRefresh: Function;
  listChartRequest: Function;
}

type TagData = {
  id: number;
  name: string;
}

export const TagsList = ({listChartRequest,setRefresh, tags, ambassadorId, canCreateTag, handleGetAmbassadorTagList, isLoadingButton, setIsLoadingButton }: TagListData) => {
  const [addTagModal, setAddTagModal] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        {tags?.filter((_, index) => index >= 2).map((tag, index) => (
          <div key={`${tag.name}-${index}`}>
            <span className={`badge badge-tag text-black me-2 mb-1 h-auto`} key={`${tag.name}-${index}`}>{tag.name}</span>
          </div>
        ))}
      </Popover.Body>
    </Popover>
  );

  const handleCloseModal = () => setAddTagModal(false);

  return (
    <>
      <div className='d-flex justify-content-center align-items-center min-h-45px'>
        <div className='flex-wrap d-flex justify-content-center align-items-center'>
          {tags?.length === 0
            ?
              <span className="badge badge-tag text-black me-2 mb-1 h-auto">-</span>
            :
              <>
                {tags?.filter((_, index) => index < 2).map((tag, index) => (
                  <span className="badge badge-tag text-black me-2 mb-1 h-auto" key={`${tag?.id}-${index}`}>{tag?.name}</span>
                ))}

                {
                  tags?.length > 2
                  &&
                  <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={popover}>
                    <span className="badge badge-tag text-black me-2 mb-1 h-auto cursor-pointer">...</span>
                  </OverlayTrigger>
                }
              </>
          }
          {canCreateTag && (
            <span
              className="badge badge-tag text-black me-2 mb-1 h-auto cursor-pointer"
              onClick={() => setAddTagModal(!addTagModal)}
            >
              Adicionar tag +
            </span>
          )}
        </div>
      </div>

      <Modal
        centered
        show={addTagModal}
        onHide={handleCloseModal}
      >
        <ModalHeader closeButton>
          <ModalTitle className="fs-1">Adicionar tag</ModalTitle>
        </ModalHeader>

        <ModalBody>
          <span className="mb-5">Crie uma nova tag usando a caixa de texto e pressionando o "enter", ou escolha uma tag já existente abaixo.</span>

          <TagField listChartRequest={listChartRequest} setRefresh={setRefresh} setAddTagModal={setAddTagModal} tags={tags} ambassadorId={ambassadorId} handleGetAmbassadorTagList={handleGetAmbassadorTagList} isLoadingButton={isLoadingButton} setIsLoadingButton={setIsLoadingButton} />

          {showAlert &&
            <Alert variant='primary' className="d-flex justify-content-between align-items-center" onClose={() => setShowAlert(false)} dismissible>
              <div>
                <span className={`svg-icon svg-icon-primary svg-icon-2hx pe-sm-5`}>
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M22.3047 12.5C22.3047 6.97715 17.8275 2.5 12.3047 2.5C6.78184 2.5 2.30469 6.97715 2.30469 12.5C2.30469 18.0228 6.78184 22.5 12.3047 22.5C17.8275 22.5 22.3047 18.0228 22.3047 12.5Z" fill="#009EF6"/>
                    <path d="M11.3047 11.5V16.5C11.3047 17.0523 11.7524 17.5 12.3047 17.5C12.857 17.5 13.3047 17.0523 13.3047 16.5V11.5C13.3047 10.9477 12.857 10.5 12.3047 10.5C11.7524 10.5 11.3047 10.9477 11.3047 11.5Z" fill="#009EF6"/>
                    <path d="M12.3047 7.5C11.7524 7.5 11.3047 7.94772 11.3047 8.5C11.3047 9.05228 11.7524 9.5 12.3047 9.5C12.857 9.5 13.3047 9.05228 13.3047 8.5C13.3047 7.94772 12.857 7.5 12.3047 7.5Z" fill="#009EF6"/>
                  </svg>
                </span>
              </div>

              <div>
                Crie suas próprias tags para categorizar seus embaixadores e personalizar sua interação com sua comunidade.
              </div>
            </Alert>
          }
        </ModalBody>
      </Modal>
    </>
  )
}