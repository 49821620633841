import React, { useEffect, useState } from "react";
import metabase from "app/modules/services/metabase";

interface AmbassadorDashboardData {
  brandId: number;
  ambassadorId: number;
}

export function AmbassadorDashboard({ brandId, ambassadorId }: AmbassadorDashboardData) {
  const [iframe, setIframe] = useState<any>({
    header: "",
    urlEmbed: ""
  })
  const hash_token = `4eb2771ef0847618afc44497bf9b6aea0d1d88c4`;

  const GetAmbassadorDashboard = async () => {
    try {
      const response = await metabase.post('/dashboard/get-dashboard', {
        hash: hash_token,
        brand_id: [brandId],
        user_id: [ambassadorId]
      });

      setIframe(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (brandId) {
      GetAmbassadorDashboard();
    }
    // eslint-disable-next-line
  }, [brandId])


  return (
    <>
      {/* <h1 className="mb-10">Painel Geral - Resumo das atividades</h1> */}
      <iframe
        title="dashboard"
        src={iframe.urlEmbed}
        frameBorder="0"
        width="800"
        height="650"
        style={{ width: "100%" }}
        className="mb-5 mt-5"
      />
      {/* <ChartRequests /> */}
    </>
  );
};
