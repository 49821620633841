import { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  CalcInput,
  Radio,
  Select,
  SubTitle,
  TextField,
  Trigger,
} from "app/components";
import { CreateEngagementChallengeWizzardController } from "../../../components";

import { WizzardFrameData } from "../../types";
import Money from "app/modules/hooks/Money/Money";
import { RewardType } from "./types";
import { useParams } from "react-router-dom";

const header = {
  title: "Recompensas",
  subtitle: "Defina os parâmetros da recompensa do seu desafio.",
};
const RewardFormSchema = Yup.object().shape({
  value: Yup.number()
    .max(999.99, "O valor maximo é R$ 999,00"),
  maxParticipants: Yup.string().required(
    "Informe a quantidade máxima de participantes"
  ),
  maxRewardsPerUser: Yup.string()
    .required("Informe a quantidade máxima de recompensas por participante")
    .max(3, "A quantidade maxima é 3")
    .min(1, "A qualidade minima é 1"),
});

const Reward = ({
  setHeaderData,
  formData,
  setFormData,
  actualStep,
  setNextStep,
}: WizzardFrameData) => {
  const [toggleReward, setToggleReward] = useState<number>(1);
  const router = useParams();
  const params = router.id ? true : false;

  useEffect(() => {
    setHeaderData(header);
  }, [setHeaderData]);

  const initialValues: RewardType = {
    maxParticipants: "",
    value: "",
    maxRewardsPerUser: "",
    rewardDescription: "",
    rewardType: "1",
    grossValue: "0"
  };
  const formik = useFormik({
    initialValues,
    validationSchema: RewardFormSchema,
    onSubmit: async (reward: RewardType) => {
      // await RewardFormSchema.validate(reward);
      setFormData({
        ...formData,
        reward: {
          maxParticipants: Number(reward.maxParticipants),
          value: Number(reward.value),
          maxRewardsPerUser: Number(reward.maxRewardsPerUser),
          rewardType: Number(reward.rewardType),
          rewardDescription: reward.rewardDescription,
          grossValue: Number(reward.grossValue)
        },
      });
      setNextStep(actualStep + 1);
    },
  });

  const Budget = useCallback(
    (value) =>
      Number(value) *
      Number(formik.values.value) *
      Number(formik.values.maxRewardsPerUser),
    [formik.values.value, formik.values.maxRewardsPerUser]
  );

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <SubTitle
        title={"Valor da recompensa"}
        description={
          "Escolha o valor da recompensa que será paga a quem cumprir o desafio."
        }
      />

      <div className="grid-radio mt-10 mb-5">
        <Radio
          label={"Valor fixo"}
          id={"l3"}
          disabled={params && true}
          onClick={() => setToggleReward(1)}
          {...formik.getFieldProps("rewardType")}
          name="rewardType"
          value="1"
          checked={toggleReward === 1}
        />

        <Radio
          label={"Sem recompensa"}
          id={"l1"}
          disabled={params && true}
          onClick={() => {
            setToggleReward(2);
            formik.setFieldValue("value", 0);
            formik.setFieldValue("maxRewardsPerUser", 1);
          }}
          {...formik.getFieldProps("rewardType")}
          name="rewardType"
          value="2"
          checked={toggleReward === 2}
        />
      </div>

      {toggleReward === 1 && (
        <div className="pt-8 pb-5">
          <CalcInput
            mask="currency"
            name="value"
            result={formik.values.value}
            touched={formik.touched.value}
            error={!formik.errors.value}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            {...formik.getFieldProps("value")}
            description_value="Valor que a marca  pagará para cada desafio cumprido."
            description_rate="Ao final de cada mês, recolheremos uma taxa de 20% sobre o valor das recompensas pagas. Isso ajuda a cobrir custos envolvidos em cada operação."
          />
        </div>
      )}

      {toggleReward === 2 && (
        <div className="pt-8 pb-5">
          <article>
            <label className="mb-3 form-label">
              Descreva a recompensa
              <Trigger description="Descreva a recompensa que deseja oferecer" />
              <span className="badge badge-blue-primary">Opcional</span>
            </label>
            <div className="input-group mb-3">
              <TextField
                placeholder="Descreva a recompensa"
                maxLength={12}
                touched={formik.touched.rewardDescription}
                error={formik.errors.rewardDescription}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                {...formik.getFieldProps("rewardDescription")}
              />
            </div>
            {formik.touched.rewardDescription &&
              formik.errors.rewardDescription && (
                <span className="text-danger fw-bold d-block py-2">
                  {formik.errors.rewardDescription}
                </span>
              )}
          </article>
        </div>
      )}

      <SubTitle
        title="Quantidade"
        description="Escolha o número máximo participantes neste desafio."
        hasPopover="As recompensas serão pagas manualmente ao final do desafio. Um mesmo conteúdo  pode bater a meta várias vezes, e você poderá dar até 3 recompensas pelo conteúdo enviado."
      />

      <div className="mw-100px mt-3 mb-10">
        <TextField
          placeholder="Ex: 50"
          name="maxParticipants"
          touched={formik.touched.maxParticipants}
          error={formik.errors.maxParticipants}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          onInput={(e) =>
            ((e.target as HTMLInputElement).value = (
              e.target as HTMLInputElement
            ).value.replace(/[^0-9;]/, ""))
          }
          {...formik.getFieldProps("maxParticipants")}
        />
      </div>

      <SubTitle
        title="Recompensas por participante"
        description="Esse é o número máximo de recompensas que você poderá distribuir pra quem bater as metas mais vezes."
        hasPopover="O sistema permite que você escolha dar até 3 recompensas por participante pelo desempenho dele no desafio.  Por enquanto, não é possível editar esse campo."
      />

      <div className="mw-100px mt-3 mb-10">
        <Select
          label=""
          disabled={toggleReward === 2}
          name="maxRewardsPerUser"
          touched={formik.touched.maxRewardsPerUser}
          error={formik.errors.maxRewardsPerUser}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          labelOfDefault=""
          {...formik.getFieldProps("maxRewardsPerUser")}
        >
          <option value="1" selected>
            1
          </option>
          <option value="2">2</option>
          <option value="3">3</option>
        </Select>
      </div>

      <h2 className="mt-10 mb-4 d-d-inline-block">
        Orçamento total
        <Trigger description="Quanto ficará reservado na sua conta para este desafio e será usado para pagar quem bateu a meta. Calculado a partir  do valor da recompensa e da quantidade de participantes." />
        <span className="text-primary ms-2">
          {Money(Budget(formik.values.maxParticipants))}
        </span>
      </h2>
      <p className="font-14 font-roboto mb-0 text-gray-600">
        Calculado a partir do valor da recompensa, da quantidade de
        participantes e de recompensas por participante.
      </p>
      <div className="divisor px-10 my-10" />

      <CreateEngagementChallengeWizzardController
        actualStep={actualStep}
        setNextStep={setNextStep}
      />
    </form>
  );
};

export { Reward };

