// import { ReactNode, useState } from 'react'
import { Modal } from 'react-bootstrap'

interface props {
  children: any,
  show: boolean,
  setOpen: Function
}

const Popup = ({ children, show, setOpen }: props) => {
  const handleClose = () => setOpen(false);
  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      {children}
    </Modal>
  )
}

export default Popup
