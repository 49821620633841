import { OverlayTrigger, Popover } from 'react-bootstrap';
import { BsInstagram } from 'react-icons/bs';

interface ReviewData {
  link: string;
}

export function Review({ link }: ReviewData) {
  const renderTooltip = (props) => (
    <Popover id="progressbar-tooltip" {...props}>
      <div className='p-4 d-block fw-normal'>
        <div>Verifique o conteúdo enviado dentro do próprio Instagram</div>
      </div>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger='hover'
      placement='top'
      overlay={renderTooltip}
    >
      <a href={link} target={"_blank"} rel="noreferrer">
        <BsInstagram width={14} color="#5D5FEF" />

        <span className='text-review'>Revisar</span>
      </a>
    </OverlayTrigger>
  );
}
