import React from "react";
import { Trigger } from "../Trigger";

import { ISubTitleProps } from "./types";

const SubTitle: React.FC<ISubTitleProps> = ({
  title,
  description,
  hasPopover,
  isOptional = false
}): JSX.Element => {
  return (
    <div>
      <h2>
        { title }
        { hasPopover && <Trigger description={hasPopover} /> }
        { isOptional && <span className="badge ms-2 bg-ligth-blue text-blue">Opcional</span> }
      </h2>
      
      <p className="font-14 font-roboto mb-0 text-gray-600">{description}</p>
    </div>
  );
};

export { SubTitle };