import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

interface props {
  type: 'Logo' | 'Bloco' | 'Register'
  children: ReactNode
}

const Pages = ({ children, type }: props) => {
  const Nav = useNavigate()
  switch (type) {
    case 'Bloco':
      return (
        <div style={{ background: `url(${toAbsoluteUrl('/media/bg-colab.png')}) no-repeat fixed`, backgroundSize: "cover" }}>
          <div className='d-block justify-lg-between d-lg-flex' style={{ height: '100vh' }}>
            <div className='d-none d-lg-flex illustration-login m-auto mb-20 mt-20' style={{ width: '45%' }}>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-white.svg')} className="logo-adapt cursor-pointer" onClick={() => Nav('/auth/')} />
              <img src={toAbsoluteUrl('/media/imgs/ilustra_home.png')} alt="ilustração" className='mt-10' />
            </div>

            <div className='bg-white d-flex flex-column justify-content-center auth-container content-login h-100'>
              {children}
            </div>
          </div>
        </div>)
    case 'Logo':
      return (
        <div style={{ background: `url(${toAbsoluteUrl('/media/bg-colab.png')}) no-repeat fixed`, backgroundSize: "cover" }}>
          <div className='d-block justify-lg-between d-lg-flex' style={{ minHeight: '100vh' }}>
            <div className='d-lg-flex d-none align-items-lg-center justify-content-lg-center m-auto flex-column' style={{ width: '45%', }}>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-white.svg')} className="cursor-pointer" onClick={() => Nav('/auth/')} />
              <img src={toAbsoluteUrl('/media/imgs/ilustra_home.png')} alt="ilustração" className='mt-10' />
            </div>
            <div className='bg-white d-flex flex-column justify-content-center cadastroMinHeight content-login' style={{ padding: '90px', }} >
              {children}
            </div>
          </div>
        </div>
      )
    case 'Register':
      return (
        <div style={{ background: `url(${toAbsoluteUrl('/media/bg-colab.png')}) no-repeat fixed`, backgroundSize: "cover" }}>
          <div className='d-block justify-lg-between d-lg-flex'>
            <div className='d-lg-flex d-none align-items-lg-center justify-content-lg-center m-auto flex-column' style={{ width: '45%', }}>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-white.svg')} className="cursor-pointer" onClick={() => Nav('/auth/')} />
              <img src={toAbsoluteUrl('/media/imgs/ilustra_home.png')} alt="ilustração" className='mt-10' />
            </div>
            <div className='bg-white d-flex flex-column justify-content-center cadastroMinHeight register-wrapper content-login'>
              {children}
            </div>
          </div>
        </div>
      )

  }
}

export default Pages