import { Button } from 'react-bootstrap'
import { default as _ReactPlayer } from 'react-player/wistia';
import { ReactPlayerProps } from "react-player/types/lib";

const FinancialMoney = () => {
    const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;
    const intercom = typeof window.Intercom === 'function'

    const handleClick = () => window.Intercom && window.Intercom?.('show')

    return (
        <section className='container-metronic px-5 px-md-0 px-xxl-0 position-relative bg-white h-100'>
            <h2 className='px-5 py-md-10 px-md-15 bg-gray-100'>Financeiro</h2>
            <article className='px-5 px-md-15'>
                <div className="row">
                    <div className="col-md-6">
                        <h1 className='text-info fs-1 pt-20 pb-5 settings-h1'>Adicione saldo para criar o seu primeiro desafio</h1>
                        <p>Agora que já conectou seu e-commerce, você precisa adicionar dinheiro à sua conta para poder reservar o saldo necessário para criar seu primeiro desafio.</p>
                        <p>Não se preocupe, o saldo que não for pago em comissões para os embaixadores voltará para sua carteira e poderá ser reutilizado em outros desafios.</p>
                        <p>Pra adicionar saldo, por favor contate o nosso time de Atendimento.</p>
                        <div className='my-10'>
                            {intercom ?
                                <Button variant='primary' onClick={handleClick}>
                                    <span className="svg-icon svg-icon-white svg-icon-1hx">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor" />
                                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
                                        </svg>
                                    </span>
                                    Contatar Atendimento
                                </Button> :
                                <a href='mailto:suporte@brandlovrs.com'>
                                    <Button variant='primary'>
                                        <span className="svg-icon svg-icon-white svg-icon-1hx">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor" />
                                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
                                            </svg>
                                        </span>
                                        Contatar Atendimento
                                    </Button>
                                </a>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="pt-20 pb-5 video">
                            <ReactPlayer url='https://adventures-inc.wistia.com/medias/32rtbhemwn' controls={true} width='100%' height='350px' />
                        </div>
                    </div>
                </div>
            </article>
        </section>
    )
}

export default FinancialMoney