import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Legate = () => {
    const code = useRef<HTMLDivElement | any>(null)
    const [copy, setCopy] = useState(false)

    useEffect(() => {
        const tag = code.current.querySelectorAll('.btn-copy')
        tag.forEach((e) => {
            e.children[1].addEventListener('click', () => {
                const code = e.children[0].innerText
                window.navigator.clipboard.writeText(code)
                setCopy(true)
            })
            setCopy(false)
            e.children[0].addEventListener('mouseout', () => setCopy(false))
        });
    }, [])

    return (
        <section className='container-metronic px-5 px-md-0 px-xxl-0 position-relative bg-white h-100'>
            <h2 className='px-5 py-10 py-md-10 px-md-15 bg-gray-100'>Configurações</h2>
            <article className='px-5 px-md-15'>
                <div className="row">
                    <div className="col-md-6">
                        <h1 className='text-info fs-1 pt-20 pb-5 settings-h1 '>Configure o formulário de cadastro no seu site</h1>
                        <p>Chame clientes que são fãs da sua marca para fazer parte da sua nova comunidade de embaixadores!</p>
                        <p>Siga os passos a seguir para inserir seu formulário de cadastro de novos embaixadores dentro do seu site. Assim, quem quiser poderá se cadastrar e entrar para a BrandLovrs.</p>
                        <p>Você pode usar seu formulário em uma landing page, em um banner ou em uma página específica dentro do seu e-commerce.</p>
                        <p>Faça uma chamada criativa e convide todo mundo pra entrar e participar dos seus desafios!</p>
                        <div className="alert bg-light-primary border-primary-left-20 mt-15 ps-7">
                            <h3 className='text-primary'>Atenção</h3>
                            <p className='mb-0'>Se você não tiver conhecimento técnico em HTML para fazer essa configuração, sugerimos que busque alguém especialista. </p>
                        </div>
                    </div>
                    <div className="col-md-6 link" ref={code}>
                        <p className='pt-20 pb-5 mb-7'><strong>Passo 1:</strong> Crie um link ou botão e aponte para o endereço de cadastro da BrandLovrs. Segue uma sugestão de código abaixo, mas lembre-se de trocar “Nome do link” pelo texto que você quer que apareça no site.</p>
                        <div className="bg-gray-200 position-relative p-5 rounded-3 btn-copy">
                            <code className='text-dark shadow-none pb-2 d-block' >{`<a href="https://webapp.brandlovrs.com/auth/register/user">Nome do link</a>`}</code>
                            <Button variant={copy ? 'dark' : 'secondary'} className='py-2 px-3 position-absolute' style={{ fontSize: '10px', bottom: '10px', right: '10px' }}>
                                {copy ? 'copiado!' : 'copiar'}
                            </Button>
                        </div>
                        <p className='py-11'> <strong>Passo 2:</strong> Antes de fechar a tag <strong>{'</BODY>'}</strong> da página onde ficará o seu formulário, copie e cole o código abaixo:</p>
                        <div className="bg-gray-200 position-relative p-5 rounded-3 btn-copy">
                            <code className='text-dark shadow-none pb-2 d-block' >{`<script type="text/javascript" src="https://app.brandlovrs.com/static/js/brandlovrs-embed.js"></script>`}</code>
                            <Button variant={copy ? 'dark' : 'secondary'} className='py-2 px-3 position-absolute' style={{ fontSize: '10px', bottom: '10px', right: '10px' }}>
                                {copy ? 'copiado!' : 'copiar'}
                            </Button>
                        </div>
                        <div className="mt-10">
                            {/* <Button variant='primary' className='me-6'>
                                <span className="svg-icon svg-icon-primary svg-icon-1hx">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M18.4 5.59998C18.7766 5.9772 18.9881 6.48846 18.9881 7.02148C18.9881 7.55451 18.7766 8.06577 18.4 8.44299L14.843 12C14.466 12.377 13.9547 12.5887 13.4215 12.5887C12.8883 12.5887 12.377 12.377 12 12C11.623 11.623 11.4112 11.1117 11.4112 10.5785C11.4112 10.0453 11.623 9.53399 12 9.15698L15.553 5.604C15.9302 5.22741 16.4415 5.01587 16.9745 5.01587C17.5075 5.01587 18.0188 5.22741 18.396 5.604L18.4 5.59998ZM20.528 3.47205C20.0614 3.00535 19.5074 2.63503 18.8977 2.38245C18.288 2.12987 17.6344 1.99988 16.9745 1.99988C16.3145 1.99988 15.661 2.12987 15.0513 2.38245C14.4416 2.63503 13.8876 3.00535 13.421 3.47205L9.86801 7.02502C9.40136 7.49168 9.03118 8.04568 8.77863 8.6554C8.52608 9.26511 8.39609 9.91855 8.39609 10.5785C8.39609 11.2384 8.52608 11.8919 8.77863 12.5016C9.03118 13.1113 9.40136 13.6653 9.86801 14.132C10.3347 14.5986 10.8886 14.9688 11.4984 15.2213C12.1081 15.4739 12.7616 15.6039 13.4215 15.6039C14.0815 15.6039 14.7349 15.4739 15.3446 15.2213C15.9543 14.9688 16.5084 14.5986 16.975 14.132L20.528 10.579C20.9947 10.1124 21.3649 9.55844 21.6175 8.94873C21.8701 8.33902 22.0001 7.68547 22.0001 7.02551C22.0001 6.36555 21.8701 5.71201 21.6175 5.10229C21.3649 4.49258 20.9947 3.93867 20.528 3.47205Z" fill="currentColor" />
                                        <path d="M14.132 9.86804C13.6421 9.37931 13.0561 8.99749 12.411 8.74695L12 9.15698C11.6234 9.53421 11.4119 10.0455 11.4119 10.5785C11.4119 11.1115 11.6234 11.6228 12 12C12.3766 12.3772 12.5881 12.8885 12.5881 13.4215C12.5881 13.9545 12.3766 14.4658 12 14.843L8.44699 18.396C8.06999 18.773 7.55868 18.9849 7.02551 18.9849C6.49235 18.9849 5.98101 18.773 5.604 18.396C5.227 18.019 5.0152 17.5077 5.0152 16.9745C5.0152 16.4413 5.227 15.93 5.604 15.553L8.74701 12.411C8.28705 11.233 8.28705 9.92498 8.74701 8.74695C8.10159 8.99737 7.5152 9.37919 7.02499 9.86804L3.47198 13.421C2.52954 14.3635 2.00009 15.6417 2.00009 16.9745C2.00009 18.3073 2.52957 19.5855 3.47202 20.528C4.41446 21.4704 5.69269 21.9999 7.02551 21.9999C8.35833 21.9999 9.63656 21.4704 10.579 20.528L14.132 16.975C14.5987 16.5084 14.9689 15.9544 15.2215 15.3447C15.4741 14.735 15.6041 14.0815 15.6041 13.4215C15.6041 12.7615 15.4741 12.108 15.2215 11.4983C14.9689 10.8886 14.5987 10.3347 14.132 9.86804Z" fill="currentColor" />
                                    </svg>
                                </span>
                                Checar configuração
                            </Button> */}
                            <Link to={'/'}>
                                {/* link-primary text-decoration-underline */}
                                <span className="btn btn-primary">Pular esta etapa</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    )
}

export default Legate
