import { useFormik } from "formik";

import { Select } from "app/components";
import { RegisterWizzardController } from "../../components/Controller";
import MultiSelect from "multiselect-react-dropdown";

import { WizzardFrameData } from "../types";
import { AditionalInfoType } from "./types";
import schema from "./schema";
import { useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";


const AditionalFormSchema = schema;

const AditionalInfo = ({
  formData,
  setFormData,
  actualPage,
  setNextPage,
  actualField,
  setNextField,
}: WizzardFrameData) => {
  const numberOfEmployees = [
    { id: 1, label: "Sou só eu mesmo" },
    { id: 2, label: "Entre 2 a 5 colaboradores" },
    { id: 3, label: "Entre 6 a 10 colaboradores" },
    { id: 4, label: "Entre 11 a 50 colaboradores" },
    { id: 5, label: "Entre 51 a 100 colaboradores" },
    { id: 6, label: "Entre 101 a 300 colaboradores" },
    { id: 7, label: "Acima de 301 colaboradores" },
    { id: 8, label: "Não sei informar" },
  ];

  const averageMonthlyRevenue = [
    { id: 1, label: "Até R$ 9.999/mês" },
    { id: 2, label: "Entre R$ 10.000 e R$ 49.999/mês" },
    { id: 3, label: "Entre R$ 50.000 e R$ 89.999/mês" },
    { id: 4, label: "Entre R$ 90.000 e R$ 149.999/mês" },
    { id: 5, label: "Entre R$ 150.000 e R$ 300.000/mês" },
    { id: 6, label: "Acima de R$ 300.000/mês" },
    { id: undefined, label: "Não sei informar" },
  ];

  const brandGoals = [
    { id: 1, label: "Tornar minha marca mais conhecida" },
    { id: 2, label: "Começar minha estratégia com influenciadores/ embaixadores", },
    { id: 3, label: "Aproveitar a base de embaixadores que vocês já têm" },
    { id: 4, label: "Reduzir custos de mídia paga" },
    { id: 5, label: "Construir minha comunidade de clientes apaixonados" },
    { id: 6, label: "Gerenciar minha comunidade já existente" },
    { id: 7, label: "Vender mais usando os desafios" },
    { id: 8, label: "Outro" },
  ];

  const [intro, setIntro] = useState(true);
  const [errorRequest, setErrorRequest] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedBrandGoals, setSelectedBrandGoals] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentValidationSchema = AditionalFormSchema[actualField];
  const initialValues: AditionalInfoType = {
    numberOfEmployees: "",
    averageMonthlyRevenue: "",
    brandGoals: [],
  };
  const formik = useFormik({
    initialValues,
    validationSchema: currentValidationSchema,
    onSubmit: async (registry: AditionalInfoType) => {
      const token = localStorage.getItem("accessToken");
      const decodedToken: any = jwt_decode(token);
      if (actualField === 2) {
        setFormData({
          ...formData,
          ...registry,
          brandGoals: selectedBrandGoals,
          brandId: decodedToken?.permissions[0]?.brandId ? decodedToken?.permissions[0]?.brandId : decodedToken?.permissions?.permissions[0]?.brandId,
        });
        try {
          setLoading(true);
          const res = await axios.post("/colab/brands/add-info", {
            averageMonthlyRevenue: Number(registry.averageMonthlyRevenue),
            numberOfEmployees: Number(registry.numberOfEmployees),
            brandGoals: selectedBrandGoals,
            brandId: decodedToken?.permissions[0]?.brandId ? decodedToken?.permissions[0]?.brandId : decodedToken?.permissions?.permissions[0]?.brandId,
          });
          if (res.status === 200) {
            await axios.post("/brand/save-register-status", {
              accessToken: token,
              code: 2002,
            });
            setNextPage(actualPage + 1);
          }
        } catch (e) {
          setErrorRequest(true);
          setErrorMessage(
            "Ocorreu um erro inesperado. Por favor tente novamente mais tarde"
          );
          console.log(e);
          return;
        } finally {
          setLoading(false);
        }
      }
      formik.setTouched({});
      setNextField(actualField + 1);
    },
  });

  function handleAddBrandGoal(selectedItem) {
    const addTagToList = selectedItem.map((d) => d.id).concat();

    setSelectedBrandGoals(addTagToList);
    formik.setFieldValue("brandGoals", addTagToList);
  }

  function handleRemoveBrandGoal(selectedList, removedItem) {
    const filterTagRemove = selectedBrandGoals.filter(function (item) {
      return item !== removedItem.id;
    });

    setSelectedBrandGoals(filterTagRemove);
    formik.setFieldValue("brandGoals", filterTagRemove);
  }

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        className="h-100vh d-flex flex-column justify-content-center"
      >
        <>
          {intro ? (
            <>
              <h1 className="mb-5">
                Certo!
                <br />
                <br />
                Agora, só falta nos contar um pouco sobre o seu negócio
              </h1>
              <div className="mb-10">
                É rapidinho e nos ajuda a adequar a plataforma para as suas necessidades
              </div>
              <button className="btn btn-info w-150px" onClick={() => setIntro(false)}>
                Continuar
              </button>
            </>
          ) : (
            <>
              {actualField === 0 && (
                <>
                  <h2>Quantas pessoas trabalham aí?</h2>
                  <p className="font-14 font-roboto mb-0 text-gray-600">
                    Escolha a faixa que mais se aproxima da quantia de
                    colaboradores da empresa.
                  </p>
                  <Select
                    label=""
                    name="numberOfEmployees"
                    touched={formik.touched.numberOfEmployees}
                    error={formik.errors.numberOfEmployees}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    labelOfDefault=""
                    autoFocus
                    {...formik.getFieldProps("numberOfEmployees")}
                  >
                    {numberOfEmployees?.map((numberOfEmployees) => (
                      <option
                        key={numberOfEmployees.id}
                        value={numberOfEmployees?.id}
                      >
                        {numberOfEmployees?.label}
                      </option>
                    ))}
                  </Select>
                </>
              )}

              {actualField === 1 && (
                <>
                  <h2>E qual o faturamento médio mensal da marca?</h2>
                  <p className="font-14 font-roboto mb-0 text-gray-600">
                    Escolha a faixa que mais se aproxima.
                  </p>
                  <Select
                    label=""
                    name="category"
                    touched={formik.touched.averageMonthlyRevenue}
                    error={formik.errors.averageMonthlyRevenue}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    labelOfDefault=""
                    autoFocus
                    {...formik.getFieldProps("averageMonthlyRevenue")}
                  >
                    {averageMonthlyRevenue?.map((averageMonthlyRevenue) => (
                      <option
                        key={averageMonthlyRevenue.id}
                        value={averageMonthlyRevenue?.id}
                      >
                        {averageMonthlyRevenue?.label}
                      </option>
                    ))}
                  </Select>
                </>
              )}

              {actualField === 2 && (
                <>
                  <h2>Quais seus principais objetivos na BrandLovrs?</h2>
                  <p className="font-14 font-roboto mb-0 text-gray-600">
                    Escolha o resultado que você espera ao utilizar a
                    BrandLovrs.
                  </p>

                  <div>
                    <MultiSelect
                      {...formik.getFieldProps("selectedTags")}
                      options={brandGoals}
                      displayValue="label"
                      isObject
                      placeholder="Selecione"
                      hidePlaceholder
                      emptyRecordMsg="Nenhuma opção disponível"
                      showArrow
                      onSelect={handleAddBrandGoal}
                      onRemove={handleRemoveBrandGoal}
                      style={{
                        chips: {
                          backgroundColor: "#C9E8FF",
                          color: "#009EF6",
                        },
                        searchBox: {
                          border: "1px solid #e4e6ef",
                          backgroundColor: "#F3F6F9",
                          borderRadius: "0.475rem",
                          boxShadow: "inset 0 1px 2px rgb(0 0 0 / 8%)",
                          transition:
                            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                          position: "relative",
                          padding: "1rem 3rem 1rem 1rem",
                        },
                        inputField: {
                          MozPaddingStart: "calc(1rem - 3px)",
                          fontSize: "1.1rem",
                          fontWeight: "500",
                          lineHeight: "1.5",
                          color: "#5e6278",
                          appearance: "none",
                        },
                      }}
                    />

                    {formik.errors.brandGoals && (
                      <small className="text-danger fw-bold d-block mb-5">
                        {formik.errors.brandGoals}
                      </small>
                    )}
                  </div>
                </>
              )}

              {errorRequest && (
                <span className="text-danger fw-bold d-block mb-5">
                  {errorMessage}
                </span>
              )}

              <RegisterWizzardController
                actualField={actualField}
                setNextField={setNextField}
                loading={loading}
              />
            </>
          )}
        </>
      </form>
    </>
  );
};

export { AditionalInfo };
