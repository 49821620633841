import {FC} from 'react'

const Error500: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-white mb-10'>Ops! Ocorreu algum erro.</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        Tente novamente mais tarde.
      </div>
    </>
  )
}

export {Error500}
