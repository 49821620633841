import React, { useState, TextareaHTMLAttributes, useEffect } from 'react';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FormikTouched } from "formik";

import "./style.scss";

interface EditRichTextData extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  touched: boolean | FormikTouched<any> | FormikTouched<any>[];
  error: string | React.ReactNode;
  setFieldValue: (val: string) => void;
  rawData: any;
}

export function EditRichText({ value, setFieldValue, placeholder, touched, error, rawData, ...rest }: EditRichTextData) {
  const [editorContent, setEditorContent] = useState(
    () => EditorState.createWithContent(convertFromRaw({
      "blocks": [
        {
          "key": "701rr",
          "text": "",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
        }
      ],
      "entityMap": {}
    })),
  );

  const maxLength = 3000;

  const classNameControl = !touched
    ? ""
    : error
      ? "is-invalid"
      : editorContent.getCurrentContent().getPlainText().length > 0 ? "is-valid" : "is-invalid";

  function handleEditorStateChange(editorState: EditorState) {
    const raw = convertToRaw(editorState.getCurrentContent());

    setEditorContent(editorState);
    setFieldValue(raw);
  }

  useEffect(() => {
    setEditorContent(() => EditorState.createWithContent(convertFromRaw(rawData)))
  }, [rawData]);

  return (
    <div>
      <Editor
        placeholder={placeholder}

        editorState={editorContent}
        onEditorStateChange={handleEditorStateChange}

        toolbar={{
          options: ['inline', 'fontSize', 'colorPicker', 'blockType', 'textAlign', 'list'], // when back adjust the charset, just add 'emoji'
        }}

        handleBeforeInput={val => {
          const textLength = editorContent.getCurrentContent().getPlainText().length;

          if (val && textLength >= maxLength) {
            return 'handled';
          }

          return 'not-handled';
        }}

        handlePastedText={val => {
          const textLength = editorContent.getCurrentContent().getPlainText().length;

          return ((val.length + textLength) >= maxLength);
        }}

        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName={`editorClassName ${classNameControl}`}
      />

      <div className="d-flex justify-content-between">
        {error && (
          <small className="text-danger fw-bold d-block py-2">
            {error}
          </small>
        )}

        <p className="text-end text-gray-500 mt-3">
          {editorContent.getCurrentContent().getPlainText('').length} / {maxLength ? maxLength : 3000} CARACTERES
        </p>
      </div>
    </div>
  );
}
