import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as TooltipIcon } from '../../assets/svg/general/tooltip.svg';

import { ITriggerProps } from './types';

const Trigger: React.FC<ITriggerProps> = ({ description }): JSX.Element => {
  return <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 500 }}
    overlay={
      <Tooltip id="tooltip-banner">{description}</Tooltip>
    }>
    <span className="svg-icon svg-icon-primary svg-icon-1hx ms-2" style={{ cursor: 'pointer' }}>
      <TooltipIcon />
    </span>

  </OverlayTrigger>
}

export { Trigger }