/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
// import { getAuth } from '../../../../app/modules/auth'
import { AsideMenuItem } from './AsideMenuItem'
// import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'

export const AsideMenuMain = () => {
  // const auth = getAuth()
  // const Redirect = (): string => {
  //   if (!auth?.hasConnector) {
  //     return '/dashboard/settings/integration'
  //   } else if (!auth?.hasConnector && auth.chart_request === 0) {
  //     return '/dashboard/settings/financial'
  //   } else {
  //     return '/dashboard'
  //   }
  // }
  return (
    <>
      {/* <AsideMenuItemWithSub
                to='/dashboard'
                title='Dashboard'
                icon='bi bi-dash-lg'
                fontIcon='bi bi-dash-lg'
            >*/}
      <AsideMenuItem
        to={'/dashboard'}
        title='Painel Geral' hasBullet={false}
        icon="/media/imgs/Icon-painel.svg" iconActive='/media/imgs/painel-active.svg' />
      <AsideMenuItem to='/dashboard/chart-request' title='Comunidade' hasBullet={false}
        icon="/media/imgs/embaixadores.svg" iconActive='/media/imgs/embaixadores-active.svg' />
      <AsideMenuItem to='/dashboard/challenge' title='Desafios ' hasBullet={false}
        icon="/media/imgs/desafios.svg" iconActive='/media/imgs/desafios-active.svg' />
      <AsideMenuItem to='/dashboard/sales' title='Vendas' hasBullet={false}
        icon="/media/imgs/financeiro.svg" iconActive='/media/imgs/financeiro-active.svg' />
      {/* <AsideMenuItem to={auth.balence > 0 ? '/dashboard/financial' : 'dashboard/settings/financial'}
                title='Financeiro' hasBullet={false}
                icon="/media/imgs/financeiro.svg" iconActive='/media/imgs/financeiro-active.svg' /> */}
      <AsideMenuItem to='/dashboard/profile' title='Perfil' hasBullet={false}
        icon="/media/imgs/perfil.svg" iconActive='/media/imgs/perfil-active.svg' />
      <AsideMenuItem to='/dashboard/integration' title='Conectores' hasBullet={false}
        icon="/media/imgs/conectores.svg" iconActive='/media/imgs/conectores-active.svg' />

      {/* </AsideMenuItemWithSub> */}
    </>
  )
}


