

export type InfluencerType = {
  title: string;
  description: string
  image: string;
}

export type InfluencerBanner = {
  filename?: string
  image?: string
}

export enum codeInfluencer {
  UploadError = 424,
  BrandNotFound = 404,
  UnexpectedError = 500
}