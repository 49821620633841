import { RewardModal } from './Reward';
import { ApprovalModal } from './Approval';

interface ActionModalData {
  modalType?: string;
  modalControl: boolean;
  setModalControl: Function;

  modalActionItems: any[];
  setModalActionItems: Function;

  actionData: any[];
  setActionData: Function;

  onConfirmationSuccess?: () => void;
}

export function ActionModal({
  modalType,
  modalControl,
  modalActionItems,
  setModalActionItems,
  setModalControl,
  actionData,
  setActionData,
  onConfirmationSuccess = () => undefined
}: ActionModalData) {

  return (
    <>
      {
        modalType === 'reward'
          ?
            <RewardModal
              modalType={modalType}
              modalControl={modalControl}
              modalActionItems={modalActionItems}
              setModalActionItems={setModalActionItems}
              setModalControl={setModalControl}
              actionData={actionData}
              setActionData={setActionData}
              onConfirmationSuccess={onConfirmationSuccess}
            />
          :
            <ApprovalModal
              modalType={modalType}
              modalControl={modalControl}
              modalActionItems={modalActionItems}
              setModalActionItems={setModalActionItems}
              setModalControl={setModalControl}
              actionData={actionData}
              setActionData={setActionData}
              onConfirmationSuccess={onConfirmationSuccess}
            />
      }
    </>
  );
}
