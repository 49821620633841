import React from "react";

import Styles from "./RowSocial.module.scss";

import { IRowSocialProps } from "./types";

const RowSocial: React.FC<IRowSocialProps> = ({
  socialName,
  socialIcon,
  placeholderFieldLink,
  nameFieldLink,
  valueFieldLink,
  placeholderFieldNumber,
  nameFieldNumber,
  valueFieldNumber,
  onChange,
}): JSX.Element => {
  return (
    <>
      <div className="col-md-2">
        <div className={Styles.socialMediaContainer}>
          <span className="svg-icon svg-icon-muted svg-icon-2hx">
            {socialIcon}
          </span>
          <span className={Styles.socialMediaTitle}>{socialName}</span>
        </div>
      </div>
      <div className="col-md-8">
        <input
          className="form-control form-control-lg form-control-solid w-100"
          style={{ width: "22rem" }}
          placeholder={placeholderFieldLink}
          type="text"
          name={nameFieldLink}
          autoComplete="off"
          value={
            !valueFieldLink || valueFieldLink === "null" ? "" : valueFieldLink
          }
          onChange={(evt) => onChange?.(evt)}
          onBlur={(e) => (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.trim()}
        ></input>
      </div>
      <div className="col-md-2">
        <input
          className="form-control form-control-lg form-control-solid w-100"
          style={{ width: "11rem" }}
          placeholder={placeholderFieldNumber}
          min={0}
          type="number"
          name={nameFieldNumber}
          autoComplete="off"
          value={
            !valueFieldNumber || valueFieldNumber === "null"
              ? ""
              : valueFieldNumber
          }
          onChange={(evt) => onChange?.(evt)}
        ></input>
      </div>
    </>
  );
};

export { RowSocial };

