/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useFormik } from "formik";

import { Select, TextArea, TextField } from "app/components";
import { RegisterWizzardController } from "../../components/Controller";

import { WizzardFrameData } from "../types";
import { codeProfile, ProfileType } from "./types";
import { Link, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { UploadImage } from "app/components/Inputs/UploadImage";

import ProfileFormSchema from "./Form.schema";
import { erroAlert } from "app/components/ModalSwal/ModalSwal";
import { Modal, Alert } from "react-bootstrap";
import { BsCheck2Circle } from "react-icons/bs";
import { getAuth } from "app/modules/auth/core/AuthHelpers";

const Profile = ({
  formData,
  setFormData,
  actualPage,
  setNextPage,
  actualField,
  setNextField,
  categories,
  brand,
}: WizzardFrameData) => {
  const initialValues: ProfileType = {
    name: "",
    category: "",
    image_cover: "",
    aboutMe: "",
    acceptRule: null,
    socials: {
      instagram: "",
      facebook: "",
      twitter: "",
      youtube: "",
      tiktok: "",
    },
  };
  const [banner, setBanner] = useState({ filename: "", image: "" });
  const [intro, setIntro] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const bannerData =
    banner.image !== ""
      ? { filename: banner.filename, data: banner.image }
      : null;
  const [erro, setErro] = useState("");
  const [state, setState] = useState(false);

  const auth = getAuth();

  const currentValidationSchema = ProfileFormSchema[actualField];

  const errorMessages = {
    [codeProfile.FormValidation]: "Erro na validação do formulário.",
    [codeProfile.UnexpectedError]:
      "Falha no servidor. Tente novamente em breve",
    [codeProfile.InternalServerError]:
      "Falha no servidor. Tente novamente em breve",
    [codeProfile.PayloadTooLarge]: "Tamanho do banner muito grande."
  };

  const Error = (status: number): void => {
    const errorMessage = errorMessages[status] || "Erro desconhecido";
    setErro(errorMessage);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: currentValidationSchema,
    onSubmit: async (registry: ProfileType) => {
      formik.setTouched({
        aboutMe: null,
        socials: {
          instagram: null,
          facebook: null,
          twitter: null,
          youtube: null,
          tiktok: null,
        },
      });
      if (actualField === 3) {
        const token = localStorage.getItem("accessToken");
        try {
          setLoading(true);
          const res = await axios.post("/colab-update-info", {
            name: brand ? brand?.name : formData.companyName,
            url: brand ? brand?.url : formData.siteAddress,
            categoryId: registry.category,
            socials: [
              { fk_id_colab_type_label: 1, value: registry.socials.facebook },
              { fk_id_colab_type_label: 4, value: registry.socials.instagram },
              { fk_id_colab_type_label: 5, value: registry.socials.tiktok },
              { fk_id_colab_type_label: 7, value: registry.socials.twitter },
              { fk_id_colab_type_label: 9, value: registry.socials.youtube },
            ],
            brandToken: auth?.api_token ? auth?.api_token : token,
            acceptRule: registry.acceptRule,
            banner: { filename: bannerData.filename, data: bannerData.data },
            aboutMe: registry.aboutMe,
          });
          if (res.status === 200 || res.status === 201) {
            setFormData({
              ...formData,
              categoryId: registry.category,
              socials: [
                { fk_id_colab_type_label: 1, value: registry.socials.facebook },
                {
                  fk_id_colab_type_label: 4,
                  value: registry.socials.instagram,
                },
                { fk_id_colab_type_label: 5, value: registry.socials.tiktok },
                { fk_id_colab_type_label: 7, value: registry.socials.twitter },
                { fk_id_colab_type_label: 9, value: registry.socials.youtube },
              ],
              brandToken: token,
              acceptRule: registry.acceptRule,
              banner: { filename: bannerData.filename, data: bannerData.data },
              aboutMe: registry.aboutMe,
            });
            await axios.post("/brand/save-register-status", {
              accessToken: token,
              code: 2001,
            });
            setNextPage(actualPage + 1);
          }
        } catch (e) {
          setState(true);
          if (!e.response) setErro("Tamanho do arquivo enviado muito grande.")
          Error(e.response.status);
        } finally {
          setLoading(false);
        }
      } else {
        setNextField(actualField + 1);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        {intro ? (
          <>
            <h1 className="mb-5">
              Sabemos que cada marca tem uma necessidade diferente, por isso
              queremos conhecer um pouco mais a sua.
            </h1>
            <div className="mb-10">
              Tem mais 3 perguntinhas pra gente entender seu contexto e adequar
              nosso suporte pra vocês, ok?
            </div>
            <button className="btn btn-info" onClick={() => setIntro(false)}>
              Continuar
            </button>
          </>
        ) : (
          <>
            {state && <Alert variant="danger">{erro}</Alert>}
            {actualField === 0 && (
              <>
                <h2>Em qual categoria você encaixa sua marca?</h2>
                <p className="font-14 font-roboto mb-0 text-gray-600">
                  Você aparecerá neste segmento para os seus Lovrs ;)
                </p>

                <Select
                  label=""
                  name="category"
                  touched={formik.touched.category}
                  error={formik.errors.category}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  labelOfDefault=""
                  autoFocus
                  {...formik.getFieldProps("category")}
                >
                  {categories?.map((category) => (
                    <option key={category.id} value={category?.id}>
                      {category?.name}
                    </option>
                  ))}
                </Select>
              </>
            )}
            {actualField === 1 && (
              <>
                <h2> Nos mande um banner pra mostrar no perfil da marca.</h2>
                <p className="font-14 font-roboto mb-0 text-gray-600">
                  Este banner aparecerá para todos os Lovrs.
                </p>
                <span className="font-14 font-roboto mb-0 text-gray-600">
                  Formato: Na horizontal, em JPG ou PNG, com no máximo 1 MB e
                  até 1820 x 800.
                </span>
                <UploadImage
                  name="image_cover"
                  viewText={false}
                  label_title="Banner do Desafio"
                  label_subtitle="Defina uma imagem exclusiva para ilustrar este desafio."
                  badge_title="Opcional"
                  banner_title="Carregar arquivo..."
                  banner_subtitle="Seu banner precisa ser no formato JPG ou PNG, ter até 1 MB e dimensão máxima de 1820 x 800."
                  {...formik.getFieldProps("image_cover")}
                  touched={formik.touched.image_cover}
                  error={formik.errors.image_cover}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  setBaseValue={setBanner}
                />
              </>
            )}
            {actualField === 2 && (
              <>
                <h1>Fale sobre a sua marca para a nossa comunidade</h1>
                <div>
                  Esse texto é o cartão de visitas da sua marca para os Lovrs,
                  vale a pena caprichar.
                </div>
                <div className="mb-8">
                  <TextArea
                    label=""
                    maxLength={420}
                    placeholder="Sobre sua marca..."
                    autoComplete="off"
                    name="aboutMe"
                    autoFocus
                    touched={formik.touched.aboutMe}
                    error={formik.errors.aboutMe}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    {...formik.getFieldProps("aboutMe")}
                  />
                </div>
              </>
            )}
            {/* actualField === 3 && <>
            <h1>Como será a <strong> aprovação</strong> de embaixadores?</h1>
            <ul className="mt-5">
              <li>
                <p className="font-14 font-roboto mb-0"><strong>Aprovação automática</strong> é feita pelo sistema assim que o Lovr pede pra entrar na comunidade.</p>
              </li>
              <li>
                <p className="font-14 font-roboto mb-0"> <strong>Aprovação manual</strong> é feita pela marca, depois de analisar <strong>cada pedido</strong> no painel.</p>
              </li>
            </ul>
            <label htmlFor="Automatica" className="w-100 mb-3">
              <input
                type="radio"
                className="form-check-input"
                {...formik.getFieldProps("acceptRule")}
                name="acceptRule"
                value="1"
                id="Automatica"
                autoFocus
                checked={Number(formik.values.acceptRule) === 1}
              /> Automática (comunidade aberta)
            </label>
            <label htmlFor="manual" className="w-100">
              <input
                type="radio"
                className="form-check-input "
                {...formik.getFieldProps("acceptRule")}
                name="acceptRule"
                value="2"
                id="manual"
                autoFocus
                checked={Number(formik.values.acceptRule) === 2}
              /> Manual (comunidade Fechada)
            </label>
          </> */}
            {actualField === 3 && (
              <>
                <h2> Redes Sociais</h2>
                <p className="font-14 font-roboto mb-0 text-gray-600">
                  Cole aqui os links das suas redes sociais. Você pode atualizar
                  isso mais tarde.
                </p>

                <div className="mt-3 mb-10">
                  <TextField
                    label="Facebook"
                    type="url"
                    autoFocus
                    placeholder="https://facebook.com/SuaMarca"
                    touched={formik.touched.socials?.facebook}
                    error={formik.errors.socials?.facebook}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    {...formik.getFieldProps("socials.facebook")}
                  />
                </div>

                <div className="mt-3 mb-10">
                  <TextField
                    label="Instagram"
                    type="url"
                    autoFocus
                    placeholder="https://instagram.com/SuaMarca"
                    touched={formik.touched.socials?.instagram}
                    error={formik.errors.socials?.instagram}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    {...formik.getFieldProps("socials.instagram")}
                  />
                </div>
                <div className="mt-3 mb-10">
                  <TextField
                    label="Tiktok"
                    type="url"
                    autoFocus
                    placeholder="https://tiktok.com/@SuaMarca"
                    touched={formik.touched.socials?.tiktok}
                    error={formik.errors.socials?.tiktok}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    {...formik.getFieldProps("socials.tiktok")}
                  />
                </div>
                <div className="mt-3 mb-10">
                  <TextField
                    label="Twitter"
                    type="url"
                    autoFocus
                    placeholder="https://twitter.com/SuaMarca"
                    touched={formik.touched.socials?.twitter}
                    error={formik.errors.socials?.twitter}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    {...formik.getFieldProps("socials.twitter")}
                  />
                </div>
                <div className="mt-3 mb-10">
                  <TextField
                    label="Youtube"
                    type="url"
                    autoFocus
                    placeholder="https://youtube.com/SuaMarca"
                    touched={formik.touched.socials?.youtube}
                    error={formik.errors.socials?.youtube}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    {...formik.getFieldProps("socials.youtube")}
                  />
                </div>
              </>
            )}

            <RegisterWizzardController
              actualField={actualField}
              setNextField={setNextField}
              loading={loading}
            />
          </>
        )}
      </form>
    </>
  );
};

export { Profile };
