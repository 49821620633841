import DatePtBR from 'date-pt-br'

const DateFormat = (data: string) => {
    const date = new DatePtBR();
    
    date.config({
        delimiterDate: '/',
        delimiterTime: ':',
        sepDateTime: ', ',
        sepDate: ' de ',
        sepTime: ' e '
    });

    return date.getDate(data);
}

const DateTimeFormat = (data: string) => {
  const date = new DatePtBR();
  
  date.config({
      delimiterDate: '/',
      delimiterTime: ':',
      sepDateTime: ' às ',
      sepDate: ' de ',
      sepTime: ' e '
  });

  return date.getDateTime(data);
}

export { DateFormat, DateTimeFormat };