import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { Accordion, AccordionButton } from "react-bootstrap";

import { ITableHeader, ITableProps, ITableSortEventPayload } from "./types";
import { ChevronDown } from "../Icons";
import { CheckBox } from "../CheckBox";
import { RiLoaderLine } from "react-icons/ri";

const INITIAL_SELECTED_ROWS_STATE: any[] = []
const INITIAL_SORTING_INFO_STATE: ITableSortEventPayload = {
  header: { value: '', isSortable: false },
  sortType: 'desc'
}

export const Table: React.FC<ITableProps> = ({
  rows,
  headers,
  hasBatchActions = true,
  hasCollapsableRows = false,
  onSelectedRowsChange = () => { },
  onSort = () => { },
  messageErro,
  titleErro,
  ...rest
}): JSX.Element => {
  const [selectedRows, setSelectedRows] = useState(INITIAL_SELECTED_ROWS_STATE);

  const [sortingInfo, setSortingInfo] = useState<ITableSortEventPayload>(INITIAL_SORTING_INFO_STATE);

  const selectRows = useCallback((...keys: any): void => {
    setSelectedRows(prevState => [...prevState, ...keys]);
  }, [setSelectedRows]);

  const dropRows = useCallback((...keys: any): void => {
    setSelectedRows(prevState => [...prevState.filter(element => !keys.includes(element))]);
  }, [setSelectedRows]);

  const handleOnSort = useCallback((header: ITableHeader) => {
    let type;

    if (header.value !== sortingInfo?.header.value) {
      type = 'desc';
    } else {
      type = sortingInfo.sortType === 'desc' ? 'asc' : 'desc';
    }

    const sortingPayload = { header: header, sortType: type };

    setSortingInfo(sortingPayload)
    onSort(sortingPayload);
  }, [onSort, sortingInfo, setSortingInfo]);

  useEffect(() => {
    onSelectedRowsChange(selectedRows);
  }, [onSelectedRowsChange, selectedRows]);

  const transformHeaders = useCallback(() => {
    return (
      <tr>
        {hasBatchActions && (
          <th>
            <div className="table-header">
              <CheckBox
                checked={selectedRows.length === rows.length}
                onChange={checked => {
                  setSelectedRows(INITIAL_SELECTED_ROWS_STATE);
                  checked && selectRows(...rows.map(r => r.key));
                }}
              />
            </div>
          </th>
        )}

        {hasCollapsableRows && (
          <th>
            <div className="table-header"></div>
          </th>
        )}

        {headers.map((header, index) => {
          const { value, isSortable } = header;

          return (
            <th key={`table_header_${index}`}>
              <div
                className={clsx('table-header', { 'sortable': isSortable }, { 'static': !isSortable })}
                onClick={() => handleOnSort(header)}
              >
                {value.toUpperCase()}

                <span
                  className={clsx('chevron',
                    { 'sorted': value === sortingInfo.header.value },
                    { 'chevron-desc': sortingInfo.sortType === 'desc' },
                    { 'chevron-asc': sortingInfo.sortType === 'asc' })}
                >
                  {isSortable && <ChevronDown width={20} />}
                </span>
              </div>
            </th>
          )
        })}
      </tr>
    )
  }, [hasBatchActions, selectedRows.length, rows, hasCollapsableRows, headers, selectRows, sortingInfo.header.value, sortingInfo.sortType, handleOnSort])

  const transformRows = useCallback(() => {
    return rows.map((row, index) =>
      <React.Fragment key={`table_row_${index}`}>
        <Accordion.Item
          eventKey={row.key}
          as="tr"
          bsPrefix={clsx({ 'selected': selectedRows.includes(row.key) }, { 'clickable': row.onClick }, 'border-0')}
          onClick={event => row.onClick ? row.onClick(event) : false}
        >
          {hasBatchActions && (
            <td>
              <div className="table-data">
                <CheckBox
                  checked={selectedRows.includes(row.key)}
                  onChange={(checked => checked ? selectRows(row.key) : dropRows(row.key))}
                />
              </div>
            </td>
          )}

          {
            hasCollapsableRows && (
              <td colSpan={1} className="cursor-pointer">
                <AccordionButton bsPrefix={'accordion-button accordionButton'} />
              </td>
            )
          }

          {row.values.map((cell, index) => (
            <td key={`table_row_cell_${index}`}>
              <div className={hasCollapsableRows ? "collapse-table-data" : "table-data"}>
                {cell}
              </div>
            </td>
          ))}
        </Accordion.Item>

        {
          hasCollapsableRows && (
            <Accordion.Collapse eventKey={row.key} as="tr" bsPrefix="selected">
              <>
                {row.subValues.map((cell, index) => (
                  <td colSpan={1} key={`table_row_cell_${index}`}>
                    <div className={hasCollapsableRows ? "collapse-table-data" : "table-data"}>
                      {cell}
                    </div>
                  </td>
                ))}
              </>
            </Accordion.Collapse>
          )
        }
      </React.Fragment>
    )
  }, [rows, selectedRows, hasBatchActions, hasCollapsableRows, selectRows, dropRows])

  if (rows.length === 0) {
    return <>
      <div className='d-flex flex-column justify-content-center p-20 align-items-center'>
        <div className='bg-gray-300 rounded-circle mb-5'>
          <RiLoaderLine className='fa-3x p-2' />
        </div>
        <h2>{titleErro}</h2>
        <p className='text-gray-400'>{messageErro}</p>
      </div>
    </>
  } else {
    return <>
      <table id={"table-component"}>
        <thead>
          {transformHeaders()}
        </thead>

        <Accordion as="tbody">
          {transformRows()}
        </Accordion>
      </table>
    </>
  }
}
