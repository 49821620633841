import React, { useImperativeHandle, useRef, useState } from "react";
import { node } from "prop-types";

import { ISelectProps, SelectRef } from "./types";

const Select = React.forwardRef<SelectRef, ISelectProps>(
  (
    { touched, error, label, labelOfDefault, children, ...props },
    ref
  ): JSX.Element => {
    const selectReference = useRef<HTMLSelectElement>(null);
    const [didFocus, setDidFocus] = useState<boolean>(false);
    const showFeedback =
      (didFocus && props.value?.toString().trim().length < 3) || touched;

    const classNameControl = !touched
      ? ""
      : showFeedback && error
      ? "is-invalid"
      : "is-valid";

    useImperativeHandle(ref, () => ({
      handleFocus() {
        if (selectReference.current) selectReference.current.focus();
      },
    }));

    return (
      <>
        <label className="form-label">{label}</label>

        <select
          ref={selectReference}
          {...props}
          className={`form-control form-control-lg form-control-solid ${classNameControl} select-input`}
          value={props.value || ""}
          onFocus={() => setDidFocus(true)}
        >
          <option value="" disabled defaultValue="">
            {labelOfDefault}
          </option>
          {children}
        </select>
        {showFeedback && error && (
          <small className="text-danger fw-bold d-block py-2">{error}</small>
        )}
      </>
    );
  }
);

Select.propTypes = {
  children: node.isRequired,
};

export { Select };
