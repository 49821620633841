/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Trigger } from "../Trigger";
import { IMaskInputProps, MaskInputRef } from "./types";
import { useMasks } from "app/modules/hooks/useMask";
const MaskInput = forwardRef<MaskInputRef, IMaskInputProps>(({
  touched,
  error,
  label,
  badge = false,
  trigger = false,
  textTooltip,
  disabled = false,
  type = 'text',
  mask,
  ...props },
  ref): JSX.Element => {

  const inputReference = useRef<HTMLInputElement>(null);
  const [didFocus, setDidFocus] = useState<boolean>(false);
  const [value, setValue] = useState("");
  const { cpf, cep, phone, cnpj, currency, data } = useMasks();

  const showFeedback =
    (didFocus && props.value?.trim().length < 3) || touched;

  const classNameControl = !touched
    ? ""
    : showFeedback && error
      ? "is-invalid"
      : "is-valid";

  useImperativeHandle(ref, () => ({
    handleFocus() {
      if (inputReference.current) inputReference.current.focus();
    },
  }));

  const handleChangeEvent = useCallback((e) => {
    setValue(e.target.value);
    if (mask === 'cpf') {
      cpf(e);
    }
    if (mask === 'cep') {
      cep(e);
    }
    if (mask === 'phone') {
      phone(e);
    }
    if (mask === 'cnpj') {
      cnpj(e);
    }
    if (mask === 'currency') {
      currency(e);
    }
    if (mask === 'data') {
      data(e);
    }
  }, [value, mask]);
  return (
    <>
      {label !== undefined &&
        <>
          <label className="form-label">{label}</label>
          {trigger && <Trigger description={textTooltip} />}
          {badge && <span className="badge badge-blue-primary">Opcional</span>}
        </>
      }

      <input
        ref={inputReference}
        {...props}
        className={`form-control form-control-lg form-control-solid ${classNameControl}`}
        type={type}
        value={props.value || ""}
        onFocus={() => setDidFocus(true)}
        disabled={disabled}
        onInput={(e) => handleChangeEvent(e)}
      />
      {showFeedback && error && (
        <small className="text-danger fw-bold d-block py-2">{error}</small>
      )}
    </>
  );
}
);
export { MaskInput };


