import Swal from "sweetalert2"

export const successAlert = (title: string, text: string, buttonText = 'Fechar') => {
  Swal.fire({
    title: `${title}`,
    text: `${text}`,
    icon: 'success',
    confirmButtonText: `${buttonText}`,
    confirmButtonColor: '#009ef7',
    customClass: {
      confirmButton: "btn btn-primary",
      denyButton: "btn btn-danger",
    }
  })
}

export const toastSuccess = (title: string, text: string) => {
  Swal.fire({
    title: `${title}`,
    text: `${text}`,
    icon: 'success',
    toast: true,
    timer: 2000,
    allowOutsideClick: true,
    position: 'bottom',
    showConfirmButton: false
  })
}

export const erroAlert = (title: string, text: string, buttonText = 'Fechar') => {
  Swal.fire({
    title: `${title}`,
    text: `${text}`,
    icon: 'error',
    confirmButtonText: `${buttonText}`,
    confirmButtonColor: '#d9214e',
    customClass: {
      confirmButton: "btn btn-danger",
      denyButton: "btn btn-danger",
    },
  })
}

export const warningAlert = (title: string, text: string, buttonText = 'Fechar') => {
  Swal.fire({
    title: `${title}`,
    text: `${text}`,
    icon: 'warning',
    confirmButtonText: `${buttonText}`,
    confirmButtonColor: '#e0a800',
    customClass: {
      confirmButton: "btn btn-danger",
      denyButton: "btn btn-danger",
    }
  })
}
