import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MoneyInput from "@rschpdr/react-money-input";
import { Modal } from "react-bootstrap";
import { useAuth } from "../../modules/auth";

const AddFunds = () => {
    const [account, setAccount] = useState({} as any);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState({} as any);
    const [depositValue, setDepositValue] = useState<any>();
    const [couponCopied, setCouponCopied] = useState(false);

    const token = JSON.parse(localStorage.getItem("kt-auth-react-v"));
    const { logout } = useAuth()

    useEffect(() => {
        GetAccount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GetAccount = async () => {
        try {
            setLoading(true);
            const res = await axios.post("/colab/wallet/get-account", {
                token: token.api_token,
            });
            setAccount(res.data.data);
            setLoading(false);
        } catch (e) {
            if (e.response.data.error === "User not found" || e.response.data.error === "Invalid param: accessToken" || e.response.data.error === "Brand not found") {
                logout()
            }
        }
    };

    const handleChangeWithDraw = (e): any => {
        setDepositValue(e.target.value);
    };

    const handleGeneratePayment = async () => {
        setCouponCopied(false)
        try {
            const res = await axios.post("/colab/wallet/add-money", {
                token: token.api_token,
                value: depositValue,
            });
            setPaymentInfo(res.data.data);
            setShow(true);
        } catch (e) {
            if (e.response.data.error === "User not found" || e.response.data.error === "Invalid param: accessToken" || e.response.data.error === "Brand not found") {
                logout()
            }
        }
    };

    const handleClose = () => {
        setShow(false);
    };

    const handleCopyCoupon = (): void => {
        setCouponCopied(true);
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        navigator.clipboard.writeText(paymentInfo.content);
    };

    return (
        <>
            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between">
                <Link to="/dashboard/financial">
                    <button className="btn btn-primary text-white w-100 mt-5 mb-5">
                        Voltar
                    </button>
                </Link>
            </div>
            <div className="row">
                <article className="col-md-4 py-4 py-md-0">
                    <div className="border rounded-2 py-7 px-7 h-100">
                        {loading ? (
                            <div className="d-flex justify-content-center h-100 flex-column align-items-center">
                                <div
                                    className="spinner-grow text-primary"
                                    role="status"
                                />
                            </div>
                        ) : (
                            <>
                                <h2>Adicionar dinheiro</h2>
                                <p className="mb-0 mt-5"> Quanto você quer adicionar?</p>

                                <MoneyInput
                                    onChange={handleChangeWithDraw}
                                    value={depositValue}
                                    variant="outlined"
                                    className="form-control form-control-lg form-control-solid mt-5 mb-5"
                                    currencyConfig={{
                                        locale: "pt-BR",
                                        currencyCode: "BRL",
                                        currencyDisplay: "symbol",
                                    }}
                                />
                                <div className="d-flex justify-content-between">
                                    <p>Nome</p>
                                    <p>{account?.name}</p>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <p>CNPJ</p>
                                    <p>{account?.cnpj}</p>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <p>Instituição</p>
                                    <p>{account?.institution}</p>
                                </div>
                                <button
                                    className="btn btn-primary text-white w-100 mt-5"
                                    onClick={handleGeneratePayment}>
                                    Gerar código para pagamento
                                </button>
                            </>
                        )}
                    </div>
                </article>
            </div>
            <Modal show={show} onHide={handleClose}>
                <div className="d-flex flex-column p-10">
                    <div className="d-flex justify-content-between w-100">
                        <h2>Código de pagamento</h2>
                        <span onClick={handleClose} className="svg-icon svg-icon-primary svg-icon-2hx me-3 cursor-pointer">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    opacity="0.3"
                                    x="2"
                                    y="2"
                                    width="20"
                                    height="20"
                                    rx="10"
                                    fill="currentColor"
                                />
                                <rect
                                    x="7"
                                    y="15.3137"
                                    width="12"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 7 15.3137)"
                                    fill="currentColor"
                                />
                                <rect
                                    x="8.41422"
                                    y="7"
                                    width="12"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 8.41422 7)"
                                    fill="currentColor"
                                />
                            </svg>
                        </span>
                    </div>

                    <p style={{ fontSize: "20px", color: "#464E5F" }}>
                        {depositValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </p>

                    <p>
                        Escaneie o QR Code ou utilize o código PIX abaixo para
                        depositar este valor na sua conta Brandlovrs.
                    </p>

                    {/*  TODO: Insert QR Code Image               
                    <img /> */
                    }

                    <input
                        value={paymentInfo?.content}
                        disabled
                        className="form-control form-control-lg form-control-solid w-100 h-100"
                    />

                    <button
                        onClick={handleCopyCoupon}
                        className={
                            couponCopied
                                ? `btn btn-success text-white w-100 mt-5 mb-5 m-auto disabled`
                                : "btn btn-primary text-white w-50 mt-5 mb-5 m-auto"
                        }
                    >
                        {couponCopied ? "Copiado" : "Copiar código"}
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default AddFunds;

