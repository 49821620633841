/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef } from 'react'
import { KTSVG } from '../../../helpers'
import { StepperComponent } from '../../../assets/ts/components'


const Main: FC = () => {
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }

        loadStepper()
    }, [stepperRef])

    return (
        <div className='modal fade' id='kt_modal_create_app' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered mw-900px'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h2>Create App</h2>

                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>

        
                </div>
            </div>
        </div>
    )
}

export { Main }
