import React from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/pt-PT'

import enMessages from './messages/en.json'
import ptBrMessages from './messages/pt-BR.json'

const allMessages = {
  en: enMessages,
  'pt-BR': ptBrMessages
}

const I18nProvider = ({children}: any) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
