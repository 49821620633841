/* eslint-disable import/no-anonymous-default-export */

import * as Yup from "yup";
export default [
    Yup.object({
      name: Yup.string().required("Este campo é obrigatório."),
    }),
    Yup.object({
      email: Yup.string()
        .email("Insira um e-mail válido.")
        .required("Este campo é obrigatório."),
    }),
    Yup.object({
      whatsapp: Yup.string().required("Este campo é obrigatório."),
    }),
    Yup.object({
      companyName: Yup.string().required("Este campo é obrigatório."),
    }),
    Yup.object({
      siteAddress: Yup.string()
        .matches(
          /^(http(s):\/\/)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/,
          "Deve ser informada um endereço de site válido."
        )
        .required("Este campo é obrigatório."),
    }),
    Yup.object({
      brandLogo: Yup.string().optional(),
    }),
    Yup.object({
      password: Yup.string()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            "A senha deve conter pelo menos 1 letra maiúscula, 1 número e 1 caracter especial"
        )
        .required("Este campo é obrigatório."),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "As senhas devem ser iguais")
        .required("Este campo é obrigatório."),
      acceptTerms: Yup.bool().isTrue("Este campo é obrigatório."),
    }),
  ]
  