import { useContext } from "react";

import { BannerContext } from "./bannerContext";
import { IBannerContext } from "./types";

const useBannerContext = (): IBannerContext => {
  const context = useContext(BannerContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};

export { useBannerContext };

