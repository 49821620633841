import { warningAlert } from "app/components/ModalSwal/ModalSwal"

enum typeErro {
  message1 = 'User not found',
  message2 = "Brand not found",
  message3 = "Invalid param: accessToken",
  message4 = "Unavailable Balance!"
}

const ValideteError = (erro: any) => {

  const err = erro.response.data.error
  if (err === typeErro.message1 || err === typeErro.message2 || err === typeErro.message3) {
    return true
  } else if (err === typeErro.message4) {
    warningAlert('Opss', 'Você não tem saldo disponivel para criar esse desafio')
  }
  return false
}

export default ValideteError