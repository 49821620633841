import React, { useEffect, useState } from "react";
import metabase from "app/modules/services/metabase";

interface ChallengeDashboardData {
  challengeId: number;
}

 function EngagementChallengeDashboard (challengeId: ChallengeDashboardData) {
  const [iframe, setIframe] = useState<any>({
    header: "",
    urlEmbed: ""
  });

  const hash_token = `0a1cf46334ca6a2c2e61c3e96aafd5f8acf3f522`;

  const GetEngagementChallengeDashboard = async () => {
    try {
      const response = await metabase.post('/dashboard/get-dashboard', {
        hash: hash_token,
        challenge_id: [challengeId.challengeId],
      });

      setIframe(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (challengeId.challengeId) {
      GetEngagementChallengeDashboard();
    }
    // eslint-disable-next-line
  }, [challengeId])


  return (
    <>
      {/* <h1 className="mb-10">Painel Geral - Resumo das atividades</h1> */}
        <iframe
          title="dashboard"
          src={iframe.urlEmbed}
          frameBorder="0"
          width="800"
          height="750"
          style={{ width: "100%" }}
          className="mb-10 mt-10"
        />
      {/* <ChartRequests /> */}
    </>
  );
};
export default React.memo(EngagementChallengeDashboard)