import axios from 'axios';

const metabase = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});


metabase.defaults.headers.Accept = 'application/json';
metabase.defaults.headers['x-api-key'] = `${process.env.REACT_APP_API_KEY}`;
delete metabase.defaults.headers.common['Authorization'];

metabase.interceptors.request.use(
  (config: { headers: { Authorization: string } }) => {
    delete config.headers.Authorization;

    return config;
  },
  (err: any) => {
      Promise.reject(err)
  }
);

export default metabase;