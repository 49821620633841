/* eslint-disable no-octal */
import Pagination from "react-js-pagination";

import { IPaginativeProps } from './type';

const Paginative = ({ setActivePage, activePage, totalRequests, limit, text = false, styles = "end" }: IPaginativeProps) => {
  const pageNumber = Math.ceil(totalRequests / limit) * 10;

  function handlePageChange(event) {
    setActivePage(event);
    console.log(event);
  }

  return (
    <div className={`d-flex justify-content-${styles} align-items-center`}>
      <>
        {text &&
          <span className='me-10 text-gray-700'>
            Exibindo {limit} itens por página
          </span>
        }

        <Pagination
          activePage={activePage}
          totalItemsCount={pageNumber}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          innerClass="pagination cursor-pointer"
          itemClass="page-item"
          linkClass="page-link"
          nextPageText=">"
          prevPageText="<"
          linkClassPrev="setas"
          linkClassFirst="setas"
          linkClassNext="setas"
          linkClassLast="setas"
        />
      </>
    </div>
  )
}

export default Paginative