import { useAuth } from "../../auth"

const Token = (): string => {
    const { logout } = useAuth()
    const token = JSON.parse(localStorage.getItem('kt-auth-react-v'))

    if (!token || token.api_token === null || token.api_token === '') {
        logout()
        window?.location.reload()
    } else {
        return token.api_token
    }
}

export default Token