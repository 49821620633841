import { Trigger } from 'app/components'
import { Table } from 'app/components/Table';
import renderMetricTitle from 'app/modules/hooks/MetricTitle/MetricTitle';

import { ITableMetricProps } from './type';

const MetricTable = ({ metrics, setSelectedItems }: ITableMetricProps) => {

  const header = [
    { value: 'Métricas' },
    { value: 'Meta', hasPopover: 'Descricao do Meta Header' },
  ];



  return (
    <Table
      headers={header}
      rows={
        Object.keys(metrics).length === 0 ? [] :
          metrics.map((metric, index) => ({
            key: metric.id,
            values: [
              <p className="text-left">
                {renderMetricTitle(metric.name)}
                <Trigger description={`Número total de ${renderMetricTitle(metric.name)} em uma publicação`} />
              </p>,
              <div className="mw-25">
                <input
                  type="text"
                  name={`test-${index}`}
                  id={`${metric.id}`}
                  onChange={(e) => (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(/[^0-9;]/, '')}
                  className="form-control form-control-lg form-control-solid"
                />
              </div>
            ]
          }))
      }
      onSelectedRowsChange={rowKeys => setSelectedItems(rowKeys)}
      hasBatchActions={true}
      hasHeaderDivider={true}
    />
  )
}

export default MetricTable;