/* eslint-disable react-hooks/exhaustive-deps */
import { FaCheck, FaTimes } from 'react-icons/fa';
import { IoMdMail } from 'react-icons/io';

import { Table } from 'app/components/Table';
import { ActionButton } from 'app/components/Buttons';
import { Check, XMark } from 'app/components/Icons';

import { SocialMediaList } from '../SocialMediaList';
import { AmbassadorResultDashboard } from '../AmbassadorResultDashboard';
import { TagsList } from '../TagsList';
import { InterestsList } from '../InterestsList';

import styles from './styles.module.scss';
import { Dropdown } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';

type CommunityListData = {
  listData: any;
  handleChangeStatusRequest: any;
  handleGetAmbassadorTagList: Function;
  isLoadingButton: number;
  setIsLoadingButton: Function;
  setRefresh: Function;
  listChartRequest: Function;
}

export const CommunityList = ({listChartRequest, setRefresh,listData, handleChangeStatusRequest, isLoadingButton, handleGetAmbassadorTagList, setIsLoadingButton }: CommunityListData) => {
  return (
    <section className='mb-10'>
      <div className="scroll-view">
        <Table
          headers={[
            { value: 'Nome' },
            { value: 'Redes Sociais', isSortable: false },
            { value: 'Instagram', isSortable: false },
            { value: 'Tags e Interesses', isSortable: false },
            { value: 'Resultados', isSortable: false },
            { value: 'Status', isSortable: false },
            { value: '' }
          ]}
          rows={
            Object.keys(listData).length === 0 ? [] :
              listData.map((creator) => ({
                key: creator?.creatorId,
                values: [
                  creator?.name,
                  <SocialMediaList medias={creator?.socialMedia} />,
                  !creator?.socialMedia?.instagram?.followers ? <span className='fst-italic' style={{ fontSize: '10px' }}>
                    Sem dados por enquanto
                  </span> : <div>
                    <span className='d-block fs-4 py-2' style={{ fontSize: '10px' }}>{Number(creator?.socialMedia?.instagram?.followers).toLocaleString()}</span>
                    <span className='d-block' style={{ fontSize: '10px' }}>Seguidores</span>
                  </div>
                  ,
                  <TagsList
                    tags={creator?.tags}
                    ambassadorId={creator?.creatorId}
                    canCreateTag={creator?.status}
                    handleGetAmbassadorTagList={handleGetAmbassadorTagList}
                    isLoadingButton={isLoadingButton}
                    setIsLoadingButton={setIsLoadingButton}
                    setRefresh={setRefresh}
                    listChartRequest={listChartRequest}
                  />,
                  <AmbassadorResultDashboard
                    name={creator?.name}
                    ambassador={creator?.creatorId}
                    approval={creator?.status}
                  />,
                  <div className="d-flex justify-content-center align-items-center w-100px">
                    {
                      creator?.status === 'pending' ?
                        <>
                          <ActionButton
                            type="approved"
                            className={'text-light mx-1'}
                            onClick={() => handleChangeStatusRequest(true, creator?.id)}
                          >
                            <Check width={14} />
                          </ActionButton>

                          <ActionButton
                            type="reproved"
                            className={'text-light mx-1'}
                            onClick={() => handleChangeStatusRequest(false, creator?.id)}
                          >
                            <XMark width={14} />
                          </ActionButton>
                        </>
                        : creator?.status === 'approved' ? <span className='approvedTxt'><FaCheck /> Aprovado</span> : <span className='declinedTxt'><FaTimes /> Reprovado</span>
                    }
                  </div>,
                  <Dropdown>
                    <Dropdown.Toggle variant="" id="dropdown-basic" className='BsThreeDotsVertical'>
                      <BsThreeDotsVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {(creator?.status === 'approved' || creator?.status === 'pending') && <Dropdown.Item eventKey="2" onClick={() => handleChangeStatusRequest(false, creator?.id)}>Reprovar</Dropdown.Item>}
                      {(creator?.status === 'declined' || creator?.status === 'pending') && <Dropdown.Item eventKey="1" onClick={() => handleChangeStatusRequest(true, creator?.id)}>Aprovar</Dropdown.Item>}
                    </Dropdown.Menu>
                  </Dropdown>
                ],
                subValues: [
                  '',
                  <span className={`${styles.subValueText} text-nowrap`}><IoMdMail fill='#7E8299' /> {creator?.email}</span>,
                  '',
                  '',
                  <InterestsList interests={creator?.interestsName} />,
                  '',
                  ''
                ]
              }))
          }
          hasBatchActions={false}
          hasCollapsableRows
          titleErro='Você ainda não tem nenhum embaixador'
          messageErro='Que tal criar o primeiro desafio da sua marca ?'
        />
      </div>
    </section>
  )
}
