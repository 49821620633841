import { Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

interface ControllerButtonData extends React.HTMLAttributes<HTMLButtonElement> {
  actualPage?: number;
  setNextPage?: Function;
  disableNextPage?: boolean;

  actualField?: number;
  setNextField?: Function;

  handleSubmit?: Function;
  loading?: boolean;

  nextField?: any;
}

const RegisterWizzardController = ({
  actualField,
  loading,
  setNextField,
  disableNextPage,
  handleSubmit,
  nextField,
}: ControllerButtonData) => {
  return (
    <div className={`d-flex justify-content-between align-items-center mt-5`}>
      {actualField >= 1 ? (
        <Button
          type="button"
          variant="light"
          onClick={() => setNextField(actualField - 1)}
        >
          Voltar
        </Button>
      ) : (
        <Link to={"/auth"}>
          <span className="btn btn-light">Voltar</span>
        </Link>
      )}

      <Button type="submit" variant="primary" disabled={disableNextPage}>
        Próxima Etapa
        {loading && (
          <Spinner
            animation="grow"
            className={loading && "ms-3"}
            variant="light"
            size="sm"
          />
        )}
      </Button>
    </div>
  );
};

export { RegisterWizzardController };
