const Base64 = async (file: any) => {
    return new Promise((resolve) => {
        if (file && file.name) {
            const fr = new FileReader();
            fr.onload = (evt) => {
                resolve(evt.target.result as string);
            };
            fr.readAsDataURL(file)
            return
        }
        resolve(false)
    });
}

export default Base64