import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import MultiSelect from "multiselect-react-dropdown";

import Token from "../../modules/hooks/Token/Token";
import ValideteError from "../../modules/hooks/ValideteError/ValideteError";
import { useAuth } from "../../modules/auth";

import { ChartRequestCard, CommunityList } from "./components";
import Paginative from "../Paginative/Paginative";
import { BsSearch } from "react-icons/bs";
import { TextField } from "../TextField";

export interface IChartRequests {
  createdAt: string;
  followers: string;
  id: number;
  ambassadorId: number;
  interestsName: string[];
  tagsName: any;
  name: string;
  email: string;
  username: string;
  approvalStatus?: boolean | undefined;
  phone: string;
  state: string;
  socialMedia: any;
}

type OrderFilter = "asc" | "desc";
type ListFilter = "all" | "approved" | "declined" | "pending" | "name" | "tags";

interface ChartsFilter {
  order: OrderFilter;
  filter: any;
  page: number;
  tagsNames?: any[];
  ambassadorName?: string;
  searchName?: string;
}

interface TagData {
  id: number;
  name: string;
}

export const ChartRequests = () => {
  const [chartRequests, setChartRequests] = useState<IChartRequests[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(0 as any);
  const [refresh, setRefresh] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [viewType, setViewType] = useState("list");
  const [isTotal, setIsTotal] = useState(false);

  const [totalRequestsByFilter, setTotalRequestsByFilter] = useState(8);
  const [chartsFilter, setChartsFilter] = useState<ChartsFilter>({
    order: "asc",
    filter: "all",
    page: currentPage,
    tagsNames: [],
    searchName: "",
  });
  const [brandTags, setBrandTags] = useState<TagData[]>([]);
  const [newRequest, setNewRequest] = useState<number>();

  const limit = 8;
  const auth = Token();
  const { logout } = useAuth();

  async function listChartRequests() {
    try {
      setIsLoading(true);
      const payload: any = localStorage.getItem("kt-auth-react-v");
      let parsedPayload;
      if (payload) parsedPayload = JSON.parse(payload);

      const res = await axios.post("colab/influencer-panel", {
        accessToken: parsedPayload.api_token,
        brandId: parsedPayload.brandId,
        filter: {
          order: chartsFilter.order,
          orderBy: 'name',
          page: currentPage,
          pageSize: limit,
          by: {
            tags: chartsFilter.tagsNames,
            match: chartsFilter.searchName,
            status: [chartsFilter.filter],
          }
        }
      });
      if (res.data?.length === 8 && !isTotal) {
        setTotalRequestsByFilter(res.data?.length === 8 ? totalRequestsByFilter + 8 : totalRequestsByFilter);
      } else {
        setIsTotal(true);
      }
      setChartRequests(res.data);
      setNewRequest(0);
      handleListBrandTags();
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function handleListBrandTags() {
    const payload: any = localStorage.getItem("kt-auth-react-v");

    let parsedPayload;
    if (payload) parsedPayload = JSON.parse(payload);

    const response = await axios.post("colab/list-brand-ambassador-tags", {
      accessToken: parsedPayload.api_token,
      brandId: parsedPayload.brandId,
    });

    setBrandTags(response.data);
  }

  useEffect(() => {
    listChartRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, currentPage]);


  function handleListFilter(filterValue: ListFilter) {
    window.analytics.track("Influencer Listed Filter Changed", {
      filterLabel: filterValue,
    });

    setChartsFilter({ ...chartsFilter, filter: filterValue });
    setRefresh(draft => draft + 1)
  }

  async function handleGetAmbassadorTagList() {
    try {
      setIsLoading(false);

      const payload: any = localStorage.getItem("kt-auth-react-v");
      let parsedPayload;
      if (payload) parsedPayload = JSON.parse(payload);

      const res = await axios.post("colab/influencer-panel", {
        accessToken: parsedPayload.api_token,
        brandId: parsedPayload.brandId,
        filter: {
          order: chartsFilter.order,
          orderBy: 'name',
          page: currentPage,
          pageSize: limit,
          by: {
            tags: chartsFilter.tagsNames,
            match: chartsFilter.searchName,
            status: [chartsFilter.filter],
          }
        }
      });

      setChartRequests(res.data);
      setIsLoadingButton(false);
    } catch (e) {
      setIsLoadingButton(false);
      if (ValideteError(e)) {
        logout();
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function handleChangeStatusRequest(status: boolean, requestId: number) {
    try {
      setIsLoadingButton(requestId);

      const response = await axios.post("colab/influencer-apply-result", {
        accessToken: auth,
        requestId: requestId,
        approvalStatus: status,
      });

      if (response.status === 200) {
        status &&
          window.analytics.track("Influencer Approved", {
            influencerId: requestId,
          });

        !status &&
          window.analytics.track("Influencer Declined", {
            influencerId: requestId,
          });

        setRefresh(refresh + 1);
      }
      setIsLoadingButton(0);
    } catch (e) {
      if (e.response.data.error === "User not found") {
        logout();
      }
    }
  }

  function handleAddFilterTags(selectedList, selectedItem) {
    setChartsFilter({
      ...chartsFilter,
      searchName: '',
      tagsNames: [...chartsFilter.tagsNames, selectedItem.name],
    });
    setRefresh(draft => draft + 1);
  }

  function handleRemoveFilterTags(selectedList, removedItem) {
    setChartsFilter({
      ...chartsFilter,
      searchName: '',
      tagsNames: chartsFilter.tagsNames.filter(function (tag) {
        return tag !== removedItem.name;
      }),
    });
    setRefresh(draft => draft + 1);

  }

  // function handleChangeCommunityView(viewType: string) {
  //   setIsLoading(true);
  //   setViewType(viewType);

  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 200);
  // }

  return (
    <>
      <h1 className="mb-8">Minha comunidade</h1>
      <div className="container row justify-content-md-between mb-10">
        <div className="d-flex align-items-md-center justify-content-between">
          <div className="d-flex align-items-md-center justify-content-start">
            <div className="d-flex flex-column flex-md-row align-items-md-center min-w-250px pb-5 pb-md-0 gap-2">
              <TextField
                value={chartsFilter.searchName}
                touched={false}
                error=""
                onChange={(e) => {
                  setChartsFilter({
                    ...chartsFilter,
                    searchName: e.currentTarget.value,
                  })
                  if (e.currentTarget.value === "") {
                    setRefresh(refresh + 1)
                  }
                }}
                placeholder="Pesquisar por nome"
              />
              <button className="btn btn-primary" onClick={() => setRefresh(refresh + 1)}><BsSearch /></button>
            </div>
          </div>

          <div className="d-flex align-items-md-center justify-content-end">
            <div className="d-flex align-items-md-center pb-5 pb-md-0 mx-5">
              <span className="">
                <label htmlFor="select" className="fw-bold d-block me-3">
                  {" "}
                  Listar:
                </label>
              </span>

              <select
                className="form-select"
                onChange={(e) => {
                  handleListFilter(e.target.value as ListFilter);
                }}
              >
                <option value="pending">Novas Solicitações</option>
                <option value="approved">Aprovados</option>
                <option value="declined">Reprovados</option>
                <option value="all" selected>
                  Mostrar Todos
                </option>
              </select>
            </div>

            <div className="d-flex align-items-md-center pb-5 pb-md-0 mx-5">
              <span className="">
                <label htmlFor="select" className="fw-bold d-block me-3">
                  {" "}
                  Tags:
                </label>
              </span>

              <MultiSelect
                options={brandTags}
                displayValue="name"
                isObject
                showCheckbox
                placeholder="Pesquise e filtre por tags"
                emptyRecordMsg="Nenhuma opção disponível"
                hidePlaceholder={false}
                showArrow
                onSelect={handleAddFilterTags}
                onRemove={handleRemoveFilterTags}
                style={{
                  chips: {
                    display: "none",
                  },
                  searchBox: {
                    border: "0px solid #ccc",
                    borderRadius: "0px",
                    minHeight: "0px",
                    padding: "0px",
                    position: "relative",
                  },
                  inputField: {
                    display: "block",
                    width: "100%",
                    padding: "0.75rem 3rem 0.75rem 1rem",
                    MozPaddingStart: "calc(1rem - 3px)",
                    fontSize: "1.1rem",
                    fontWeight: "500",
                    lineHeight: "1.5",
                    color: "#5e6278",
                    backgroundColor: "#ffffff",
                    backgroundImage:
                      "url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237e8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e)",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right 1rem center",
                    backgroundSize: "16px 12px",
                    border: "1px solid #e4e6ef",
                    borderRadius: "0.475rem",
                    boxShadow: "inset 0 1px 2px rgb(0 0 0 / 8%)",
                    transition:
                      "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                    appearance: "none",
                  },
                }}
              />

              {/* <div className="ps-4">
                {viewType === "list" ? (
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary"
                    onClick={() => handleChangeCommunityView("grid")}
                  >
                    <i className="bi fs-1 pe-0 py-1 bi-grid-3x3-gap"></i>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary"
                    onClick={() => handleChangeCommunityView("list")}
                  >
                    <i className="bi fs-1 pe-0 py-1 bi-list"></i>
                  </button>
                )}
              </div> */}
            </div>
          </div>
        </div>

        <div className="pt-5">
          <h1>Embaixadores</h1>
          {!isLoading && (
            <>
              {" "}
              {newRequest !== 0 && (
                <>
                  <span
                    className="badge bg-blue rounded-1"
                    style={{ border: "2px" }}
                  >
                    {newRequest}
                  </span>
                  <p className="text-gray-400 fw-bold fs-5 d-inline mt-5 ms-2">
                    Novas solicitações
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {!isLoading ? (
        <>
          <div className="row">
            {viewType === "list" ? (
              <CommunityList
                listData={chartRequests}
                handleChangeStatusRequest={handleChangeStatusRequest}
                handleGetAmbassadorTagList={handleGetAmbassadorTagList}
                isLoadingButton={isLoadingButton}
                setIsLoadingButton={setIsLoadingButton}
                setRefresh={setRefresh}
                listChartRequest={listChartRequests}
              />
            ) : (
              chartRequests.map((chart) => (
                <ChartRequestCard
                  key={chart.id}
                  chart={chart}
                  handleChangeStatusRequest={handleChangeStatusRequest}
                  handleGetAmbassadorTagList={handleGetAmbassadorTagList}
                  isLoadingButton={isLoadingButton}
                  setIsLoadingButton={setIsLoadingButton}
                />
              ))
            )}
          </div>

          <Paginative
            setActivePage={setCurrentPage}
            text={true}
            activePage={currentPage}
            limit={limit}
            totalRequests={totalRequestsByFilter}
          />
        </>
      ) : (
        <div className="d-flex justify-content-center p-20 h-100">
          <Spinner animation="grow" variant="primary" />
        </div>
      )}
    </>
  );
};
