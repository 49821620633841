import React from 'react'
import { IAlertProps } from './types'

const iconAlert = (icon: string) => {
  switch (icon) {
    case 'erro':
      return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
        <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor" />
        <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor" />
      </svg>
    case 'success':
      return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
        <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
      </svg>
    case 'warning':
      return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
        <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor" />
        <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor" />
      </svg>
  }
}

const iconOthers = (icon: string) => {
  switch (icon) {
    case 'play':
      return <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99219 2H14.7495L20.1367 8.93666H13.0976L7.71517 2.00618C7.80699 2.00208 7.89934 2 7.99219 2Z" fill="#00A3FF" fillOpacity="0.3" />
        <path d="M28 8.93666H23.203L17.8158 2H22.0078C25.3172 2 28 4.63645 28 7.88868V8.93666Z" fill="#00A3FF" fillOpacity="0.3" />
        <path d="M5.17855 2.68821C3.28734 3.67844 2 5.63582 2 7.88868V8.93666H10.0313L5.17855 2.68821Z" fill="#00A3FF" fillOpacity="0.3" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2 11.4818H28V22.1113C28 25.3636 25.3172 28 22.0078 28H7.99219C4.68279 28 2 25.3636 2 22.1113V11.4818ZM19.9512 20.5103C20.7298 20.0685 20.7298 18.9641 19.9512 18.5223L13.4004 14.8055C12.6217 14.3637 11.6484 14.916 11.6484 15.7996L11.6484 23.2331C11.6484 24.1166 12.6217 24.6689 13.4004 24.2271L19.9512 20.5103Z" fill="#00A3FF" />
      </svg>
  }
}
const Alert: React.FC<IAlertProps> = ({
  title,
  children,
  variant,
  border,
  type,
  icon
}): JSX.Element => {
  if (border) {
    return (
      <div className={`alert bg-light-${variant} d-flex align-items-center p-5 p-md-8 mb-10 border-dashed border-${variant}`} role="alert" >
        <span className={`svg-icon svg-icon-${variant} svg-icon-2hx pe-sm-5`}>
          {iconAlert(variant)}
        </span>
        <div>
          <h3>{title}</h3>
          <div className="mb-0">{children}</div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={`alert bg-light-${type} d-flex align-items-center p-5 p-md-8 mb-10 border-${type} border-l`} role="alert" >
        <span className={`svg-icon svg-icon-${type} svg-icon-2hx pe-sm-5`}>
          {iconOthers(icon)}
        </span>
        <div>
          <h3 className={`text-${type}`}>{title}</h3>
          <div className="mb-0">{children}</div>
        </div>
      </div>
    )
  }
}
export default Alert