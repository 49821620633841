/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable-next-line*/
import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useLayout } from '../../core'
import { toAbsoluteUrl } from '../../../helpers'
import { AsideMenu } from './AsideMenu'
import { useAuth } from '../../../../app/modules/auth'

import axios from 'axios'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import Money from 'app/modules/hooks/Money/Money'
import Token from "../../../../app/modules/hooks/Token/Token";
import metabase from 'app/modules/services/metabase'





const AsideDefault: FC = () => {
  const { classes } = useLayout()
  const { logout } = useAuth()
  const token = Token()


  const [menu, setMenu] = useState({
    avatar_image: '',
    name: '',
    fk_id_brand: null
  })

  const [balance, setBalance] = useState(null)

  const [eye, setEye] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getAvatar = async () => {
    const payload: string = localStorage.getItem('accessPayload')
    let parsedPayload
    if (payload) parsedPayload = JSON.parse(payload)
    const res = await axios.post(`/profile/show`, {
      "id": parsedPayload.sub
    })
    setMenu(res.data)
  }

  const getBalance = async () => {
    const id = localStorage.getItem('kt-auth-react-v')
    const brandId = JSON.parse(id)

    const res = await metabase.post('/colab/brands/get-balance', {
      accessToken: token,
      brandId: brandId.brandId
    })
    setBalance(res.data?.available)
  }

  useEffect(() => {
    getAvatar()
    getBalance()
    // eslint-disable-next-line
  }, [])

  const [disabled, setDisabled] = React.useState(false)
  React.useLayoutEffect(()=>{
    var queryparams = window.location.search.split('?')[1]
    var params = queryparams?.split('&')
    
    var pair = null
    var data = []
    params?.forEach(function(d) {
      pair = d.split('=')
      data.push({ key: pair[0], value: pair[1] })
      if (pair[0] === 'tokenNewFront') {
        setDisabled(true)
        }
      }
    )
  },[disabled])
  return (
    <>
    {
      !disabled &&
        <div
          id='kt_aside'
          className={clsx('aside', classes.aside.join(' '))}
          data-kt-drawer='true'
          data-kt-drawer-name='aside'
          data-kt-drawer-activate='{default: true, lg: false}'
          data-kt-drawer-overlay='true'
          data-kt-drawer-width="{default:'200px', '300px': '250px'}"
          data-kt-drawer-direction='start'
          data-kt-drawer-toggle='#kt_aside_mobile_toggle'
        >
          {/* begin::Brand */}
          <div className='aside-logo d-flex d-justify-content-between' id='kt_aside_logo'>
            <Link to='/dashboard'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo-white.svg')}
                width="92"
                className='w-75'
              />
            </Link>
            <a onClick={logout} className="d-flex flex-column">
              <img
                alt='Logo'
                style={{ width: '45px' }}
                src={toAbsoluteUrl('/media/imgs/signoff.svg')}
              />
            </a>
          </div>
          <span className="border-menu"></span>

          <div className='d-flex px-25 py-5 position-relative align-items-center'>
            <div style={{ maxWidth: '35%' }}>
              {menu.avatar_image ? <img src={toAbsoluteUrl(`${menu.avatar_image}`)} alt='metronic' width={60} className="mw-100 rounded" />
                :
                <div style={{ width: '50px', height: '50px', borderRadius: '6px', background: '#28283B', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <p style={{ fontSize: '1.8rem', margin: '0', color: '#5D5FEF', fontWeight: 'bold' }}>
                    {menu.name && menu.name.split(' ').length > 1 ? `${menu.name?.split(' ')[0]?.substring(0, 1)?.toUpperCase()}${menu.name?.split(' ')[menu.name.split(' ').length - 1]?.substring(0, 1)?.toUpperCase()}`
                      : `${menu.name?.split(' ')[0]?.substring(0, 1)?.toUpperCase()}`}
                  </p>
                </div>
              }
            </div>

            <div className='ms-5'>
              <h3 className='text-light mb-0 fw-light'>
                {
                  menu.name && menu.name.split(' ').length > 1 ?
                    `${menu.name?.split(' ')[0]} ${menu.name?.split(' ')[menu.name.split(' ').length - 1]}`
                    :
                    `${menu.name?.split(' ')[0]}`
                }
              </h3>
            </div>
          </div>
          <span className="border-menu"></span>
          <div className='d-flex justify-content-between align-items-center px-7 py-5'>
            <span className='text-uppercase text-violeta-300 fw-bolder'>Saldo disponível</span>
          </div>
          <div className='px-7 mb-7 d-flex'>
            <input type="text" className={`input-menu ${eye ? '' : 'active'}`} value={
              `${balance !== null ? Money(balance) : 'Não disponível'}`
            } onChange={() => false} />
            <button className={`btn-menu ${eye ? '' : 'active'} `} onClick={() => setEye(!eye)}>
              {eye ? <BsEye /> : <BsEyeSlash />}
            </button>

          </div>

          <span className="border-menu"></span>
          <span className='text-uppercase text-violeta-300 fw-bolder pt-5 px-7'>menu navegação</span>
          <div className='aside-menu flex-column-fluid px-7 mb-5'>
            <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
          </div>

        </div>
      }
    </>
  )
}

export { AsideDefault }