import React, { ReactNode } from "react";

interface props {
  children: ReactNode;
}

const ContanerCard = ({ children }: props) => {
  const [disabled, setDisabled] = React.useState(false)
  React.useLayoutEffect(()=>{
    var queryparams = window.location.search.split('?')[1]
    var params = queryparams?.split('&')
    
    var pair = null
    var data = []
    params?.forEach(function(d) {
      pair = d.split('=')
      data.push({ key: pair[0], value: pair[1] })
      if (pair[0] === 'tokenNewFront') {
        setDisabled(true)
        }
      }
    )
  },[disabled])

  return (
    <>
      <div className={`${!disabled ? 'container-metronic heading-bg': 'authTokenUrl'} position-relative`}></div>

      <div
        className={`${!disabled ? 'container-metronic px-5 px-xxl-20': 'authTokenUrl'} position-relative`}
        style={{ bottom: "140px" }}
      >
        <section className="card">
          <div className="card-body">{children}</div>
        </section>
      </div>
    </>
  );
};

export default ContanerCard;
