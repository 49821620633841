import { createContext } from "react";

import { IBannerContext } from "./types";

const BannerContext = createContext<IBannerContext>({
  banner: "",
  handleBannerChange: (url: string) => {},
});

export { BannerContext };

