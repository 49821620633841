import { useAuth } from 'app/modules/auth'

const BrandId = () => {
  const { logout } = useAuth()
  const token = JSON.parse(localStorage.getItem('kt-auth-react-v'))

  if (!token || token.brandId === null || token.brandId === '') {
    logout()
    window?.location.reload()
  } else {
    return token.brandId
  }
}

export default BrandId
