/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Trigger } from "../Trigger";
import { ICalcInputProps, CalcInputRef } from "./types";
import { useMasks } from "app/modules/hooks/useMask";

const CalcInput = forwardRef<CalcInputRef, ICalcInputProps>(({
  touched,
  error,
  label,
  badge = false,
  disabled = false,
  description_value,
  description_rate,
  result = 0,
  mask,
  ...props
}, ref): JSX.Element => {
  const inputReference = useRef<HTMLInputElement>(null);
  const [didFocus, setDidFocus] = useState<boolean>(false);
  const [value, setValue] = useState("");

  const { cpf, cep, phone, cnpj, currency } = useMasks();

  const showFeedback = didFocus && touched;
  const classNameControl = !touched
    ? ""
    : showFeedback && error
      ? "is-valid"
      : "is-invalid";

  useImperativeHandle(ref, () => ({
    handleFocus() {
      console.log(touched);
      if (inputReference.current) inputReference.current.focus();
    },
  }));

  const handleChangeEvent = useCallback((e) => {
    setValue(e.target.value);
    if (mask === 'cpf') {
      cpf(e);
    }
    if (mask === 'cep') {
      cep(e);
    }
    if (mask === 'phone') {
      phone(e);
    }
    if (mask === 'cnpj') {
      cnpj(e);
    }
    if (mask === 'currency') {
      currency(e);
    }
  }, [value, mask]);

  return (
    <>
      <div className="row mb-5">
        <div className="col-sm-3">
          <article>
            <label className="mb-3 form-label">Valor a ser pago
              <Trigger description={description_value} />
            </label>

            <div className="input-group mb-3">
              <span className="input-group-text">R$</span>
              <input
                type="number"
                ref={inputReference}
                {...props}
                className={`form-control form-control-lg form-control-solid ${classNameControl}`}
                value={props.value || ""}
                onFocus={() => setDidFocus(true)}
                disabled={disabled}
                onInput={(e) => handleChangeEvent(e)}
                maxLength={5}
              />
              {showFeedback && error && (
                <small className="invalid-feedback">{error}</small>
              )}
            </div>
          </article>
        </div>
      </div>



    </>
  );
}
);
export { CalcInput };

