import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Trigger } from "../Trigger";
import { ITextFieldProps, TextFieldRef } from "./types";

const TextField = forwardRef<TextFieldRef, ITextFieldProps>(
  ({ touched, error, label, badge = false, trigger = false, maxLength, textTooltip, disabled = false, type = 'text', ...props }, ref): JSX.Element => {
    const inputReference = useRef<HTMLInputElement>(null);
    const [didFocus, setDidFocus] = useState<boolean>(false);

    const showFeedback = (didFocus) || touched;

    const classNameControl = !touched
      ? ""
      : showFeedback && error
        ? "is-invalid"
        : "is-valid";

    useImperativeHandle(ref, () => ({
      handleFocus() {
        if (inputReference.current) inputReference.current.focus();
      },
    }));
    return (
      <>
        {label !== undefined &&
          <>
            <label className="form-label">{label}</label>
            {trigger && <Trigger description={textTooltip} />}
            {badge && <span className="badge badge-blue-primary">Opcional</span>}
          </>
        }

        <input
          ref={inputReference}
          {...props}
          className={`form-control form-control-lg form-control-solid ${classNameControl}`}
          type={type}
          value={props?.value || ""}
          onFocus={() => setDidFocus(true)}
          disabled={disabled}
          maxLength={maxLength}
          onBlur={(e) => (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.trim()}
        />
        {showFeedback && error && (
          <small className="text-danger fw-bold d-block py-2">{error}</small>
        )}
      </>
    );
  }
);
export { TextField };
