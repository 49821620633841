import React, { useImperativeHandle, useRef, useState } from "react";

import { ITextAreaProps, TextAreaRef } from "./types";

const TextArea = React.forwardRef<TextAreaRef, ITextAreaProps>(
  (
    { touched, error, label, placeholder, maxLength, children, badge = false, ...props },
    ref
  ): JSX.Element => {
    const textareaReference = useRef<HTMLTextAreaElement>(null);
    const [didFocus, setDidFocus] = useState<boolean>(false);
    const showFeedback =
      (didFocus && props.value?.toString().trim().length < 3) || touched;

    const classNameControl = !touched
      ? ""
      : showFeedback && error
        ? "is-invalid"
        : "is-valid";

    useImperativeHandle(ref, () => ({
      handleFocus() {
        if (textareaReference.current) textareaReference.current.focus();
      },
    }));

    return (
      <>
        <label className="form-label">{label}
          {badge && <span className="badge badge-blue-primary">Opcional</span>}
        </label>
        <textarea
          ref={textareaReference}
          {...props}
          className={`form-control form-control-lg form-control-solid ${classNameControl}`}
          style={{ resize: "none", height: "9rem" }}
          placeholder={placeholder}
          value={props.value || ""}
          maxLength={maxLength}
          onFocus={() => setDidFocus(true)}
          onBlur={(e) => (e.target as HTMLTextAreaElement).value = (e.target as HTMLTextAreaElement).value.trim()}
        />
        <div className="d-flex justify-content-between">
          <small className="text-danger fw-bold d-block py-2">
            {showFeedback && error && <>{error}</>}
          </small>
          <p className="text-end text-gray-500 mt-3">
            {props.value?.toString().length || 0}/{maxLength ? maxLength : 3000} CARACTERES
          </p>
        </div>
      </>
    );
  }
);

export { TextArea };
