/* eslint-disable react-hooks/exhaustive-deps */
import React, { FormEvent, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import Styles from "./styles.module.scss";

import { Link, useParams } from "react-router-dom";
import MultiSelect from "multiselect-react-dropdown";
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import MoneyInput from "@rschpdr/react-money-input";
import InputMask from "react-input-mask";
import * as DateFns from "date-fns";
import clsx from "clsx";

import Token from "../../modules/hooks/Token/Token";
import ValideteError from "../../modules/hooks/ValideteError/ValideteError";
import { useAuth } from "../../modules/auth";
import { DateFormat } from "app/modules/hooks/DateFormat/DateFormat";

import { erroAlert, successAlert } from "app/components/ModalSwal/ModalSwal";
import { Radio, TextField, SubTitle, Trigger } from "app/components";
import { UploadImage } from "app/components/Inputs/UploadImage";
import Popup from "../Popup/Popup";

import { fromImport } from "app/pages/Challenge/Sales/pages/create/Sales.schema";
import { formSchema } from "./FormChallenge.schema.ts";

import {
  IntegrationImport,
  IntegrationNewChallenge,
  SelectedTagsData,
} from "./types";

import { ReactComponent as Download } from "../../assets/svg/general/download.svg";
import { EditRichText } from "../RichText/EditRichText";
import metabase from "app/modules/services/metabase";
import { toAbsoluteUrl } from "_metronic/helpers";
import { getConnectores } from "app/modules/auth/core/_requests";

const FormChallenge: React.FC = (): JSX.Element => {
  type RichTextContract = {
    blocks: Array<{
      key: string;
      text: string;
      type: any;
      depth: number;
      inlineStyleRanges: any[];
      entityRanges: any[];
      data: object;
    }>;
    entityMap: object;
  };

  const auth = Token();
  const { logout } = useAuth();
  const router = useParams();
  const [connectors, setConnectors] = useState({} as any);

  const params = router.id ? true : false;

  const [counterTitle, setCounterTitle] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [res, setRes] = useState(0);
  const [erro, setErro] = useState(false);
  const [imported, setImported] = useState(false);
  const [hasDueDate, setHasDueDate] = useState(false);
  const [banner, setBanner] = useState({
    filename: "",
    image: "",
  });
  const [brandTags, setBrandTags] = useState<SelectedTagsData[]>([]);
  const [selectedTags, setSelectedTags] = useState<Number[]>([]);

  const [ciclo, setCiclo] = useState<string>("30");
  // const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);

  const [toggleReward, setToggleReward] = useState<number>(2);
  const [toggleCupom, setToggleCupom] = useState<number>(3);
  const [toggleAvailability, setToggleAvailability] = useState<number>(0);
  const [divEntonation, setDivEntonation] = React.useState(false);
  const [divPublic, setDivPublic] = React.useState(false);
  const [entonation, setEntonation] = React.useState("Entonação");
  const [categoryPublic, setCategoryPublic] = React.useState("Público");
  const [optionsIA, setOptionsIA] = React.useState(false);
  const [openImageExample, setOpenImageExample] = React.useState(false);
  const [askIA, setAskIA] = React.useState({
    brand: "",
    title: "",
    segment: "",
    bio: "",
    typeChallenge: "",
  });
  const [askIALoading, setAskIALoading] = React.useState(false);
  const [betterLoading, setBetterLoading] = React.useState(false);
  const [interest, setInterest] = React.useState('');
  const [tippyMessage, setTippyMessage] = React.useState('');
  const [interestMessage, setInterestMessage] = React.useState('');
  const richTextMock = {
    blocks: [
      {
        key: "701rr",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  };

  const [descriptionData, setDescriptionData] =
    useState<RichTextContract>(richTextMock);
  const percentAmountMask = useCallback(
    (value: string) => value.replace(/(^0)|\D/g, "").slice(0, 2),
    []
  );

  const initialValues: IntegrationNewChallenge = {
    title: "",
    commissionMoney: 0,
    commissionPercentage: 0,
    dueDate: "",
    quantity: 0,
    discountType: "",
    discount: "",
    typecommission: "percent",
    description: "",
    prefix: "",
    minimumSubtotal: "",
    discountMoney: "",
    discountPercentage: "",
    image_cover: "",
    challengeAvailability: "",
    selectedTags: [],
    rewardDescription: null,
  };

  const initialValuesImport: IntegrationImport = {
    import: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    onSubmit: async (values: IntegrationNewChallenge) => {
      try {
        setLoading(true);

        const bannerData =
          banner.image !== ""
            ? { filename: banner.filename, data: banner.image }
            : null;

        const res = await axios
          .post("colab/create-challenge", {
            token: auth,
            externalId: formik_Import.values.import,
            title: values.title,
            description: values.description,
            active: 1,
            banner: bannerData,
            payment_days: Number(ciclo),
            rewardDescription: values.rewardDescription,
            comission: {
              type: values.typecommission,
              value:
                values.typecommission === "money"
                  ? values.commissionMoney
                  : values.commissionPercentage,
            },
            coupon: {
              expiration: DateFns.parse(
                values.dueDate,
                "dd/MM/yyyy",
                new Date()
              )
                .toISOString()
                .slice(0, 10),
              discount: {
                type: values.discountType,
                value:
                  toggleCupom === 1
                    ? values.discountMoney
                    : values.discountPercentage,
              },
              quantity: Number(values.quantity),
              prefix: values.prefix,
            },
            minimumSubtotal: values.minimumSubtotal,
            ambassadorsTags: selectedTags,
          })
          .then((d) => d.status)
          .catch((d) => d.response.status);
        setRes(res.status);
        if (res === 200 || res === 201) {
          window.analytics.track("Challenge Created", {
            nameOfChallenge: formik.values.title,
            type: "Sales",
            commissionValue:
              formik.values.typecommission === "value"
                ? formik.values.discountMoney
                : formik.values.discountPercentage,
            discountValue: formik.values.discount,
          });
          successAlert("Ebaa", "O desafio foi registrado com sucesso!");
        } else {
          erroAlert(
            "Opss",
            "Aconteceu um erro inesperado. Por favor, tente novamente."
          );
        }
      } catch (e) {
        if (ValideteError(e)) {
          logout();
        } else if (e.response.status === 500) {
          erroAlert(
            "Opss",
            "Aconteceu um erro inesperado. Por favor, tente novamente."
          );
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const formImport = async () => {
    try {
      setLoadingImport(true);
      const res = await axios.post("colab/import-challenge", {
        token: auth,
        externalId: formik_Import.values.import,
      });

      if(res.data.dueDate !== null && res.data.dueDate !== '') setHasDueDate(true);
      const data = res.data.dueDate ? DateFormat(res.data.dueDate) : "";
      formik.setValues({
        ...formik.values,
        ...res.data,
        dueDate: data,
      });
      setToggleCupom(res.data.discountType === "value" ? 1 : 2);
      setImported(true);
      setModal(false);
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      } else if (e.response.status === 400) {
        setErro(true);
      }
    } finally {
      setLoadingImport(false);
    }
  };

  const formik_Import = useFormik({
    initialValues: initialValuesImport,
    validationSchema: fromImport,
    onSubmit: formImport,
  });

  function CounterTitle(e: FormEvent<HTMLInputElement>) {
    const s = e.target as HTMLTextAreaElement;
    if (s.value.length === 0) {
      return setCounterTitle(s.value.length);
    }
    return setCounterTitle(s.value.length + 1);
  }

  async function handleGetBrandTagsData() {
    const payload: any = localStorage.getItem("kt-auth-react-v");

    let parsedPayload;
    if (payload) parsedPayload = JSON.parse(payload);

    const response = await axios.post("colab/list-brand-ambassador-tags", {
      accessToken: parsedPayload.api_token,
      brandId: parsedPayload.brandId,
    });

    setBrandTags(response.data);
  }

  function handleAddTagToChallenge(selectedItem) {
    const addTagToList = selectedItem.map((d) => d.id).concat();

    setSelectedTags(addTagToList);
  }

  function handleRemoveTagFromChallenge(selectedList, removedItem) {
    const filterTagRemove = selectedTags.filter(function (item) {
      return item !== removedItem.id;
    });

    setSelectedTags(filterTagRemove);
  }

  const handleGetConnectors = async () => {
    const token = JSON.parse(localStorage.getItem('kt-auth-react-v'))
    const list = await getConnectores(token?.api_token);
    setConnectors({
      shopify: list.data.data.find((obj) => obj.title === "shopify")?.connected,
      tray: list.data.data.find((obj) => obj.title === "tray")?.connected
    });
  }

  useEffect(() => {
    handleGetBrandTagsData();
    handleGetConnectors();
  }, []);

  const getBrandInfo = async () => {
    try {
      const getInfoBrand = await axios.post("colab-list-info", {
        brandToken: auth,
      });
      const getCategoryBrand = await axios.post("colab/interests", {
        brandToken: auth,
      });
      const categoryBrand = getCategoryBrand.data.find(
        (obj) => obj.id === getInfoBrand.data?.categoryId
      );
      setAskIA((prevState) => ({
        ...prevState,
        brand: getInfoBrand.data?.name,
        bio: getInfoBrand.data?.aboutMe,
        typeChallenge: "vendas",
        segment: categoryBrand?.name,
      }));
    } catch (e) {
      console.log("ERROR REQUEST GET INFO BRAND");
    }
  };

  async function handleAskIA() {
    let description: RichTextContract;

    try {
      setAskIALoading(true);

      const textIA = await metabase.post(
        "generative-ai/description-challenge",
        {
          ...askIA,
        }
      );

      description = {
        ...richTextMock,
        blocks: [
          { ...richTextMock.blocks[0], text: textIA.data?.data?.response },
        ],
      };

      setDescriptionData(description);
    } catch (error) {
    } finally {
      setAskIALoading(false);
    }
  }
  async function handleBetterTextIA() {
    setBetterLoading(true);
    let description: RichTextContract;

    try {
      setAskIALoading(true);

      const textIA = await metabase.post("generative-ai/improve-challenge", {
        targetAudience: categoryPublic,
        intonation: entonation,
        description: formik?.values?.description?.blocks?.[0]?.text,
      });

      description = {
        ...richTextMock,
        blocks: [
          { ...richTextMock.blocks[0], text: textIA.data?.data?.response },
        ],
      };

      setDescriptionData(description);
    } catch (error) {
    } finally {
      setBetterLoading(false);
    }
  }

  useEffect(() => {
    getBrandInfo();
  }, []);

  useEffect(() => {
    setAskIA((prevState) => ({
      ...prevState,
      title: formik?.values?.title,
    }));

    formik.setFieldValue("description", richTextMock);
  }, [formik?.values?.title]);

  useEffect(() => {
    if (formik?.values?.description?.blocks?.[0]?.text.length > 1) {
      setOptionsIA(true);
    } else {
      setOptionsIA(false);
    }
  }, [formik?.values?.description?.blocks?.[0]?.text]);

  useEffect(() => {
    formik.setFieldValue("description", descriptionData);
  }, [descriptionData]);

  useEffect(() => {
    if (formik?.values?.commissionPercentage > 10 && formik?.values?.commissionPercentage <= 14) {
      setInterest('lower')
      setTippyMessage('Menor interesse: Ofereça 10% até 14% de recompensa aos criadores. ')
      setInterestMessage('Possivelmente menos interesses pelos criadores')
    } else if (formik?.values?.commissionPercentage > 14 && formik?.values?.commissionPercentage <= 17) {
      setInterest('medium')
      setTippyMessage('Interesse moderado: Ofereça 15% a 17% de recompensa aos criadores.')
      setInterestMessage('Possivelmente interesse moderado pelos criadores')
    } else if (formik?.values?.commissionPercentage > 17) {
      setInterest('high')
      setTippyMessage('Maior interesse: Ofereça mais de 18% de recompensa aos criadores.')
      setInterestMessage('Possivelmente maior interesse pelos criadores')
    } else {
      setInterest('')
      setTippyMessage('')
      setInterestMessage('')
    }
  }, [formik?.values?.commissionPercentage])

  return (
    <>
      <Popup show={modal} setOpen={setModal}>
        <Modal.Header closeButton className="border-0 pb-0" />
        <Modal.Body className="px-20 pb-10 text-center">
          {connectors?.shopify ? (
            <>
              <h2 className="mx-5 pb-5">Importar regra de desconto</h2>
              <p className="text-gray-600 mb-7">
                Por favor, informe a URL com o ID de desconto já criado no seu{" "}
                <span className="link-primary text-decoration-underline cursor-pointer" onClick={() => setOpenImageExample(!openImageExample)}>painel da {connectors.shopify ? "Shopify" : "Tray"}</span>
              </p>
              {openImageExample && (
                <img src={toAbsoluteUrl('/media/image-example.png')} alt="image_example" />
              )}
              <form onSubmit={formik_Import.handleSubmit} style={{ marginTop: '1rem' }}>
                <TextField
                  placeholder="Ex: https://admin.shopify.com/store..."
                  touched={formik_Import.touched.import}
                  error={formik_Import.errors.import}
                  onBlur={formik_Import.handleBlur}
                  onChange={formik_Import.handleChange}
                  {...formik_Import.getFieldProps("import")}
                />
                {erro && (
                  <small className="text-center text-danger d-block mt-3">
                    Algo deu errado, tente novamente.
                  </small>
                )}
                <button
                  className="btn btn-info mt-10"
                  type="submit"
                  disabled={!formik_Import.isValid}
                >
                  Importar
                  {loadingImport && (
                    <Spinner
                      animation="grow"
                      variant="light"
                      size="sm"
                      className="ms-2"
                    />
                  )}
                </button>
              </form>
            </>
          ) : (
            <>
              <h2 className="mx-5 pb-5">Importar regra de desconto</h2>
              <p className="text-gray-600 mb-7">
                Por favor, informe o ID do desconto já criado no seu painel da{" "}
                {connectors.shopify ? "Shopify" : "Tray"}:
              </p>
              <form onSubmit={formik_Import.handleSubmit}>
                <TextField
                  placeholder="Ex: 1078878011591"
                  touched={formik_Import.touched.import}
                  error={formik_Import.errors.import}
                  onBlur={formik_Import.handleBlur}
                  onChange={formik_Import.handleChange}
                  {...formik_Import.getFieldProps("import")}
                />
                {erro && (
                  <small className="text-center text-danger d-block mt-3">
                    Algo deu errado, tente novamente.
                  </small>
                )}
                <button
                  className="btn btn-info mt-10"
                  type="submit"
                  disabled={!formik_Import.isValid}
                >
                  Importar
                  {loadingImport && (
                    <Spinner
                      animation="grow"
                      variant="light"
                      size="sm"
                      className="ms-2"
                    />
                  )}
                </button>
              </form>
            </>
          )}
        </Modal.Body>
      </Popup>

      <form onSubmit={formik.handleSubmit}>
        <div className="d-flex justify-content-between">
          <h1 className="mb-10">Sobre o Desafio</h1>
          {(connectors.shopify || connectors.tray) && (
            <button
              className="btn btn-outline btn-outline-primary"
              onClick={(e) => {
                e.preventDefault();
                setModal(true);
              }}
            >
              <Download />
              Importar da {connectors.shopify ? "Shopify" : "Tray"}
            </button>
          )}
        </div>

        <SubTitle
          title="Título do desafio"
          description="Será exibido na página do desafio para qualquer embaixador dentro da BrandLovrs"
          hasPopover="Chamada principal que aparecerá no Desafio da sua marca. Insira palavras-chave que chamem atenção."
        />
        <div className="mt-3">
          <TextField
            autoComplete="off"
            name="title"
            disabled={params && true}
            placeholder="Ex: Promo de Natal da BandLovrs"
            onKeyDown={(e) => CounterTitle(e)}
            touched={formik.touched.title}
            error={formik.errors.title}
            onBlur={formik.handleBlur}
            maxLength={56}
            onChange={formik.handleChange}
            {...formik.getFieldProps("title")}
          />

          <p className="text-end text-gray-400 mt-3 mb-10">
            {counterTitle <= 56 ? counterTitle : 56}/56 CARACTERES
          </p>
        </div>

        <SubTitle
          title="Descrição"
          description="Será exibido na página do desafio para qualquer embaixador dentro da BrandLovrs"
          hasPopover="Oriente de forma clara sobre as regras e o que os participantes devem fazer ou falar para cumprir o desafio."
        />

        {!optionsIA ? (
          !askIALoading ? (
            <button
              className={Styles.buttonIA}
              type="button"
              onClick={() => handleAskIA()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-magic"
                viewBox="0 0 16 16"
              >
                <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0v1.829Zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707L14 2.707ZM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707L7.293 4Zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1h1.829Zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1h1.829ZM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707L13.293 10ZM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0v1.829Zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0L8.354 9.06Z" />
              </svg>
              Criar descrição com IA
            </button>
          ) : (
            <button className={Styles.buttonIA} type="button" disabled>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-magic"
                viewBox="0 0 16 16"
              >
                <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0v1.829Zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707L14 2.707ZM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707L7.293 4Zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1h1.829Zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1h1.829ZM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707L13.293 10ZM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0v1.829Zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0L8.354 9.06Z" />
              </svg>
              Aguarde
              <span
                className="spinner-grow"
                style={{ width: 4, height: 4 }}
                role="status"
                aria-hidden="true"
              ></span>
              <span
                className="spinner-grow"
                style={{ width: 4, height: 4 }}
                role="status"
                aria-hidden="true"
              ></span>
              <span
                className="spinner-grow"
                style={{ width: 4, height: 4 }}
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          )
        ) : (
          <div className={Styles.containerIA}>
            <div
              onMouseEnter={() => setDivEntonation(true)}
              onMouseLeave={() => setDivEntonation(false)}
            >
              <button type="button" className={Styles.menuDropIA}>
                {entonation}
              </button>
              {divEntonation && (
                <div className={Styles.menuDropItensIA}>
                  <div className={Styles.input}>
                    <input
                      type="radio"
                      id="Profissional"
                      name="entonation"
                      value="Profissional"
                      checked={entonation === "Profissional"}
                      onChange={(e) => setEntonation(e.target.value)}
                    />
                    <label htmlFor="Profissional" className={Styles.label}>
                      Profissional
                    </label>
                  </div>

                  <div className={Styles.input}>
                    <input
                      type="radio"
                      id="Casual"
                      name="entonation"
                      value="Casual"
                      checked={entonation === "Casual"}
                      onChange={(e) => setEntonation(e.target.value)}
                    />
                    <label className={Styles.label} htmlFor="Casual">
                      Casual
                    </label>
                  </div>
                  <div className={Styles.input}>
                    <input
                      type="radio"
                      id="Direto"
                      name="entonation"
                      value="Direto"
                      checked={entonation === "Direto"}
                      onChange={(e) => setEntonation(e.target.value)}
                    />
                    <label className={Styles.label} htmlFor="Direto">
                      Direto
                    </label>
                  </div>
                  <div className={Styles.input}>
                    <input
                      type="radio"
                      id="Confiante"
                      name="entonation"
                      value="Confiante"
                      checked={entonation === "Confiante"}
                      onChange={(e) => setEntonation(e.target.value)}
                    />
                    <label className={Styles.label} htmlFor="Confiante">
                      Confiante
                    </label>
                  </div>

                  <div className={Styles.input}>
                    <input
                      type="radio"
                      id="Amigavel"
                      name="entonation"
                      value="Amigável"
                      checked={entonation === "Amigável"}
                      onChange={(e) => setEntonation(e.target.value)}
                    />
                    <label className={Styles.label} htmlFor="Amigavel">
                      Amigável
                    </label>
                  </div>
                </div>
              )}
            </div>

            <div
              onMouseEnter={() => setDivPublic(true)}
              onMouseLeave={() => setDivPublic(false)}
            >
              <button type="button" className={Styles.menuDropIA}>
                {categoryPublic}
              </button>
              {divPublic && (
                <div className={Styles.menuDropItensIA}>
                  <div className={Styles.input}>
                    <input
                      type="radio"
                      id="Jovens"
                      name="public"
                      value="Jovens"
                      checked={categoryPublic === "Jovens"}
                      onChange={(e) => setCategoryPublic(e.target.value)}
                    />
                    <label className={Styles.label} htmlFor="Jovens">
                      Jovens
                    </label>
                  </div>
                  <div className={Styles.input}>
                    <input
                      type="radio"
                      id="Homens"
                      name="public"
                      value="Homens"
                      checked={categoryPublic === "Homens"}
                      onChange={(e) => setCategoryPublic(e.target.value)}
                    />
                    <label className={Styles.label} htmlFor="Homens">
                      Homens
                    </label>
                  </div>
                  <div className={Styles.input}>
                    <input
                      type="radio"
                      id="Mulheres"
                      name="public"
                      value="Mulheres"
                      checked={categoryPublic === "Mulheres"}
                      onChange={(e) => setCategoryPublic(e.target.value)}
                    />
                    <label className={Styles.label} htmlFor="Mulheres">
                      Mulheres
                    </label>
                  </div>
                </div>
              )}
            </div>

            {betterLoading ? (
              <button className={Styles.buttonIA} type="button" disabled>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-magic"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0v1.829Zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707L14 2.707ZM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707L7.293 4Zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1h1.829Zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1h1.829ZM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707L13.293 10ZM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0v1.829Zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0L8.354 9.06Z" />
                </svg>
                Aguarde
                <span
                  className="spinner-grow"
                  style={{ width: 4, height: 4 }}
                  role="status"
                  aria-hidden="true"
                ></span>
                <span
                  className="spinner-grow"
                  style={{ width: 4, height: 4 }}
                  role="status"
                  aria-hidden="true"
                ></span>
                <span
                  className="spinner-grow"
                  style={{ width: 4, height: 4 }}
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            ) : (
              <button
                type="button"
                className={Styles.buttonIA}
                onClick={() => handleBetterTextIA()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-magic"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0v1.829Zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707L14 2.707ZM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707L7.293 4Zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1h1.829Zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1h1.829ZM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707L13.293 10ZM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0v1.829Zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0L8.354 9.06Z" />
                </svg>
                Melhorar descrição
              </button>
            )}
          </div>
        )}

        <div className="mt-3">
          <EditRichText
            rawData={descriptionData}
            placeholder="Ex: Crie um reels bem criativo apresentando a loja e convidando seus seguidores do Instagram a conhecer e aproveitar a promoção de Natal da BrandLovrs."
            name="description"
            id="description"
            {...formik.getFieldProps("description")}
            setFieldValue={(val) => formik.setFieldValue("description", val)}
            touched={formik.touched.description}
            error={formik.errors.description}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>

        <UploadImage
          name="image_cover"
          label_title="Banner do Desafio"
          label_subtitle="Defina uma imagem exclusiva para ilustrar este desafio."
          badge_title="Opcional"
          banner_title="Carregar arquivo..."
          banner_subtitle="Seu banner precisa ser no formato JPG ou PNG, ter até 1 MB e dimensão máxima de 1820 x 800."
          {...formik.getFieldProps("image_cover")}
          touched={formik.touched.image_cover}
          error={formik.errors.image_cover}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          setBaseValue={setBanner}
        />

        <hr className="border-gray-600 mt-5 mb-10" />

        <h2 className="mb-3">Recompensa</h2>
        <p className="text-gray-600 font-roboto mb-8">
          Escolha qual será a recompensa dos participantes que cumprirem o
          desafio.
        </p>

        {/* Comissionamento */}

        <div className="grid-radio mb-10">
          {/* <Radio
            label={"Valor fixo por venda"}
            id={"l3"}
            disabled={params && true}
            onClick={() => setToggleReward(1)}
            {...formik.getFieldProps("typecommission")}
            name="typecommission"
            value="money"
            checked={toggleReward === 1}
          /> */}

          <Radio
            label={"% do total da venda"}
            id={"l2"}
            disabled={params && true}
            onClick={() => setToggleReward(2)}
            {...formik.getFieldProps("typecommission")}
            name="typecommission"
            value="percent"
            checked={toggleReward === 2}
          />

          {/* <Radio
            label={"Sem recompensa"}
            id={"l1"}
            disabled={params && true}
            onClick={() => {
              setToggleReward(3);
              formik.setFieldValue("commissionMoney", 0);
            }}
            {...formik.getFieldProps("typecommission")}
            name="typecommission"
            value="money"
            checked={toggleReward === 3}
          /> */}
        </div>

        {toggleReward === 1 && (
          <div className="row mb-5">
            <div className="col-sm-3">
              <article>
                <label className="mb-3 form-label">
                  Valor a ser pago
                  <Trigger
                    description={
                      "Valor que a marca pagará como recompensa para cada desafio cumprido."
                    }
                  />
                </label>

                <div className="input-group mb-3">
                  <MoneyInput
                    currencyConfig={{
                      locale: "pt-BR",
                      currencyCode: "BRL",
                      currencyDisplay: "symbol",
                    }}
                    autoComplete="off"
                    name="commissionMoney"
                    placeholder="Ex:10"
                    max={9999999999}
                    min={1}
                    disabled={params && true}
                    className={clsx(
                      "form-control form-control-lg form-control-solid",
                      {
                        "is-invalid":
                          formik.touched.commissionMoney &&
                          formik.errors.commissionMoney,
                      },
                      {
                        "is-valid":
                          formik.touched.commissionMoney &&
                          !formik.errors.commissionMoney,
                      }
                    )}
                    {...formik.getFieldProps("commissionMoney")}
                  />
                </div>

                {formik.touched.commissionMoney &&
                  formik.errors.commissionMoney && (
                    <span className="text-danger fw-bold d-block py-2">
                      {formik.errors.commissionMoney}
                    </span>
                  )}
              </article>
              {formik.touched.commissionMoney &&
                formik.errors.commissionMoney && (
                  <span className="text-danger fw-bold d-block py-2">
                    {formik.errors.commissionMoney}
                  </span>
                )}
            </div>
          </div>
        )}

        {toggleReward === 2 && (
          <div className="row mb-5">
            <div className="col-sm-3">
              <article>
                <label className="mb-3 form-label">
                  Valor a ser pago
                  <Trigger description="Valor que a marca pagará como recompensa para cada desafio cumprido." />
                </label>
                <div className="input-group mb-3">
                  <input
                    placeholder="Ex:10"
                    type="text"
                    autoComplete="off"
                    disabled={params && true}
                    className={clsx(
                      "form-control form-control-lg form-control-solid",
                      {
                        "is-invalid":
                          formik.touched.commissionPercentage &&
                          formik.errors.commissionPercentage,
                      },
                      {
                        "is-valid":
                          formik.touched.commissionPercentage &&
                          !formik.errors.commissionPercentage,
                      }
                    )}
                    {...formik.getFieldProps("commissionPercentage")}
                    onChange={({ target }) => {
                      let value = percentAmountMask(target.value);
                      formik.setFieldValue(
                        "commissionPercentage",
                        value.length ? value : 0
                      );
                    }}
                  />
                  <span className="input-group-text" id="basic-addon1">
                    %
                  </span>
                  {interestMessage && (<div className={Styles.interestWrapper}>
                    <div className={Styles.dot} style={interest === 'lower' ? { background: '#FC3001' } : interest === 'medium' ? { background: '#F09205' } : { background: '#30B043' }}></div>
                    <OverlayTrigger placement="top"
                      delay={{ show: 250, hide: 500 }} overlay={<Tooltip>{tippyMessage}</Tooltip>}>
                      <p className={Styles.interest}>{interestMessage}</p>
                    </OverlayTrigger>
                  </div>)}
                </div>
                {formik.touched.commissionPercentage &&
                  formik.errors.commissionPercentage && (
                    <span className="text-danger fw-bold d-block py-2">
                      {formik.errors.commissionPercentage}
                    </span>
                  )}
              </article>
            </div>
          </div>
        )}

        {toggleReward === 3 && (
          <div className="row mb-5">
            <div className="col-sm-3">
              <article>
                <label className="mb-3 form-label">
                  Descreva a recompensa
                  <Trigger description="Descreva a recompensa que deseja oferecer" />
                  <span className="badge badge-blue-primary">Opcional</span>
                </label>
                <div className="input-group mb-3">
                  <TextField
                    placeholder="Descreva a recompensa"
                    maxLength={12}
                    touched={formik.touched.rewardDescription}
                    error={formik.errors.rewardDescription}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    {...formik.getFieldProps("rewardDescription")}
                  />
                </div>
                {formik.touched.rewardDescription &&
                  formik.errors.rewardDescription && (
                    <span className="text-danger fw-bold d-block py-2">
                      {formik.errors.rewardDescription}
                    </span>
                  )}
              </article>
            </div>
          </div>
        )}

        {/* Prazo para pagamento */}

        <h3>
          Prazo para pagamento
          <Trigger
            description={
              "Tempo necessário para confirmar as vendas e liberar o pagamento para seus embaixadores."
            }
          />
        </h3>
        <p className="text-gray-600 font-roboto mb-8">
          Prazo para confirmar que o desafio foi cumprido e liberar a
          recompensa.
        </p>
        <div className="d-flex mt-3">
          <div className="d-flex">
            <input
              type="radio"
              id="30"
              name="payment_days"
              value="30"
              className="d-none"
              disabled={params && true}
              defaultChecked
            />
            <label
              className="btn-ciclo cursor-pointer"
              htmlFor="30"
              onClick={() => setCiclo("30")}
            >
              30 dias
            </label>
          </div>
          {/* <div className="d-flex">
            <input
              type="radio"
              id="60"
              name="payment_days"
              value="60"
              className="d-none"
              disabled={params && true}
            />
            <label
              className="btn-ciclo cursor-pointer"
              htmlFor="60"
              onClick={() => setCiclo("60")}
            >
              60 dias
            </label>
          </div>
          <div className="d-flex">
            <input
              type="radio"
              id="90"
              name="payment_days"
              value="90"
              className="d-none"
              disabled={params && true}
            />
            <label
              className="btn-ciclo cursor-pointer"
              htmlFor="90"
              onClick={() => setCiclo("90")}
            >
              90 dias
            </label>
          </div>
          <div className="d-flex">
            <input
              type="radio"
              id="120"
              name="payment_days"
              value="120"
              className="d-none"
              disabled={params && true}
            />
            <label
              className="btn-ciclo cursor-pointer"
              htmlFor="120"
              onClick={() => setCiclo("120")}
            >
              120 dias
            </label>
          </div>
          <div className="d-flex">
            <input
              type="radio"
              name="payment_days"
              value={ciclo}
              className="d-none"
              disabled={params && true}
            />
            <label
              className={`btn-ciclo cursor-pointer ${
                open ? "d-none" : "d-block"
              }`}
              htmlFor="120"
              onClick={() => setOpen(true)}
            >
              Personalizado
            </label>
            <div
              className={`d-flex align-items-end ${
                open ? "d-block" : "d-none"
              }`}
            >
              <input
                maxLength={3}
                type="number"
                min={1}
                className={`form-ciclo`}
                disabled={params && true}
                onChange={(e) => setCiclo(e.target.value)}
              />
              <span className={`text-blue`}>dias</span>
            </div>
          </div> */}
        </div>

        <hr className="border-gray-600 my-10" />

        <h2 className="mb-3">Cupom de desconto</h2>
        <p className="text-gray-600 font-roboto mb-8">
          Configure o cupom de desconto que será divulgado para os compradores.
        </p>
        <div className="grid-radio-type mb-10">
          <Radio
            label={"Desconto fixo em R$"}
            id={"l4"}
            disabled={(params && true) || imported}
            onClick={() => setToggleCupom(1)}
            {...formik.getFieldProps("discountType")}
            value="value"
            name="discountType"
            checked={toggleCupom === 1}
          />
          <Radio
            label={"% de desconto"}
            id={"l5"}
            disabled={(params && true) || imported}
            onClick={() => setToggleCupom(2)}
            {...formik.getFieldProps("discountType")}
            value="percent"
            name="discountType"
            checked={toggleCupom === 2}
          />
        </div>

        {toggleCupom === 1 && (
          <div className="row mb-5">
            <div className="col-sm-3">
              <article>
                <label className="mb-3 form-label">Desconto por cupom</label>

                <div className="input-group mb-3">
                  <MoneyInput
                    currencyConfig={{
                      locale: "pt-BR",
                      currencyCode: "BRL",
                      currencyDisplay: "symbol",
                    }}
                    autoComplete="off"
                    name="discountMoney"
                    placeholder="Ex:10"
                    max={9999999999}
                    min={1}
                    disabled={(params && true) || imported}
                    className={clsx(
                      "form-control form-control-lg form-control-solid",
                      {
                        "is-invalid":
                          formik.touched.discountMoney &&
                          formik.errors.discountMoney,
                      },
                      {
                        "is-valid":
                          formik.touched.discountMoney &&
                          !formik.errors.discountMoney,
                      },
                      { disabled: (params && true) || imported }
                    )}
                    {...formik.getFieldProps("discountMoney")}
                  />
                </div>

                {formik.touched.discountMoney &&
                  formik.errors.discountMoney && (
                    <span className="text-danger fw-bold d-block py-2">
                      {formik.errors.discountMoney}
                    </span>
                  )}
              </article>
            </div>
          </div>
        )}

        {toggleCupom === 2 && (
          <div className="row mb-5">
            <div className="col-sm-3">
              <article>
                <label className="mb-3 form-label">Desconto por cupom</label>
                <div className="input-group mb-3">
                  <input
                    placeholder="Ex:10"
                    type="number"
                    max={99}
                    min={1}
                    autoComplete="off"
                    name="discountPercentage"
                    disabled={(params && true) || imported}
                    className={clsx(
                      "form-control form-control-lg form-control-solid",
                      {
                        "is-invalid":
                          formik.touched.discountPercentage &&
                          formik.errors.discountPercentage,
                      },
                      {
                        "is-valid":
                          formik.touched.discountPercentage &&
                          !formik.errors.discountPercentage,
                      },
                      { disabled: (params && true) || imported }
                    )}
                    {...formik.getFieldProps("discountPercentage")}
                    onChange={({ target }) => {
                      let value = percentAmountMask(target.value);
                      formik.setFieldValue(
                        "discountPercentage",
                        value.length ? value : 0
                      );
                    }}
                  />
                  <span className="input-group-text" id="basic-addon1">
                    %
                  </span>
                </div>
                {formik.touched.discountPercentage &&
                  formik.errors.discountPercentage && (
                    <span className="text-danger fw-bold d-block py-2">
                      {formik.errors.discountPercentage}
                    </span>
                  )}
              </article>
            </div>
          </div>
        )}

        <div className="row mb-10">
          <div className="col-md-6">
            <article>
              <label className="mb-3 form-label">
                Pedido mínimo
                <Trigger description="Valor mínimo do pedido para permitir o uso do cupom." />
                <span className="badge badge-blue-primary">Opcional</span>
              </label>

              <div className="input-group mb-3">
                <MoneyInput
                  currencyConfig={{
                    locale: "pt-BR",
                    currencyCode: "BRL",
                    currencyDisplay: "symbol",
                  }}
                  autoComplete="off"
                  name="minimumSubtotal"
                  placeholder="Ex:10"
                  max={9999999999}
                  min={1}
                  disabled={(params && true) || imported}
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    {
                      "is-invalid":
                        formik.touched.minimumSubtotal &&
                        formik.errors.minimumSubtotal,
                    },
                    {
                      "is-valid":
                        formik.touched.minimumSubtotal &&
                        !formik.errors.minimumSubtotal,
                    },
                    { disabled: (params && true) || imported }
                  )}
                  {...formik.getFieldProps("minimumSubtotal")}
                />
              </div>

              {formik.touched.minimumSubtotal &&
                formik.errors.minimumSubtotal && (
                  <span className="text-danger fw-bold d-block py-2">
                    {formik.errors.minimumSubtotal}
                  </span>
                )}
            </article>
          </div>

          <div className="col-md-6">
            <label className="mb-3 form-label">Data de Validade</label>
            <InputMask
              mask="99/99/9999"
              placeholder="DD/MM/AAAA"
              disabled={(params && true) || (imported && hasDueDate)}
              autoComplete="off"
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid": formik.touched.dueDate && formik.errors.dueDate,
                },
                { "is-valid": formik.touched.dueDate && !formik.errors.dueDate }
              )}
              {...formik.getFieldProps("dueDate")}
            />
            {formik.touched.dueDate && formik.errors.dueDate && (
              <span className="text-danger fw-bold d-block py-2">
                {formik.errors.dueDate}
              </span>
            )}
          </div>
        </div>

        <div className="row mb-10">
          <div className="col-md-6">
            <article>
              <label className="mb-3 form-label">
                Prefixo do Cupom
                <Trigger description="Código padrão que aparecerá no início de todos os cupons gerados neste desafio." />
                <span className="badge badge-blue-primary">Obrigatório</span>
              </label>
              <input
                type="text"
                placeholder="Ex: LOVR"
                maxLength={5}
                autoComplete="off"
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid": formik.touched.prefix && formik.errors.prefix,
                  },
                  {
                    "is-valid": formik.touched.prefix && !formik.errors.prefix,
                  },
                  { disabled: (params && true) }
                )}
                onInput={(e) =>
                  ((e.target as HTMLInputElement).value = (
                    e.target as HTMLInputElement
                  ).value
                    .replace(/[^a-zA-Z0-9]/g, "")
                    .replace(/\s/g, ""))
                }
                {...formik.getFieldProps("prefix")}
              />
            </article>
            <small className="text-gray-500">
              Máximo de 5 caracteres, e sem usar caracteres especiais.
            </small>
            {formik.touched.prefix && formik.errors.prefix && (
              <span className="text-danger fw-bold d-block py-2">
                {formik.errors.prefix}
              </span>
            )}
          </div>
        </div>

        <hr className="border-gray-600 my-10" />

        <h2 className="mb-3">Público Alvo</h2>
        <p className="text-gray-600 font-roboto mb-8">
          Aqui você escolhe se seu desafio será exibido para todos ou só para
          aqueles que você marcou com as suas tags.
        </p>

        <div className="grid-radio-type mb-10">
          <Radio
            label={"Exibir para todos"}
            id={"challenge_availability_off"}
            disabled={(params && true) || imported}
            onClick={() => {
              setToggleAvailability(0);
              setSelectedTags([]);
            }}
            {...formik.getFieldProps("challengeAvailability")}
            name="challengeAvailability"
            checked={toggleAvailability === 0}
          />

          <Radio
            label={"Segmentar por tags"}
            id={"challenge_availability_on"}
            disabled={(params && true) || imported}
            onClick={() => setToggleAvailability(1)}
            {...formik.getFieldProps("challengeAvailability")}
            name="challengeAvailability"
            checked={toggleAvailability === 1}
          />
        </div>

        {toggleAvailability === 1 && (
          <div className="row mb-5">
            <div className="col-md-6">
              <label className="mb-3  form-label">Tags escolhidas</label>

              <div className="input-group MultiSelect mb-3">
                <MultiSelect
                  {...formik.getFieldProps("selectedTags")}
                  options={brandTags}
                  displayValue="name"
                  isObject
                  placeholder="Selecione"
                  hidePlaceholder
                  emptyRecordMsg="Nenhuma opção disponível"
                  showArrow
                  onSelect={handleAddTagToChallenge}
                  onRemove={handleRemoveTagFromChallenge}
                  style={{
                    chips: {
                      backgroundColor: "#C9E8FF",
                      color: "#009EF6",
                    },
                    searchBox: {
                      border: "1px solid #e4e6ef",
                      backgroundColor: "#F3F6F9",
                      borderRadius: "0.475rem",
                      boxShadow: "inset 0 1px 2px rgb(0 0 0 / 8%)",
                      transition:
                        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                      position: "relative",
                      padding: "1rem 3rem 1rem 1rem",
                    },
                    inputField: {
                      MozPaddingStart: "calc(1rem - 3px)",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      lineHeight: "1.5",
                      color: "#5e6278",
                      appearance: "none",
                    },
                  }}
                />

                <span className="text-black-50 d-block py-2">
                  O desafio só vai aparecer para embaixadores categorizados com
                  alguma das tags selecionadas.
                </span>
              </div>
            </div>
          </div>
        )}

        <hr className="border-gray-600 my-10" />

        <div className="d-flex justify-content-between">
          <Link to="/dashboard/challenge">
            <span className="btn btn-secondary">Voltar</span>
          </Link>
          {res === 201 ? (
            <button className="btn btn-success" type="button">
              <span>Criado com sucesso</span>
            </button>
          ) : (
            <button className="btn btn-primary text-white" type="submit">
              <span className={`${loading && "pe-5"}`}>
                {" "}
                {loading ? "Criando" : " Criar desafio"}
              </span>
              {loading && (
                <Spinner animation="grow" variant="light" size="sm" />
              )}
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export { FormChallenge };
