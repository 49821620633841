/* eslint-disable import/no-anonymous-default-export */

import * as Yup from "yup";

export default [
  Yup.object({
    image: Yup.string()
      .required("Carregue uma foto para sua marca")
      .nullable(),
  }),
  Yup.object().shape({
    title: Yup.string()
      .required("Selecione a categoria da sua marca")
  }),
  Yup.object().shape({
    description: Yup.string()
      .required("Por favor, descreva brevemente sobre sua marca.")
  }),
]

