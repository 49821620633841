/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { erroAlert, successAlert } from 'app/components/ModalSwal/ModalSwal';
import Pages from '../../../components/Page/Pages';
import { TextField } from 'app/components';

import { resetPassword } from '../core/_requests';

const initialValues = {
  email: '',
  password: '',
  password_confirmation: '',
  token: ''
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    // eslint-disable-next-line
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'A senha deve conter pelo menos 1 letra maiúscula, 1 número e 1 caracter especial')
      .required('Por favor, informe sua senha'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
    .required('Por favor, informe sua confirmação de senha')
})

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function ResetPassword() {
  let query = useQuery();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      email: query.get("email"),
      token: query.get("token")
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (values.password_confirmation === values.password) {
        setLoading(true);

        setTimeout(() => {
          resetPassword(values.email || '', values.token || '', values.password)
            .then(({ data: { result } }) => {
              setLoading(false);
              successAlert('', 'Sua nova senha foi cadastrada com sucesso.', 'Ir para login');

              navigate('/auth');
            })
            .catch(() => {
              setLoading(false);
              setSubmitting(false);
              setStatus('The login detail is incorrect');

              erroAlert('', 'Houve um erro ao tentar cadastrar sua nova senha.', 'Tente novamente');
            })
        }, 1000)
      }
    },
  });

  function contain8Letters(data) {
    return /[a-zA-Z0-9\s@,!=%$#&_-\u0600-\u065f\u066a-\u06EF\u06fa-\u06ff\ufb8a\u067e\u0686\u06af\u0750-\u077f\ufb50-\ufbc1\ufbd3-\ufd3f\ufd50-\ufd8f\ufd92-\ufdc7\ufe70-\ufefc\uFDF0-\uFDFD]{8,}/.test(data);
  }

  function containNumbers(data) {
    return /[0-9]/.test(data);
  }

  function containUpper(data) {
    return /[A-Z]/.test(data);
  }

  function containSpecial(data) {
    // eslint-disable-next-line
    return /.*[!@#\$%\^&\*]/.test(data);
  }

  return (
    <Pages type='Logo'>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <h1 className='text-black mb-3 w-75 mb-10' style={{ fontSize: '41px' }}>Vamos cadastrar sua nova senha.</h1>
        <p className='mb-5 font-roboto'>Ela precisa ter, pelo menos:</p>

        <ol className='mb-10 ms-0' style={{ listStyleType: 'none', paddingInlineStart: '0' }}>
          <li className={contain8Letters(formik.values.password) ? 'text-gray-800' : 'text-danger'}>
            -  8 Caracteres
          </li>

          <li className={containUpper(formik.values.password) ? 'text-gray-800' : 'text-danger'}>
            - 1 Letra maiúscula
          </li>

          <li className={containNumbers(formik.values.password) ? 'text-gray-800' : 'text-danger'}>
            -   1 Número
          </li>

          <li className={containSpecial(formik.values.password) ? 'text-gray-800' : 'text-danger'}>
            -  1 Caractere especial (!@#$%&)
          </li>
        </ol>

        <div className='fv-row mb-10'>
          <TextField
            type="password"
            label='Nova senha'
            placeholder='*********'
            touched={formik.touched.password}
            error={formik.errors.password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            {...formik.getFieldProps('password')}
          />
        </div>

        <div className='fv-row mb-10'>
          <TextField
            type="password"
            label='Confirmar nova senha'
            placeholder='*********'
            touched={formik.touched.password_confirmation}
            error={formik.errors.password_confirmation}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            {...formik.getFieldProps('password_confirmation')}
          />
        </div>

        <div className='d-flex flex-wrap  pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-info fw-bolder me-4'
            disabled={formik.isSubmitting}
          >
            {loading ? (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Cadastrando nova senha...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <span className='indicator-label'>Cadastrar nova senha</span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </Pages>
  )

}
