import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react'

import { Trigger } from "../Trigger";

import { ITextFieldProps, TextFieldRef } from "./types";

const EmojiField = forwardRef<TextFieldRef, ITextFieldProps>(({
  touched,
  error,
  label,
  badge = false,
  trigger = false,
  maxLength,
  textTooltip,
  disabled = false,
  type = 'text',
  ...props
}, ref): JSX.Element => {
  const inputReference = useRef<HTMLInputElement>(null);
  const [didFocus, setDidFocus] = useState<boolean>(false);

  const [value, setValue] = useState('');
  const [showEmojis, setShowEmojis] = useState<boolean>(false);

  const showFeedback = (didFocus && value) || touched;

  const classNameControl = !touched
    ? ""
    : showFeedback && error
      ? "is-invalid"
      : "is-valid";

  useImperativeHandle(ref, () => ({
    handleFocus() {
      if (inputReference.current) inputReference.current.focus();
    },
  }));

  function handleAddEmoji(e) {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);

    // (document.getElementById(props.id) as HTMLInputElement).value = props.value + emoji;
    setValue(value + emoji);
  };

  return (
    <>
      {
        label !== undefined && (
          <>
            <label className="form-label">{label}</label>

            {trigger && <Trigger description={textTooltip} />}
            {badge && <span className="badge badge-blue-primary">Opcional</span>}
          </>
        )
      }

      <div className="d-flex">
        <input
          ref={inputReference}
          {...props}
          className={`form-control form-control-lg form-control-solid ${classNameControl}`}
          type={type}
          value={value}
          onInput={(e) => setValue(e.currentTarget.value)}
          onFocus={() => setDidFocus(true)}
          disabled={disabled}
          maxLength={maxLength}
          onBlur={(e) => (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.trim()}
        />

        <button type="button" className="btn btn-outline-light fs-1" onClick={() => setShowEmojis(!showEmojis)}>😀</button>
      </div>

      {
        showEmojis &&
        <>
          <Picker
            data={data}
            onEmojiSelect={handleAddEmoji}
          // onClickOutside={() => setShowEmojis(false)}
          />
        </>

      }

      {
        showFeedback && error && (
          <small className="text-danger fw-bold d-block py-2">{error}</small>
        )
      }
    </>
  );
});

export { EmojiField };
