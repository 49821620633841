import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { AsideDefault } from "./components/aside/AsideDefault";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { PageDataProvider } from "./core";
import { useLocation } from "react-router-dom";
import { DrawerMessenger, ActivityDrawer, Main } from "../partials";
import { MenuComponent } from "../assets/ts/components";
import ContanerCard from "../../app/components/ContanerCard/ContanerCard";
import { BannerProvider } from "../../app/modules/providers";

const MasterLayout = () => {
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  if (
    location.pathname === "/dashboard/settings/chart-request" ||
    location.pathname === "/dashboard/settings/integration" ||
    location.pathname === "/dashboard/settings/financial"
  ) {
    return (
      <>
        <PageDataProvider>
          <BannerProvider>
            <div className="page d-flex flex-row flex-column-fluid">
              <AsideDefault />
              <div
                className="wrapper d-flex flex-column flex-row-fluid"
                id="kt_wrapper"
              >
                <div
                  id="kt_content"
                  className="d-flex flex-column flex-column-fluid"
                >
                  <div className="post d-flex flex-column-fluid" id="kt_post">
                    <Content>
                      <Outlet />
                    </Content>
                  </div>
                </div>
              </div>
            </div>
            <ActivityDrawer />
            <DrawerMessenger />
            <Main />
            <ScrollTop />
          </BannerProvider>
        </PageDataProvider>
      </>
    );
  } else {
    return (
      <PageDataProvider>
        <BannerProvider>
          <div className="page d-flex flex-row flex-column-fluid">
            <AsideDefault />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <ContanerCard>
                <div
                  id="kt_content"
                  className="d-flex flex-column flex-column-fluid"
                >
                  <div className="post d-flex flex-column-fluid" id="kt_post">
                    <Content>
                      <Outlet />
                    </Content>
                  </div>
                </div>
              </ContanerCard>
            </div>
          </div>
          <ActivityDrawer />
          <DrawerMessenger />
          <Main />
          <ScrollTop />
        </BannerProvider>
      </PageDataProvider>
    );
  }
};

export { MasterLayout };

