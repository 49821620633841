interface ChallengeHeaderData {
  headerData?: HeaderData;
}

interface HeaderData {
  title: string;
  subtitle: string;
}

const CreateEngagementChallengeHeader = ({ headerData }: ChallengeHeaderData) => {
  return (
    <div className="">
      <h1>{headerData ? headerData.title : ''}</h1>
      <p className="font-14 font-roboto mb-10 text-gray-600">{headerData ? headerData.subtitle : ''}</p>
    </div>
  );
}

export { CreateEngagementChallengeHeader }