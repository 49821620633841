
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useForm } from "react-hook-form";
import ValideteError from '../../modules/hooks/ValideteError/ValideteError'
import Token from '../../modules/hooks/Token/Token'
import { useAuth } from '../../modules/auth'
import { PatternLogo } from 'app/components/ConnectorLogo/ConnectorLogo'

interface integrationHash {
  id: number;
  title: string;
  type: string;
  tutorialUrl: string;
}

const IntegrationHash = () => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(0)
  const [name, setName] = useState({
    connectorId: 0,
    connectorName: '',
    fields: [],
    data: {}
  })
  const [connectors, setConnectors] = useState<integrationHash[]>([])

  const { logout } = useAuth()
  const auth = Token()
  const { id } = useParams();
  const { register, handleSubmit, formState: { errors } } = useForm();

  //Envia a edição 
  const onSubmit = async data => {
    try {
      setLoading(true)
      const res = await axios.post('/colab/edit-connector', {
        "token": auth,
        "connectorId": id,
        "data": data
      })
      setStatus(res.status)
      setLoading(false)
    } catch (e) {
      if (ValideteError(e)) {
        logout()
      }
      setStatus(404)
    }
  }

  // Lista inputs
  const GetList = async () => {
    try {
      setLoading(true)
      const res = await axios.post('/colab/get-connector', {
        "token": auth,
        "connectorId": id,
      })
      setName(res.data.data)
      setLoading(false)
    } catch (e) {
      if (ValideteError(e)) {
        logout()
      }
    }
  }
  useEffect(() => {
    GetList()
    // eslint-disable-next-line
  }, [id])

  
  const getTutorialUrl = useCallback(async () => {
    try {
      const res = await axios.post("/colab/list-connectors-available", {
        token: auth,
      });
      setConnectors(res.data.data);
    } catch (e) {
      console.log(e);
    }
  }, [auth])
  
  useEffect(() => {
    getTutorialUrl()
  }, [getTutorialUrl]);

  const tutorialUrl = connectors
  .filter((data) => data.id === Number(id))
  .map((data) => data.tutorialUrl)
  .shift() || ''

  return (
    <>
      <h1>Conectores</h1>
      <p className='text-gray-500'>Faça a integração do seu e-commerce para poder usar todas as funções da Brand Lovrs.</p>
      <div className='dividers my-5'></div>

      <div className='d-block d-sm-flex justify-content-sm-between pb-5'>
        <h2>Integração <span className='text-uppercase'>{name.connectorName}</span></h2>
        <div>
          {PatternLogo(name.connectorName)}
        </div>
      </div>
      {status === 0 ?
        <div
          className="alert alert-warning d-flex align-items-center p-5 mb-10 border-dotted border-2"
          role="alert"
        >
          <span className="svg-icon svg-icon-warning svg-icon-2hx pe-sm-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
              <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor" />
              <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor" />
            </svg>
          </span>
          <div className="">
            <h3>Importante</h3>
            <p className="mb-0 text-gray-700">
              As informações da API estão dentro do seu painel da {name.connectorName}. Para encontrar cada um dos dados necessários,{' '}
              <a target="_blank" href={tutorialUrl} rel="noreferrer">
                <span className="text-gray-700 text-decoration-underline">
                  acompanhe nosso tutorial aqui.
                </span>
              </a>
            </p>
          </div>
        </div>
        :
        <div className={`alert ${status === 200 ? 'bg-light-success' : 'alert-danger'}  d-flex align-items-center p-5 mb-10 border-dotted border-2`}
          role="alert"
          style={{ border: ` ${status === 200 ? '2px dotted #50CD89' : '2px dotted #FFA800'} ` }}>
          {status === 200 ?
            <span className="svg-icon svg-icon-success svg-icon-2hx pe-sm-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
              </svg>
            </span> :
            <span className="svg-icon svg-icon-danger svg-icon-2hx pe-sm-5">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor" />
                <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor" />
              </svg>
            </span>
          }
          <div className=''>
            <h3> {status === 200 ? 'Integração feita com sucesso' : 'Erro: Confira os dados informados e tente novamente'} </h3>
            <p className='mb-0'>
              {status === 200 ?
                <>Seus dados foram validados corretamente pela {name.connectorName}</> :
                <>Não conseguimos validar as informações. Verifique se as informações estão corretas e tente novamente.</>
              }
            </p>
          </div>
        </div>
      }

      <form onSubmit={handleSubmit(onSubmit)}>
        {loading ?
          <div className='d-flex justify-content-center'>
            <div className="spinner-grow text-primary" role="status" />
          </div> : <>
            {name.fields
              .sort((a, b) => b.id - a.id)
              .map((data) => {
                return (
                  <div className='mb-6' key={data.id}>
                    <label htmlFor={data.title} className='form-label text-gray-700 mb-1'>{data.title}</label>
                    <input
                      id={data.title}
                      type="text"
                      defaultValue={name.data[data.title]}
                      // value={name.data[data.title]}
                      disabled={status === 200 ? true : false}
                      {...register(`${data.title}`, { required: true })}
                      className={`form-control form-control-lg form-control-solid ${errors[data.title] ? 'is-invalid' : ''}`} />
                  </div>
                )
              })}
          </>
        }
        <div className='dividers mt-10 mb-5'></div>
        <div className='d-flex justify-content-between mt-10'>
          <Link to={`/dashboard/integration`}>
            <span className={`btn btn-secondary`}>Voltar</span>
          </Link>
          {status === 200 ?
            <button type='submit' className='btn btn-success'>
              Integrado com sucesso
              <span className="svg-icon svg-icon-white svg-icon-2hx ps-sm-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
                </svg>
              </span>
            </button> :
            <button type='submit' className='btn btn-primary'>Salvar</button>
          }
        </div>
      </form>
    </>
  )
}

export default IntegrationHash



