
import { useState } from 'react';

import { CreateEngagementChallengeHeader } from './components';

import {
  Goal,
  Settings,
  Reward,
} from './pages';
import { Description } from './pages/edit';

const EditEngagementChallenge = () => {
  const [activeStep, setActiveStep] = useState(3);
  const [data, setData] = useState({});
  const [headerData, setHeaderData] = useState();

  return (
    <div className='mw-950px m-auto py-xl-10 px-xl-20'>
      <CreateEngagementChallengeHeader
        headerData={headerData}
      />
      <>
        {
          activeStep === 0 &&
          <Goal
            setHeaderData={setHeaderData}
            formData={data}
            setFormData={setData}
            actualStep={activeStep}
            setNextStep={setActiveStep}
          />
        }

        {
          activeStep === 1 &&
          <Settings
            setHeaderData={setHeaderData}
            formData={data}
            setFormData={setData}
            actualStep={activeStep}
            setNextStep={setActiveStep}
          />
        }

        {
          activeStep === 2 &&
          <Reward
            setHeaderData={setHeaderData}
            formData={data}
            setFormData={setData}
            actualStep={activeStep}
            setNextStep={setActiveStep}
          />
        }

        {
          activeStep === 3 &&
          <Description
            setHeaderData={setHeaderData}
            formData={data}
            setFormData={setData}
            actualStep={activeStep}
            setNextStep={setActiveStep}
          />
        }
      </>
    </div>
  );
}

export default EditEngagementChallenge;

