import { useState } from 'react';
import axios from 'axios';
import { Modal, ModalBody } from 'react-bootstrap';
import { RiErrorWarningLine, RiCheckLine } from 'react-icons/ri';

import { ModalButton } from 'app/components/Buttons';

interface ActionModalData {
  modalType?: string;
  modalControl: boolean;
  setModalControl: Function;

  modalActionItems: any[];
  setModalActionItems: Function;

  actionData: any[];
  setActionData: Function;

  onConfirmationSuccess?: () => void;
}

export function RewardModal({
  modalType,
  modalControl,
  modalActionItems,
  setModalActionItems,
  setModalControl,
  actionData,
  setActionData,
  onConfirmationSuccess = () => undefined
}: ActionModalData) {
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [rewardCount, setRewardCount] = useState(1);

  const [errorMessage, setErrorMessage] = useState('');

  const handleConfirmAction = async () => {
    try {
      await axios.post('/colab/challenges/reward-challenge', {
        rewardAmount: rewardCount,
	      subscriptionId: actionData[0]
      });

      setModalActionItems([]);
      setActionData([]);

      setModalControl(false);
      setModalSuccess(true);
    } catch (error) {
      if (error.response.status === 400) {
        setErrorMessage(`${error.response.status}: Limite de recompensas por conteúdo excedido.`);
      } else if (error.response.status === 402) {
        setErrorMessage(`${error.response.status}: Saldo indisponível para a transação.`);
      } else if (error.response.status === 404) {
        setErrorMessage(`${error.response.status}: Não foi possível encontrar o conteúdo informado.`);
      } else if (error.response.status === 422) {
        setErrorMessage(`${error.response.status}: Ocorreu um erro inesperado, por favor, tente novamente ou contate o time de suporte.`);
      } else {
        setErrorMessage(`${error.response.status}: Ocorreu um erro inesperado, por favor, tente novamente ou contate o time de suporte.`);
      }

      setModalActionItems([]);
      setActionData([]);

      setModalControl(false);
      setModalSuccess(false);

      setModalError(true);
    }
  }

  function handleCloseSuccessModal() {
    setModalSuccess(false);
    onConfirmationSuccess();
  }

  function handleCloseErrorModal() {
    setModalError(false);
    onConfirmationSuccess();
  }

  return (
    <>
      <Modal
        variant="primary"
        show={modalControl}
        backdrop="static"
        keyboard={false}
        centered
      >
        <ModalBody className='p-12'>
          <div className='action-modal-header d-flex justify-content-center align-content-center pb-4'>
            <RiErrorWarningLine size={22} color='#009EF6' /> <span className={modalType}>Atenção</span>
          </div>

          <div className='text-center fs-4 p-4'>
            Quantas recompensas você quer liberar para o conteúdo selecionado?
          </div>

          <div className='d-flex justify-content-center align-items-center mt-3 mb-3'>
            <div className='d-flex'>
              <input type="radio" id="1" name="payment_reward" value="1" className='d-none' defaultChecked />
              <label className='btn-ciclo cursor-pointer px-14' htmlFor="1" onClick={() => setRewardCount(1)}>1</label>
            </div>
            <div className='d-flex'>
              <input type="radio" id="2" name="payment_reward" value="2" className='d-none' />
              <label className='btn-ciclo cursor-pointer px-14' htmlFor="2" onClick={() => setRewardCount(2)}>2</label>
            </div>
            <div className='d-flex'>
              <input type="radio" id="3" name="payment_reward" value="3" className='d-none' />
              <label className='btn-ciclo cursor-pointer px-14' htmlFor="3" onClick={() => setRewardCount(3)}>3</label>
            </div>
          </div>

          <div className='text-center fs-4 p-4'>
            <strong>Atenção: </strong> Esta ação é irreversivel e a quantia será retirada do seu Saldo Reservado.
          </div>

          <div className='d-flex justify-content-evenly align-content-center pt-4'>
            <ModalButton
              buttonType='outlined'
              buttonStyle='approved'
              title='Cancelar'
              onClick={() => setModalControl(false)}
            />

            <ModalButton
              buttonType='primary'
              buttonStyle='approved'
              title='Liberar recompensa'
              onClick={() => handleConfirmAction()}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal
        variant="primary"
        show={modalSuccess}
        backdrop="static"
        keyboard={false}
        centered
      >
        <ModalBody className='p-12'>
          <div className='action-modal-header d-flex justify-content-center align-content-center pb-4'>
            <RiCheckLine size={24} color='#50CD89' /> <span className='success'> Tudo certo!</span>
          </div>

          <div className='action-modal-header d-flex justify-content-center align-content-center pb-4'>
            <span className='info-text'>Já liberamos a recompensa para seu embaixador.</span>
          </div>

          <div className='d-flex justify-content-evenly align-content-center pt-4'>
            <ModalButton
              buttonType='primary'
              buttonStyle='approved'
              title='Voltar'
              onClick={() => handleCloseSuccessModal()}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal
        variant="primary"
        show={modalError}
        backdrop="static"
        keyboard={false}
        centered
      >
        <ModalBody className='p-12'>
          <div className='action-modal-header d-flex justify-content-center align-content-center pb-4'>
            <RiCheckLine size={24} color='#F1416C' /> <span className='errored'> Houve um erro!</span>
          </div>

          <div className='action-modal-header d-flex justify-content-center align-content-center pb-4'>
            <span className='info-text'>{errorMessage}</span>
          </div>

          <div className='d-flex justify-content-evenly align-content-center pt-4'>
            <ModalButton
              buttonType='primary'
              buttonStyle='reproved'
              title='Voltar'
              onClick={() => handleCloseErrorModal()}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
