/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import axios from "axios";

import { SubTitle } from "app/components";
import Alert from "app/components/Alert/Alert";
import { CreateEngagementChallengeWizzardController } from '../../../components';
import MetricTable from "../../../components/TableMetric";

import { WizzardFrameData } from "../../types";
import { Loading } from "app/components/Loading";

const header = {
  title: 'Configurações',
  subtitle: 'Configure o seu desafio de engajamento'
};

const Settings = ({ setHeaderData, formData, setFormData, actualStep, setNextStep }: WizzardFrameData) => {
  const [isLoading, setIsLoading] = useState(false);
  const [metricList, setMetricList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const { handleSubmit } = useForm();

  useEffect(() => {
    setHeaderData(header);
  }, [setHeaderData]);


  useEffect(() => {
    getMetricList();
  }, []);

  async function getMetricList() {
    setIsLoading(true);
    const response = await axios.get('/colab/list-all-metrics');
    setMetricList(response.data);
    setIsLoading(false);
  }

  async function onSubmit() {
    const goals = []
    selectedItems.map(selection => goals.push({
      metricId: selection,
      amount: Number((document.getElementById(`${selection}`) as HTMLInputElement).value),
      meansureUnit: 'absolute'
    }))
    setFormData({ ...formData, goals })
    setNextStep(actualStep + 1)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Alert title={"Atenção!"} border={false} icon="play" type='primary'>
        <p className="font-14 font-roboto mb-0">Por enquanto, só conseguimos criar desafios onde <strong>Embaixadores</strong> publicam um <strong>REELS</strong>  no <strong>seu próprio perfil</strong> do <strong>Instagram</strong>.</p>
      </Alert>

      <SubTitle
        title={"Metas de Interação"}
        hasPopover='Defina todas as metas que precisam ser atingidas para liberar a recompensa deste desafio.'
        description={"Defina as métricas e os valores mínimos para considerar o desafio cumprido."}
      />

      {isLoading ? <Loading /> :
        <MetricTable
          metrics={metricList}
          setSelectedItems={setSelectedItems}
        />}
      <div className='divisor px-10 my-10' />

      <CreateEngagementChallengeWizzardController
        actualStep={actualStep}
        setNextStep={setNextStep}
        disableNextStep={selectedItems.length > 0 ? false : true}
      />
    </form>
  );
}

export { Settings };