/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { Badge, Modal, Spinner } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { TbAlertCircle } from 'react-icons/tb'
import { DateFormat } from '../../modules/hooks/DateFormat/DateFormat'
import Token from '../../modules/hooks/Token/Token'
import Pagination from '../../components/Pagination/Pagination'
import ValideteError from '../../modules/hooks/ValideteError/ValideteError'
import { useAuth } from '../../modules/auth'
import { Table } from "../../components/Table";
import clsx from "clsx";
import { Loading } from 'app/components'

interface list {
  active: number;
  commissionValue: number;
  couponsLimit: number;
  couponsTotal: string;
  expirationDate: string;
  id: number;
  influencersTotal: number;
  title: string;
  stats: stats;
}
interface stats {
  totalChallenges: number;
}

type ChallengeType = 'sale' | 'engagement';

const Challenge = () => {
  const auth = Token()
  const { logout } = useAuth()
  const navigate = useNavigate()

  const [total, setTotal] = useState<number>()
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(50)

  const [table, setTable] = useState<list[]>([])

  const [loading, setLoading] = useState(false)

  const [idModal, setidModal] = useState(0)
  const [process, setProcess] = useState(false)
  const [success, setSuccess] = useState(false)
  const [show, setShow] = useState(false);
  const [blocos, setBlocos] = useState({
    usedCoupons: 0,
    totalReseved: 0,
    totalRefundable: 0
  })

  const [challengeType, setChallengeType] = useState<ChallengeType>("sale")

  const getTable = async () => {
    try {
      const res = await axios.post('/colab/list-challenges', {
        'token': auth,
        'page': currentPage,
        'pageSize': limit,
        'order': 'desc',
        'type': challengeType
      });

      setTotal(res.data.stats.totalChallenges);
      setTable(res.data.challenges);
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      }
    }
  }
  useEffect(() => {
    getTable()
    // eslint-disable-next-line
  }, [total, currentPage, limit, show, challengeType])

  const getRemove = async () => {
    setLoading(true)
    if (idModal > 0) {
      const res = await axios.post('/colab/get-challenge', {
        "token": auth,
        "id": idModal,
      })
      setBlocos(res.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getRemove()
    // eslint-disable-next-line
  }, [])

  // const Remove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id) => {
  //   e.preventDefault()
  //   setidModal(id)
  //   handleShow()
  //   getRemove()
  // }

  const handleRemoveChallenge = () => {
    if (challengeType === 'engagement') RemoveEngagementChallenge(idModal)
    else RemoveChallenge(idModal)
  }

  const RemoveChallenge = async (id: number) => {
    try {
      setProcess(false)
      setSuccess(false)
      const res = await axios.post('/colab/delete-challenge', {
        "token": auth,
        "id": id,
      })
      setProcess(true)
      if (res.status === 204 || res.status === 200) {
        setSuccess(true)
      }

    } catch (e) {
      if (ValideteError(e)) {
        logout()
      }
    }
  }

  const RemoveEngagementChallenge = async (id: number) => {
    try {
      setProcess(false)
      setSuccess(false)
      const res = await axios.post('/colab/delete-engagement-challenge', {
        accessToken: auth,
        challengeId: id
      })
      setProcess(true)
      if (res.status === 204 || res.status === 200) {
        setSuccess(true)
      }
    } catch (e) {
      if (ValideteError(e)) {
        logout()
      }
    }
  }

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false)
    setProcess(false)
    setSuccess(false)
  }

  const handleRowClick = (challengeId) => {
    navigate(`/dashboard/challenge/${challengeType}/view/${challengeId}`, { state: challengeType })
  }

  const handleChallengeTypeChange = useCallback((newChallengeType: ChallengeType) => {
    if (newChallengeType !== challengeType) {
      setChallengeType(newChallengeType)
    }
  }, [challengeType, setChallengeType])

  return (
    <>
      <div className='d-flex flex-column align-items-start justify-content-start flex-lg-row justify-content-md-between'>
        <div className='position-relative pb-10 pb-md-0'>
          <h1>Campanhas</h1>
          {!loading && <>
            <span className='badge bg-blue rounded-1' style={{ border: '2px' }}>{total}</span>
            <p className='text-gray-400 fw-bold fs-5 d-inline mt-5 ms-2'>Novas Campanhas</p>
          </>}

        </div>
        <div className='d-flex flex-column flex-md-row align-items-center'>
          <div className='mt-7 mt-md-0'>
            <Link to="/dashboard/challenge/engagement/create">
              <span className="btn btn-primary">
                Criar Campanha
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className='dividers my-5'></div>
      <h5 className="my-5">Selecione abaixo a categoria de campanhas:</h5>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            type="button"
            className={clsx("nav-link", { "active": challengeType === "sale" }, { "text-muted": challengeType !== "sale" })}
            onClick={() => handleChallengeTypeChange("sale")}
          >
            Venda
          </button>
        </li>
      </ul>
      {loading ?
        <Loading /> :
        <article className='mt-10'>
          <Table
            headers={[
              { value: 'id' },
              { value: 'título do desafio' },
              { value: 'valor da recompensa' },
              { value: 'embaixadores' },
              { value: 'validade' },
              { value: 'status' },
            ]}
            rows={table.map(d => ({
              key: d.id,
              onClick: () => handleRowClick(d.id),
              values: [
                d.id,
                d.title,
                d.commissionValue,
                d.influencersTotal,
                DateFormat(d.expirationDate),
                // eslint-disable-next-line eqeqeq
                d.active == 1 ? <Badge bg="success">Ativo</Badge> : <Badge bg='danger'>Inativo</Badge>,
              ]
            }))}
            hasBatchActions={false}
            titleErro='Você ainda não tem nenhum desafio'
            messageErro='Que tal criar o primeiro desafio da sua marca ?'
          />
          <Pagination
            total={total}
            limit={limit}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            view={false}
            length={total}
            setLimit={setLimit}
          />
        </article>}


      <Modal show={show} onHide={handleClose}>
        {loading ? <div className='d-flex justify-content-center h-100 flex-column align-items-center h-100px '>
          <div className="spinner-grow text-primary" role="status" />
        </div> : <>
          {success ?
            // <Modal.Body className='d-flex justify-content-center flex-column align-items-center'>
            //     <MdOutlineHourglassTop className='text-gray-600 fa-5x' />
            //     <h2 className='mt-5'>Excluindo desafio</h2>
            //     <p className='text-gray-500'>Aguarde enquanto processamos sua solicitação</p>
            // </Modal.Body>
            // :
            <Modal.Body className='d-flex justify-content-center flex-column align-items-center'>
              <span className="svg-icon svg-icon-primary svg-icon-2hx">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                  <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
                </svg>
              </span>
              <h2 className='mt-5'>O desafio foi excluído</h2>
              <button type='button' className='btn btn-primary text-white mt-5' onClick={handleClose}>O desafio foi excluído</button>
            </Modal.Body>
            : <>
              <Modal.Body>
                <div className='alert alert-danger border-0'>
                  <h2 className='mb-0 text-danger text-center'>
                    <TbAlertCircle className='fs-2  me-3' />
                    Atenção
                  </h2>
                </div>
                <p className='my-5 text-center'>Você está prestes a excluir este desafio</p>
                <p>Todas as vendas feitas com cupom durante o período ativo da campanha ainda terão o desconto aplicado.</p>
               
                <p>Valor parcial referente ao pagamento de embaixadores participantes no período de pagamento definido previamente por você. </p>
                <p>Caso alguma compra seja estornada durante este período, o valor de comissão não será pago ao embaixador e será devolvido a sua carteira.</p>
                <p>Os cupons não utilizados serão cancelados e o desafio será removido do aplicativo imediatamente. </p>
                <p>Todos os seus embaixadores serão avisados sobre o término antecipado.</p>
                <div className='py-10 text-center fw-bold'>
                  <p className='mb-0'>Você tem certeza de que quer excluir o desafio?</p>
                  <p className='text-danger'> Essa ação não poderá ser desfeita</p>
                </div>
                <div className='row'>
                  <div className='col-5'>
                    <button className='btn w-100 border border-2 border-gray-600 text-gray-600' onClick={handleClose}>Voltar</button>
                  </div>
                  <div className='col-7'>
                    <button className='btn btn-danger w-100' onClick={handleRemoveChallenge}>
                      <span className={`${process && 'pe-5'}`}> {process ? 'Excluindo' : 'Excluir desafio'}</span>
                      {process && <Spinner animation="grow" variant="light" size='sm' />}
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </>}
        </>}

      </Modal>
    </>
  )
}

export default Challenge;