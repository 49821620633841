export type ProfileType = {
  name: string;
  category: string;
  image_cover: string;
  aboutMe: string;
  acceptRule: number
  socials: SocialsType;
}

export interface ICategory {
  name: string;
  id: number;
}

export type SocialsType = {
  instagram: string,
  facebook: string,
  twitter: string,
  youtube: string,
  tiktok: string,
};

export enum codeProfile {
  InternalServerError = 500,
  InvaliPassword = 400,
  ExistingEmail = 409,
  FormValidation = 422,
  UnexpectedError = 502,
  PayloadTooLarge = 413
}