import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { Profile } from "../pages/Profile/Profile";
import Challenge from "../pages/Challenge/Challenge";
import Dashboard from "../pages/Dashboard/Dashboard";
import AddFunds from "../pages/Add-Funds/Add-Funds";
import Integration from "../pages/Integration/Integration";
import IntegrationEdit from "../pages/IntegrationEdit/IntegrationEdit";
import IntegrationHash from "../pages/IntegrationHash/IntegrationHash";
import NewChallenge from "../pages/Challenge/Sales/pages/create";
import WithdrawFunds from "../pages/Withdraw-Funds/Withdraw-Funds";
import ViewChallenge from "../pages/Challenge/Sales/pages/view";
import Influencer from "../pages/Influencer/Influencer";
import Legate from "../pages/Settings/components/Legate/Legate";
import Connectors from "../pages/Settings/components/Connectors/Connectors";
import FinancialMoney from "../pages/Settings/components/FinancialMoney/FinancialMoney";
import EditChallenge from "app/pages/Challenge/Sales/pages/edit";
import CreateEngagementChallenge from "app/pages/Challenge/Engagement";
import Sales from "app/pages/Sales";
import EditEngagementChallenge from "app/pages/Challenge/Engagement/edit";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getAuth } from "app/modules/auth";

const PrivateRoutes = () => {
  const [show, setShow] = React.useState(true);

  const handleClose = () => setShow(false);
  const auth = getAuth();

  useEffect(() => {
    if (window.location.href.includes('tokenNewFront')) return setShow(false);
    else window.location.href = `${process.env.REACT_APP_NEW_FRONT_URL}/token/${auth?.api_token.replaceAll('.', 'ponto')}`
  }, [auth?.api_token])

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className='d-flex justify-content-center h-100 flex-column align-items-center mb-10'>
            <div className="spinner-grow text-primary" role="status" />
          </div>
          <h4>Estamos redirecionando você para o novo painel de controle, aguarde...</h4>
        </Modal.Body>
      </Modal>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path="auth/*" element={<Navigate to="/dashboard" />} />

          <Route path="dashboard" element={<Dashboard />} />

          <Route path="dashboard/sales" element={<Sales />} />
          <Route path="dashboard/financial/add-funds" element={<AddFunds />} />
          <Route
            path="dashboard/financial/withdraw-funds"
            element={<WithdrawFunds />}
          />

          <Route path="dashboard/chart-request" element={<Influencer />} />
          <Route path="dashboard/profile" element={<Profile />} />

          <Route path="dashboard/integration" element={<Integration />} />
          <Route
            path="dashboard/integration/:id"
            element={<IntegrationHash />}
          />
          <Route
            path="dashboard/integration/edit/:id"
            element={<IntegrationEdit />}
          />

          <Route path="dashboard/challenge" element={<Challenge />} />
          <Route
            path="dashboard/challenge/engagement/edit/:id"
            element={<EditEngagementChallenge />}
          />
          <Route
            path="dashboard/challenge/sale/edit/:id"
            element={<EditChallenge />}
          />

          <Route path="dashboard/challenge/new" element={<NewChallenge />} />
          <Route
            path="dashboard/challenge/:type/view/:id"
            element={<ViewChallenge />}
          />

          <Route
            path="dashboard/challenge/engagement/create"
            element={<CreateEngagementChallenge />}
          />

          <Route path="dashboard/settings/chart-request" element={<Legate />} />
          <Route
            path="dashboard/settings/integration"
            element={<Connectors />}
          />
          <Route
            path="dashboard/settings/financial"
            element={<FinancialMoney />}
          />

          <Route path="*" element={<Navigate to="/error/404" />} />
        </Route>
      </Routes>
    </>
  );
};

export { PrivateRoutes };

