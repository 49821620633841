import { useEffect } from 'react'


interface props {
    total?: number,
    limit?: number,
    setCurrentPage: any,
    currentPage: any
    view?: boolean,
    length?: number,
    setLimit?: any
}

const Pagination = ({ total, limit, setCurrentPage, currentPage, view, length, setLimit }: props) => {
    // const [arrCurr, setarrCurr] = useState<any>([])
    const totalPages = Math.ceil(total / limit)
    const arrayPages: number[] = []

    for (let i = 1; i <= totalPages; i++) {
        arrayPages.push(i)
    }

    useEffect(() => (
        setCurrentPage(currentPage)
    ), [setCurrentPage, currentPage])



    return <>
        <div className="d-flex flex-row flex-md-column justify-content-md-between align-items-md-center"></div>
        <div className={`${view ? 'd-flex flex-column flex-md-row justify-content-md-between align-items-md-center' : 'd-block'}`}>
            {view && <p className='mb-0'>
                <span className='pe-1'>Mostrando de {total} a {length} solicitação </span>
                {/* <input type="text" className='form-control p-1 d-inline text-center' style={{ width: '40px' }} defaultValue={limit} maxLength={3} onBlur={(e) => setLimit(Number(e.target.value))} /> */}
            </p>}
            {total / limit > 1 &&
                <ul className="pagination cursor-pointer" style={{ justifyContent: 'initial' }}>
                    {currentPage > 1 ?
                        <li className={`page-item previous ${currentPage > 1 ? '' : 'disabled'} `} onClick={() => setCurrentPage(currentPage - 1)}>
                            <span className="page-link">
                                <i className="previous"></i>
                            </span>
                        </li> : <></>}

                    {arrayPages
                        .map((data) => (
                            <li className="page-item " key={data} onClick={() => setCurrentPage(data)}>
                                <span className={`page-link ${data === currentPage ? "active" : ''} `} >{data}</span>
                            </li>
                        ))}
                    {currentPage < arrayPages.length ?
                        <li className={`page-item next ${currentPage < arrayPages.length ? '' : 'disabled'}`}
                            onClick={() => setCurrentPage(currentPage + 1)}>
                            <span className="page-link">
                                <i className=" next"></i>
                            </span>
                        </li> : <></>}
                </ul>
            }
        </div>
    </>
}

export default Pagination
