import { useState } from 'react';
import axios from 'axios';
import { Modal, ModalBody } from 'react-bootstrap';
import { RiErrorWarningLine, RiCheckLine } from 'react-icons/ri';

import { ModalButton } from 'app/components/Buttons';

import Token from 'app/modules/hooks/Token/Token';

interface ActionModalData {
  modalType?: string;
  modalControl: boolean;
  setModalControl: Function;

  modalActionItems: any[];
  setModalActionItems: Function;

  actionData: any[];
  setActionData: Function;

  onConfirmationSuccess?: () => void;
}

export function ApprovalModal({
  modalType,
  modalControl,
  modalActionItems,
  setModalActionItems,
  setModalControl,
  actionData,
  setActionData,
  onConfirmationSuccess = () => undefined
}: ActionModalData) {
  const auth = Token();
  const [modalSuccess, setModalSuccess] = useState(false);

  const handleConfirmAction = async () => {
    try {
      await axios.post(`/colab/subscriptions/${modalType === 'approved' ? 'approve' : 'reprove'}`, {
        token: auth,
	      subscriptionsIds: modalActionItems.concat(actionData)
      });

      setModalActionItems([]);
      setActionData([]);

      setModalControl(false);
      setModalSuccess(true);
    } catch (e) {
      console.log(e);
    }
  }

  function handleCloseSuccessModal() {
    setModalSuccess(false);
    onConfirmationSuccess();
  }

  return (
    <>
      <Modal
        variant="primary"
        show={modalControl}
        backdrop="static"
        keyboard={false}
        centered
      >
        <ModalBody className='p-12'>
          <div className='action-modal-header d-flex justify-content-center align-content-center pb-4'>
            <RiErrorWarningLine size={22} color={modalType === 'approved' ? '#009EF6' : '#FF4B55'} /> <span className={modalType}>Atenção</span>
          </div>

          <div className='text-center fs-4 p-4'>
            Você está prestes a <strong>
              { modalType === 'approved' ? 'aprovar' : 'reprovar' } {
                modalActionItems.concat(actionData).length < 2 ? 'o conteúdo selecionado' : 'todos os conteúdos selecionados'}
            </strong>. Confirma essa ação?
          </div>

          <div className='d-flex justify-content-evenly align-content-center pt-4'>
            <ModalButton
              buttonType='outlined'
              buttonStyle={modalType}
              title='Cancelar'
              onClick={() => setModalControl(false)}
            />

            <ModalButton
              buttonType='primary'
              buttonStyle={modalType}
              title={`${ modalType === 'approved' ? 'Aprovar' : 'Reprovar' } todos`}
              onClick={() => handleConfirmAction()}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal
        variant="primary"
        show={modalSuccess}
        backdrop="static"
        keyboard={false}
        centered
      >
        <ModalBody className='p-12'>
          <div className='action-modal-header d-flex justify-content-center align-content-center pb-4'>
            <RiCheckLine size={24} color='#50CD89' /> <span className='success'> Tudo certo!</span>
          </div>

          <div className='action-modal-header d-flex justify-content-center align-content-center pb-4'>
            <span className='info-text'>Todos os conteúdos foram { modalType === 'approved' ? 'aprovados' : 'reprovados' }.</span>
          </div>

          <div className='d-flex justify-content-evenly align-content-center pt-4'>
            <ModalButton
              buttonType='primary'
              buttonStyle='approved'
              title='Voltar'
              onClick={() => handleCloseSuccessModal()}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
