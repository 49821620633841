import {
    FC,
    useState,
    useEffect,
    createContext,
    useContext,
    Dispatch,
    SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'

type AuthContextProps = {
    auth: AuthModel | undefined
    saveAuth: (auth: AuthModel | undefined) => void
    currentUser: UserModel | undefined
    setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
    logout: () => void
}

const initAuthContextPropsState = {
    auth: authHelper.getAuth(),
    saveAuth: () => { },
    currentUser: undefined,
    setCurrentUser: () => { },
    logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
    return useContext(AuthContext)
}

const AuthProvider: FC = ({ children }) => {
    const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
    const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
    const saveAuth = (auth: AuthModel | undefined) => {
        setAuth(auth)
        if (auth) {
            authHelper.setAuth(auth)
        } else {
            authHelper.removeAuth()
        }
    }

    const logout = () => {
        saveAuth(undefined)
        setCurrentUser(undefined)
        window.analytics.track('Logged Out')
        window.analytics.reset()
    }

    return (
        <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
            {children}
        </AuthContext.Provider>
    )
}

const AuthInit: FC = ({ children }) => {
    const { auth, logout, setCurrentUser } = useAuth()
    // const didRequest = useRef(false)
    const [showSplashScreen, setShowSplashScreen] = useState(true)
    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    useEffect(() => {
        // const requestUser = async (apiToken: string) => {
        //     try {
        //         if (!didRequest.current) {
        //             const { data } = await getUserByToken(apiToken)
        //             if (data) {
        //                 setCurrentUser(data)
        //             }
        //         }
        //     } catch (error) {
        //         console.error(error)
        //         if (!didRequest.current) {
        //             logout()
        //         }
        //     } finally {
        //         setShowSplashScreen(false)
        //     }

        //     return () => (didRequest.current = true)
        // }

        if (auth && auth.api_token) {
            setCurrentUser({
                id: 1,
                username: 'Zardini',
                password: undefined,
                email: 'alexzardini@hotmail.com',
                first_name: 'Alex',
                last_name: 'Zardini'
              })
              setShowSplashScreen(false)
            // requestUser(auth.api_token)
        } else {
            logout()
            setShowSplashScreen(false)
        }
        // eslint-disable-next-line
    }, [])

    return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
