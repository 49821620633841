import { FC } from 'react'
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate
} from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import {
  Logout,
  AuthPage,
  useAuth,
  getAuth,
  // getAuth
} from '../modules/auth'
import { App } from '../App'

const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  const auth = getAuth();

  if (auth && auth.statusBrand !== 2004 && window.location.pathname !== '/auth/registration') {
    window.location.href = '/auth/registration';
  }

  // const auth = getAuth()

  // const Redirect = () => {
  //     if (!auth?.hasConnector) {
  //         return <Route index element={<Navigate to='/dashboard/settings/integration' />} />
  //     } else if (!auth?.hasConnector && auth.chart_request === 0) {
  //         return <Route index element={<Navigate to='/dashboard/settings/financial' />} />
  //     } else {
  //         return <Route index element={<Navigate to='/dashboard' />} />
  //     }
  // }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser && auth?.statusBrand === 2004 ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />

            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
