import { useState } from "react";
import { node } from "prop-types";

import { IBannerProvider } from "./types";
import { BannerContext } from "./bannerContext";

const BannerProvider: React.FC<IBannerProvider> = ({ children }) => {
  const [banner, setBanner] = useState<string>("");

  const handleBannerChange = (url: string) => {
    setBanner(url);
  };

  return (
    <BannerContext.Provider value={{ banner, handleBannerChange }}>
      {children}
    </BannerContext.Provider>
  );
};

BannerProvider.propTypes = {
  children: node.isRequired,
};

export { BannerProvider };

