import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CreateEngagementChallengeWizzardController } from '../../../components';
import { WizzardFrameData } from "../../types";

const header = {
  title: "Objetivo do Desafio",
  subtitle: "Escolha o objetivo do desafio de acordo com sua necessidade"
};

const Goal = ({ setHeaderData, actualStep, setNextStep }: WizzardFrameData) => {
  const [selectedGoal, setSelectedGoal] = useState(null);
  const navigate = useNavigate();

  const { handleSubmit } = useForm();

  useEffect(() => {
    setHeaderData(header);
  }, [setHeaderData]);

  const handleChallengeSelected = (i: number) => {
    if (selectedGoal === i) {
      return setSelectedGoal(null);
    }
    setSelectedGoal(i);
  };

  const handleRegisterGoal = async () => { selectedGoal === 2 ? navigate('/dashboard/challenge/new') : setNextStep(1) }

  const radio = [ 
    {
      id: 2,
      title: "Vendas",
      description: "Cupons de desconto comissionados para que embaixadores divulguem e gerem vendas.",
      icon: '<svg fill=none height=24 viewBox="0 0 24 24" width=24 xmlns=http://www.w3.org/2000/svg><path d="M20 18H4C3.4 18 3 17.6 3 17V7C3 6.4 3.4 6 4 6H20C20.6 6 21 6.4 21 7V17C21 17.6 20.6 18 20 18ZM12 8C10.3 8 9 9.8 9 12C9 14.2 10.3 16 12 16C13.7 16 15 14.2 15 12C15 9.8 13.7 8 12 8Z"fill=currentColor opacity=0.3 /><path d="M18 6H20C20.6 6 21 6.4 21 7V9C19.3 9 18 7.7 18 6ZM6 6H4C3.4 6 3 6.4 3 7V9C4.7 9 6 7.7 6 6ZM21 17V15C19.3 15 18 16.3 18 18H20C20.6 18 21 17.6 21 17ZM3 15V17C3 17.6 3.4 18 4 18H6C6 16.3 4.7 15 3 15Z"fill=currentColor /></svg>',
    }
  ]

  return (
    <form onSubmit={handleSubmit(handleRegisterGoal)}>
      <section className='px-md-10 d-flex justify-content-center align-items-center'>
        <article className='my-2'>
          {radio.map((data) => (
            <div className='px-md-20 mw-xl-75 m-auto' key={data.id}>
              <input
                type="radio"
                value={data.title}
                name="goalType"
                id={data.title}
                className='d-none'
              />

              <label
                htmlFor={data.title}
                onClick={() => handleChallengeSelected(data.id)}
                className={`border-dashed alert alert-dismissible ${selectedGoal === data.id ? "bg-ligth-blue border-dashed-ligth-blue" : "bg-gray-200 border-gray-500  border-dashed"}  d-flex align-items-center flex-column flex-sm-row p-5 mb-10 border-1 cursor-pointer`} >
                <span className={`svg-icon ${selectedGoal === data.id ? 'svg-icon-primary' : 'svg-icon-muted'} svg-icon-2hx pe-6`}
                  dangerouslySetInnerHTML={{ __html: data.icon }} />
                <div>
                  <h2>{data.title}</h2>
                  <p className={`text-gray-500 mb-0`}>{data.description}</p>
                </div>
              </label>
            </div>
          ))}
        </article>
      </section>

      <div className='divisor px-10 my-10' />

      <CreateEngagementChallengeWizzardController
        actualStep={actualStep}
        setNextStep={setNextStep}
        disableNextStep={selectedGoal === null}
      />
    </form>
  )
}

export { Goal };