import { OverlayTrigger, Popover } from "react-bootstrap";

type Props = {
  interests: string[];
}

export const InterestsList = ({ interests }: Props) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        {interests?.filter((_, index) => index >= 3).map((interest, index) => (
          <div>
            <span className="badge badge-interest text-black me-2 mb-1 h-auto" key={`${interest}-${index}`}>{interest}</span>
          </div>
        ))}
      </Popover.Body>
    </Popover>
  );

  return (
    <div className='d-flex justify-content-center align-items-center mt-5 mb-5 min-h-45px'>
      <div className='flex-wrap d-flex justify-content-center align-items-center'>
        {interests === null
          ?
            <span className="badge badge-interest me-2 mb-1 h-auto">-</span>
          :
            <>
              {interests?.filter((_, index) => index < 3).map((interest, index) => (
                <span className="badge badge-interest me-2 mb-1 h-auto" key={`${interest}-${index}`}>{interest}</span>
              ))}

              {
                interests.length > 3
                &&
                <OverlayTrigger trigger={["hover", "click"]} placement="bottom" overlay={popover}>
                  <span className="badge badge-interest bg-primary me-2 mb-1 h-auto cursor-pointer">...</span>
                </OverlayTrigger>
              }
            </>
        }
      </div>
    </div>
  )
}