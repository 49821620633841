/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useFormik } from "formik";

import ProfileFormSchema from "./Form.schema";

import { RegisterWizzardController } from "../../components/Controller";

import { WizzardFrameData } from "../types";
import { InfluencerBanner, InfluencerType, codeInfluencer } from "./types";
import { UploadImage } from "app/components/Inputs/UploadImage";
import { useState } from "react";
import axios from "axios";
import { Modal, Alert, Spinner } from "react-bootstrap";
import { BsCheck2Circle } from "react-icons/bs";
import {
  getAmbassadors,
  getBalance,
  getConnectores,
  getListChallenge,
  getUser,
  login,
} from "app/modules/auth/core/_requests";
import { useAuth } from "app/modules/auth/core/Auth";
import jwt_decode from "jwt-decode";

const Influencer = ({
  formData,
  setFormData,
  actualPage,
  setNextPage,
  actualField,
  setNextField,
  brand,
}: WizzardFrameData) => {
  const [banner, setBanner] = useState<InfluencerBanner>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [intro, setIntro] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [messageErro, setMessageErro] = useState<string>("");
  const [stateMessage, setStateMessage] = useState<boolean>(false);
  const { saveAuth } = useAuth();
  const token = localStorage.getItem("accessToken");
  const decodedToken: any = jwt_decode(token);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const currentValidationSchema = ProfileFormSchema[actualField];

  const initialValues: InfluencerType = {
    title: "",
    description: "",
    image: "",
  };
  const errorMessages = {
    [codeInfluencer.UnexpectedError]:
      "Falha no servidor. Tente novamente em breve",
    [codeInfluencer.BrandNotFound]: "Marca não encontrada",
    [codeInfluencer.UploadError]: "Erro ao enviar arquivo",
  };

  const Error = (status: any[number | undefined | null]): void => {
    if (isNaN(status)) {
      setMessageErro(errorMessages[status]);
    } else {
      setMessageErro("Erro desconhecido");
    }
  };

  const title = [
    { value: `Vem pra comunidade da ${decodedToken?.permissions[0]?.brand ? decodedToken?.permissions[0]?.brand : decodedToken?.permissions?.permissions[0]?.brand}`, id: "a" },
    { value: "Faça parte você também", id: "b" },
    { value: `Venha fazer parte da ${decodedToken?.permissions[0]?.brand ? decodedToken?.permissions[0]?.brand : decodedToken?.permissions?.permissions[0]?.brand}`, id: "c" },
    { value: "Junte-se à nossa comunidade de embaixadores", id: "d" },
    {
      value: `Junte-se a todos os embaixadores da ${decodedToken?.permissions[0]?.brand ? decodedToken?.permissions[0]?.brand : decodedToken?.permissions?.permissions[0]?.brand}!`,
      id: "e",
    },
  ];

  const description = [
    {
      id: "a",
      label: `Entre na comunidade da ${decodedToken?.permissions[0]?.brand ? decodedToken?.permissions[0]?.brand : decodedToken?.permissions?.permissions[0]?.brand} e participe dos nossos desafios para <strong>ganhar recompensas</strong>  e <strong>acessar benefícios exclusivos.</strong> Cadastre-se gratuitamente abaixo:`,
      value: `Entre na comunidade da ${decodedToken?.permissions[0]?.brand ? decodedToken?.permissions[0]?.brand : decodedToken?.permissions?.permissions[0]?.brand} e participe dos nossos desafios para ganhar recompensas e acessar benefícios exclusivos. Cadastre-se gratuitamente abaixo:`,
    },
    {
      id: "b",
      label: `Junte-se à nossa comunidade na BrandLovrs. Assim, você participa dos desafios da ${decodedToken?.permissions[0]?.brand ? decodedToken?.permissions[0]?.brand : decodedToken?.permissions?.permissions[0]?.brand} e pode <strong>ganhar recompensas exclusivas.</strong>  Faça parte se cadastrando abaixo:`,
      value: `Junte-se à nossa comunidade na BrandLovrs. Assim, você participa dos desafios da ${decodedToken?.permissions[0]?.brand ? decodedToken?.permissions[0]?.brand : decodedToken?.permissions?.permissions[0]?.brand} e pode ganhar recompensas exclusivas. Faça parte se cadastrando abaixo:`,
    },
    {
      id: "c",
      label:
        "Chegamos na BrandLovrs! Faça parte da nossa comunidade de embaixadores e <strong>ganhe recompensas ao cumprir os nossos desafios.</strong>  Cadastre-se grátis abaixo:",
      value:
        "Chegamos na BrandLovrs! Faça parte da nossa comunidade de embaixadores e ganhe recompensas ao cumprir os nossos desafios. Cadastre-se grátis abaixo:",
    },
  ];

  const formik = useFormik({
    initialValues,
    validationSchema: currentValidationSchema,
    onSubmit: async (data: InfluencerType) => {
      formik.setTouched({});
      if (actualField === 2) {
        const token = localStorage.getItem("accessToken");
        const decodedToken: any = jwt_decode(token);
        try {
          setLoading(true);
          const source = axios.CancelToken.source();
          const config = { cancelToken: source.token };
          const res = await axios.post(
            "/profile/brand/create-form",
            {
              brandId: decodedToken?.permissions[0]?.brandId ? decodedToken?.permissions[0]?.brandId : decodedToken?.permissions?.permissions[0]?.brandId,
              banner: banner.image,
              header: data.title,
              description: data.description,
            },
            config
          );
          if (res.status === 200 || res.status === 201) {
            await axios.post("/brand/save-register-status", {
              accessToken: token,
              code: 2004,
            });
            handleShow();
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            setMessageErro(`Request canceled: ${error.message}`);
          } else {
            setStateMessage(true);
            Error(error.response.status);
          }
        } finally {
          setLoading(false);
        }
      } else {
        setNextField(actualField + 1);
      }
    },
  });

  const doLogin = async () => {
    setLoading(true);
    if (!formData.email || !formData.password) {
      localStorage.removeItem('accessPayload')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('kt-auth-react-v')
      window.location.href = '/auth'
      return
    }
    try {
      const response: any = await login(formData.email, formData.password);
      const token = response.data.accessToken;
      const res = await axios.post("catalyst/verify-token", {
        accessToken: response.data.accessToken,
      });

      localStorage.setItem("accessPayload", JSON.stringify(res.data));

      const user: any = jwt_decode(token);
      const requestUser = await getUser(user?.sub);
      const conectorsList = await getConnectores(token);
      const ambassadors = await getAmbassadors({
        token: token,
        brandId: user?.sub,
      });
      const balence = await getBalance(token);
      const challenge = await getListChallenge(token);

      const list_chart = ambassadors.data.totalNewRequests;
      const list = conectorsList.data.data;
      const value = balence.data.data.total;

      const isActive = (list: any) => list.connected === true;
      const reslist = list.find(isActive);

      const auth = {
        api_token: token,
        name: user?.name,
        email: user?.email,
        hasConnector: reslist === undefined ? false : reslist.connected,
        id: user?.sub,
        brandId: requestUser?.data?.fk_id_brand,
        chart_request: list_chart,
        balence: value,
        challenge: challenge.data.stats.totalChallenges,
        shopify: list[0].connected,
        tray: list.filter((e) => e.title === "tray")[0].connected,
      };

      saveAuth(auth);

      if (res.status === 200) {
        window.analytics.identify(res.data.email, {
          name: res.data.name,
          email: res.data.email,
        });
        window.analytics.track("Logged");
        window.location.href = "/";
      }
    } catch (error) {
      saveAuth(undefined)
      setLoading(false)
    }
  };

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <BsCheck2Circle
              className="text-success mb-6 mt-5"
              style={{ fontSize: "65px" }}
            />
            <p>Cadastro realizado com sucesso!</p>
            <button className="btn btn-success mt-3" onClick={() => doLogin()}>
              Entrar
              {loading && (
                <Spinner
                  animation="grow"
                  className={loading && "ms-3"}
                  variant="light"
                  size="sm"
                />
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <form onSubmit={formik.handleSubmit} noValidate>
        {intro ? (
          <>
            <h1 className="mb-5">Do nosso lado, tudo pronto!</h1>
            <p className="mb-5">
              Vamos para a etapa <strong>mais importante</strong> da ativação da
              BrandLovrs, que é <strong>chamar a sua comunidade</strong> para
              fazer parte :)
            </p>
            <p className="mb-5">
              Configuraremos um <strong>formulário</strong> que você deve
              compartilhar com seus seguidores e colocar em seu site, em um
              lugar fácil de encontrar.
            </p>
            <p className="mb-5">
              Sempre que um Lovr novo se cadastrar, automaticamente se inscreve
              para a sua comunidade e vai acessar{" "}
              <strong>todos os seus desafios :)</strong>{" "}
            </p>
            <button className="btn btn-info" onClick={() => setIntro(false)}>
              Continuar
            </button>
          </>
        ) : (
          <>
            {stateMessage && <Alert variant="danger">{messageErro}</Alert>}
            {actualField === 0 && (
              <>
                <h2 className="mb-10">
                  Vamos deixar o formulário com a sua cara?
                </h2>
                <p className="mb-3 font-14 font-roboto mb-0 text-gray-600">
                  Você pode enviar seu logo em fundo transparente, ou um banner
                  bem chamativo.
                </p>
                <p className="mb-5 font-14 font-roboto mb-0 text-gray-600">
                  <strong>Formato:</strong> Na horizontal, em JPG ou PNG, com no
                  máximo 1 MB e até 1820 x 800.
                </p>
                <UploadImage
                  name="image"
                  viewText={false}
                  label_title="Banner do Desafio"
                  label_subtitle="Defina uma imagem exclusiva para ilustrar este desafio."
                  badge_title="Opcional"
                  banner_title="Carregar arquivo..."
                  banner_subtitle="Seu banner precisa ser no formato JPG ou PNG, ter até 1 MB e dimensão máxima de 1820 x 800."
                  {...formik.getFieldProps("image")}
                  touched={formik.touched.image}
                  error={formik.errors.image}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  setBaseValue={setBanner}
                />
              </>
            )}
            {actualField === 1 && (
              <>
                <h2 className="mb-5">Escolha uma chamada!</h2>
                <p className="mb-3 font-14 font-roboto mb-0 text-gray-600 mb-5">
                  Recrute Lovrs no tom de voz que mais representa a sua marca.
                </p>
                {title.map((option) => (
                  <label
                    htmlFor={option.id}
                    className="w-100 mb-3"
                    key={option.value}
                  >
                    <input
                      type="radio"
                      className="form-check-input me-3 cursor-pointer"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("title")}
                      name="title"
                      value={option.value}
                      id={option.id}
                    />
                    {option.value}
                  </label>
                ))}
                {formik.touched.title && formik.errors.title && (
                  <span className="text-danger fw-bold d-block py-2">
                    {formik.errors.title}
                  </span>
                )}
              </>
            )}
            {actualField === 2 && (
              <>
                <h2>
                  Escolha uma <strong>descrição</strong>{" "}
                </h2>
                {/*<p className="mb-3 font-14 font-roboto mb-0 text-gray-600 mb-5">Onde estiver escrito MARCA, trocaremos pelo nome da marca.</p>*/}
                {description.map((data) => (
                  <label htmlFor={data.id} key={data.id} className="w-100 mb-3">
                    <input
                      type="radio"
                      className="form-check-input me-3"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("description")}
                      name="description"
                      value={data.value}
                      id={data.id}
                      autoFocus={data.id === "a"}
                    />
                    <span dangerouslySetInnerHTML={{ __html: data.label }} />
                  </label>
                ))}
                {formik.touched.description && formik.errors.description && (
                  <span className="text-danger fw-bold d-block py-2">
                    {formik.errors.description}
                  </span>
                )}
              </>
            )}
            <RegisterWizzardController
              actualField={actualField}
              setNextField={setNextField}
              loading={loading}
            />
          </>
        )}
      </form>
    </>
  );
};

export { Influencer };
