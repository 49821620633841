import { OverlayTrigger, Popover } from "react-bootstrap";

import { ReactComponent as Instagram } from "../../../../assets/svg/socials/instagram_alt.svg";
import { ReactComponent as Facebook } from "../../../../assets/svg/socials/facebook_alt.svg";
import { ReactComponent as TikTok } from "../../../../assets/svg/socials/tiktok_alt.svg";
import { ReactComponent as Twitter } from "../../../../assets/svg/socials/twitter_alt.svg";
import { ReactComponent as Youtube } from "../../../../assets/svg/socials/youtube_alt.svg";

export const SocialMediaList = ({ medias }: any) => {
  const renderTooltip = (props) => (
    <Popover id="button-tooltip" {...props}>
      <div className='p-4 d-block fw-normal'>
        {props}
      </div>
    </Popover>
  );

  return (
    <div className='d-flex justify-content-center align-items-center mt-5 mb-5 min-h-45px'>
      <div className='mx-1'>
        {
          medias?.instagram?.profileUrl ?
            <a href={`${medias?.instagram?.profileUrl}`} rel="noreferrer" target="_blank">
              <OverlayTrigger
                trigger={["hover", "hover"]}
                placement='top'
                overlay={renderTooltip(medias?.instagram?.profileUrl)}
              >
                <Instagram
                  fill={medias?.instagram ? "#7E8299" : "#7E829933"}
                  className={medias?.instagram?.username && "cursor-pointer"}
                />
              </OverlayTrigger>
            </a>
            :
            <Instagram
              fill={medias?.instagram?.profileUrl ? "#7E8299" : "#7E829933"}
              className={medias?.instagram?.username && "cursor-pointer"}
            />
        }
      </div>

      <div className='mx-1'>
        {
          medias?.facebook?.profileUrl ?
            <a href={`https://pt-br.facebook.com/${medias?.facebook?.profileUrl?.replace('@', '')}`} rel="noreferrer" target="_blank">
              <OverlayTrigger
                trigger={["hover", "hover"]}
                placement='top'
                overlay={renderTooltip(medias?.facebook?.profileUrl)}
              >
                <Facebook
                  fill={medias?.facebook?.profileUrl ? "#7E8299" : "#7E829933"}
                  className={medias?.facebook?.username && "cursor-pointer"}
                />
              </OverlayTrigger>
            </a>
            :
            <Facebook
              fill={medias?.facebook?.profileUrl ? "#7E8299" : "#7E829933"}
              className={medias?.facebook?.username && "cursor-pointer"}
            />
        }
      </div>

      <div className='mx-1'>
        {
          medias?.tiktok?.profileUrl ?
            <a href={`${medias?.tiktok?.profileUrl}`} rel="noreferrer" target="_blank">
              <OverlayTrigger
                trigger={["hover", "hover"]}
                placement='top'
                overlay={renderTooltip(medias?.tiktok?.profileUrl)}
              >
                <TikTok
                  fill={medias?.tiktok?.profileUrl ? "#7E8299" : "#7E829933"}
                  className={medias?.tiktok?.username && "cursor-pointer"}
                />
              </OverlayTrigger>
            </a>
            :
            <TikTok
              fill={medias?.tiktok?.profileUrl ? "#7E8299" : "#7E829933"}
              className={medias?.tiktok?.username && "cursor-pointer"}
            />
        }
      </div>

      <div className='mx-1'>
        {
          medias?.twitter?.profileUrl ?
            <a href={`https://twitter.com/${medias?.twitter?.profileUrl?.replace('@', '')}`} rel="noreferrer" target="_blank">
              <OverlayTrigger
                trigger={["hover", "hover"]}
                placement='top'
                overlay={renderTooltip(medias?.twitter?.profileUrl)}
              >
                <Twitter
                  fill={medias?.twitter?.profileUrl ? "#7E8299" : "#7E829933"}
                  className={medias?.twitter?.username && "cursor-pointer"}
                />
              </OverlayTrigger>
            </a>
            :
            <Twitter
              fill={medias?.twitter?.profileUrl ? "#7E8299" : "#7E829933"}
              className={medias?.twitter?.username && "cursor-pointer"}
            />
        }
      </div>

      <div className='mx-1'>
        {
          medias?.youtube?.profileUrl ?
            <a href={`https://www.youtube.com/@${medias?.youtube?.profileUrl?.replace('@', '')}`} rel="noreferrer" target="_blank">
              <OverlayTrigger
                trigger={["hover", "hover"]}
                placement='top'
                overlay={renderTooltip(medias?.youtube?.profileUrl)}
              >
                <Youtube
                  fill={medias?.youtube?.profileUrl ? "#7E8299" : "#7E829933"}
                  className={medias?.youtube?.username && "cursor-pointer"}
                />
              </OverlayTrigger>
            </a>
            :
            <Youtube
              fill={medias?.youtube?.profileUrl ? "#7E8299" : "#7E829933"}
              className={medias?.youtube?.username && "cursor-pointer"}
            />
        }
      </div>
    </div>
  )
}