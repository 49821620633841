import React from 'react'
import { IRadioProps } from './types'

const Radio: React.FC<IRadioProps> = ({ label, id, ...props }): JSX.Element => {
  return (
    <span className="position-relative">
      <input type="radio" className="form-check-input input-radio" id={id} {...props} />
      <label htmlFor={id} className="border-primary-label">{label}</label>
    </span>
  )
};

export { Radio };


