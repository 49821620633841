import { AccountPanelDashboard } from "app/components/Metabase";
import axios from "axios";
import { useEffect, useState } from "react";

const Dashboard = () => {
  const [brand, setBrand] = useState();

  async function handleGetBrandData() {
    const payload: string = localStorage.getItem("accessPayload");
    let parsedPayload;
    if (payload) parsedPayload = JSON.parse(payload);

    const response = await axios.post(`/profile/show`, { id: parsedPayload.sub });

    setBrand(response.data.fk_id_brand);
  }

  useEffect(() => {
    handleGetBrandData();
  }, []);

  return (
    <AccountPanelDashboard brandId={brand} />
  );
};

export default Dashboard;

