import { Link } from 'react-router-dom'
import Pages from '../../../components/Page/Pages'
export const Confirmation = () => {

  return (
    <Pages type='Logo'>
      <div className='pt-5 pb-5'>
        <h1 className='text-black fw-bolder mb-10'>Ative sua conta pelo e-mail</h1>
        <p>Por favor, acesse o e-mail que mandamos para  <span className='fw-bolder'> {localStorage.getItem('email')}</span> e use o link enviado para ativar sua conta. </p>
        <p className='mb-15'> Ah, lembre-se de checar seu lixo eletrônico (spam) também.</p>
        <div className='d-flex'>
          <Link to='/auth/login' className='link-info fw-bolder'>
            <span className='btn btn-info'>Acessar minha conta</span>
          </Link>
        </div>
      </div>
    </Pages>

  )
}
