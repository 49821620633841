import axios from 'axios';
import { useEffect, useState } from 'react';

import { toAbsoluteUrl } from '../../../../../_metronic/helpers';

import {
  Register,
  AditionalInfo,
  Profile,
  Influencer
} from './pages';
import { getAuth } from '../../core/AuthHelpers';


const RegisterWizzard = () => {
  const auth = getAuth();
  const [pageStep, setPageStep] = useState(0);
  const [fieldStep, setFieldStep] = useState(0);
  const [categories, setCategories] = useState([] as any)
  const [brand, setBrand] = useState({} as any)

  const [data, setData] = useState({});

  const getCategories = async () => {
    try {
      const response = await axios.post("colab/interests", {})
      const parentsCategories = response.data.filter((category: any) => category.parent_id === null)
      setCategories(parentsCategories)
    } catch (e) {
      console.log(e)
    }
  }

  const getInfoBrand = async () => {
    try {
      const response = await axios
        .post("colab-list-info", { brandToken: auth?.api_token })
      setBrand(response.data)
    } catch (e) {
      console.log(e);
    }
  };

  const checkStatus = () => {
    if (auth && auth?.statusBrand) {
      getInfoBrand()
      switch (auth?.statusBrand) {
        case 1002:
          setPageStep(1)
          break;
        case 2001:
          setPageStep(2)
          break;
        case 2002:
          setPageStep(3)
          break;
        default:
          break;
      }
    }
  }

  useEffect(() => {
    getCategories()
    checkStatus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setFieldStep(0), [pageStep])

  return (
    <>
      <div>
        <div className='d-block justify-lg-between d-lg-flex'>
          <div className='d-lg-flex d-none align-items-lg-center justify-content-lg-center m-auto flex-column cursor-pointer'
            style={{ width: '50%', height: '100vh', background: `url(${toAbsoluteUrl('/media/register/splash_phone.png')})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          </div>

          <div className='bg-white d-flex flex-column justify-content-center cadastroMinHeight register-wrapper content-login px-20'>
            {
              pageStep === 0 &&
              <Register
                formData={data}
                setFormData={setData}

                actualPage={pageStep}
                setNextPage={setPageStep}

                actualField={fieldStep}
                setNextField={setFieldStep}
              />
            }
            {
              pageStep === 1 &&
              <Profile formData={data}
                setFormData={setData}
                categories={categories}
                brand={brand}

                actualPage={pageStep}
                setNextPage={setPageStep}

                actualField={fieldStep}
                setNextField={setFieldStep} />
            }
            {
              pageStep === 2 &&
              <AditionalInfo
                formData={data}
                setFormData={setData}
                brand={brand}

                actualPage={pageStep}
                setNextPage={setPageStep}

                actualField={fieldStep}
                setNextField={setFieldStep}
              />
            }
            {
              pageStep === 3 &&
              <Influencer
                formData={data}
                setFormData={setData}
                brand={brand}

                actualPage={pageStep}
                setNextPage={setPageStep}

                actualField={fieldStep}
                setNextField={setFieldStep}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterWizzard;
