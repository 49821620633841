import React from "react";

import { IWarningProps } from "./types";

const Warning: React.FC<IWarningProps> = ({
    title,
    description,
}): JSX.Element => {
    return (
        <div className="alert bg-light-warning d-flex align-items-center p-5 p-md-8 mb-10 border-dotted border-warning" role="alert" >
            <span className="svg-icon svg-icon-warning svg-icon-2hx pe-sm-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                    <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor" />
                    <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"/>
                </svg>
            </span>
            <div>
                <h3>{title}</h3>
                <p className="mb-0">{description}</p>
            </div>
        </div>
    );
};

export { Warning };
